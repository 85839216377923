import React from "react";

export default function ResourceTableHeader({isSortable, onClick, className, columnName, children}) {
    if (isSortable) {
        return (
            <button
                id={"col-" + columnName}
                className={className}
                onClick={onClick}
            >
                {children}
            </button>
        )
    }

    return <div className={className}>{children}</div>
}