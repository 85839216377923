import React from "react";
import {classNames} from "../../common/util/util-helpers";

export default function Card(props) {
    const {className, bodyClass, addBodyClass, addClass, onClick} = props;
    const defaultCardClass = "bg-inverse rounded-lg border-tm-gray-200 border shadow-card";
    const defaultBodyClass = "py-3 px-6 space-y-4";

    return (
        <div onClick={onClick} className={classNames(className ?? defaultCardClass, addClass)}>
            <div className={classNames(bodyClass ?? defaultBodyClass, addBodyClass)}>
                {props.children}
            </div>
        </div>
    )
}

