import React from "react";
import NoRecords from "./no-records";
import MinusCircleIcon from "@heroicons/react/outline/MinusCircleIcon";

export default function NoRecordsTable({
                                           title,
                                           text,
                                           btnLabel,
                                           onBtnClick,
                                           className,
                                           show,
                                           canCreate,
                                           filters,
                                           clearFilterBtnLabel,
                                           onClearFilterClick,
                                           clearFilterText,
                                           excludeFields = ['query', 'limit']
                                       }) {

    let showClearFilters = !!filters ?
        Object.keys(filters)
            .filter(it => !excludeFields.includes(filters[it].name))
            .some(it => !!filters[it].value)
        : false

    return !show ? null : (
        <div className={className ?? "px-6 pt-6 py-12"}>
            <NoRecords
                canCreate={canCreate}
                show={show}
                title={title}
                text={showClearFilters ? clearFilterText : text}
                btnLabel={showClearFilters ? null : btnLabel}
                onBtnClick={() => onBtnClick()}
            />

            {showClearFilters && (
                <div className="mt-6 text-center">
                    <button
                        type="button"
                        className="btn btn-outline"
                        onClick={() => onClearFilterClick(excludeFields)}
                    >
                        <MinusCircleIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true"/>
                        {clearFilterBtnLabel ?? "Clear filters"}
                    </button>
                </div>
            )}
        </div>
    )
}
