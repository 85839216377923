import {formatMoney} from "./util";

export function numberWithCommasInputChange(n) {
    if (n) {
        let formattedNumber = numberWithCommasToBack(n.replace(/\D+/g, ''))
        if (n.split(".").length > 1) {
            const partOne = Number(n.toString().split(".")[0].replace(/\D+/g, ''))
            const partTwo = n.toString().split(".")[1].replace(/\D+/g, '')
            return partOne.toLocaleString() + "." + (partTwo ? (partTwo.charAt(0) + partTwo.charAt(1)) : '')
        } else {
            return formattedNumber ? formattedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
        }
    }
}

export function floatUpTo16InputChange(n) {
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(n)) {
        if (n.length > 17) {
            return n.slice(0, -1)
        }
        return n
    } else {
        return n.replace(/\D+/g, '');
    }
}

export function formatExpDate(value) {
    value = value.replace(/\D/g, '');

    if (value.length > 4) {
        value = value.substring(0, 4)
    }
    const month = value.substring(0, 2);
    const year = value.substring(2, 4);

    if (value.length > 2) {
        value = `${month} / ${year}`
    } else {
        value = value.replace(/\D/g, '');
    }

    return value
}

export function numberWithCommas(n) {
    if (!isNaN(n) && (n != "") && (n !== null)) {
        return formatMoney(n, 2, ".", ",");
    }

    return n;
}

export function integerWithCommas(n) {
    if (!isNaN(n) && (n != "") && (n !== null)) {
        return formatMoney(n, 0, "", ",");
    }

    return "";
}

export function numberWithCommasToBack(n, def = null) {
    // REMOVED !isNaN() from IF
    if ((n !== undefined) && (n !== "") && (n !== null)) {
        let parts = n.toString().split(".");
        return Number(parts[0].replace(/,/g, "") + (parts[1] ? "." + parts[1] : (n.toString().includes(".") ? "." : "")));
    }

    return def ?? null;
}
