import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../components/layout";
import {Field, FieldsManager} from "../../data/services/fields.js";
import FieldText from "../../components/field-text";
import FieldPassword from "../../components/field-password";
import {login, resetUserMessage} from "../../data/actions/user";
import LoaderSmall from "../../components/loader-small";
import InfoParagraph from "../../components/info-paragraph";
import {Link} from "react-router-dom";

const LoginView = (props) => {

    /** Constants
     ================================================================= */
    const {history, translate} = props

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user)

    const [accessToken, setAccessToken] = useState(null);

    /** Fields/Data definitions
     ================================================================= */
    const getFields = () => ({
        username: new Field('username', '', ['empty', 'email'], false, 'email'),
        password: new Field('password', '', ['empty'], false, 'password')
    })

    const [fields, setFields] = useState(getFields())

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        dispatch(resetUserMessage())
    }, [])

    useEffect(() => {
        if (!!user.data) {
            history.push("/");
        }
    }, [user]);

    useEffect(() => {
        if (!!accessToken) {
            dispatch(login({
                AzureAD: true,
                accessToken: accessToken
            }));
        }
    }, [accessToken]);

    /** Data events
     ================================================================= */
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit(event);
        }
    };

    const onSubmit = (event) => {
        event && event.preventDefault();

        const validatedFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            dispatch(login(FieldsManager.getFieldKeyValues(validatedFields)));
        } else {
            setFields(validatedFields);
        }
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
    }

    /** Render
     ================================================================= */
    return (
        <Layout user={user} history={history}>
            <section className="gradient-form h-[100vh] w-full bg-neutral-200 dark:bg-neutral-700">
                <div className="container h-full m-auto w-full p-10">
                    <div
                        className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200">
                        <div className="w-full xl:w-1/2">
                            <div
                                className="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
                                <div className="">
                                    <div className="px-4 md:px-0">
                                        <div className="md:mx-6 md:p-12">
                                            <div className="text-center mb-12">
                                                <img
                                                    className="mx-auto w-24"
                                                    src="/images/nursing_temp_logo.png"
                                                    alt="logo"/>
                                            </div>

                                            <form onKeyPress={onKeyDown}>
                                                <p className="mb-4">Please login to your account</p>
                                                <div className="relative mb-4" >
                                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Email
                                                    </label>

                                                    <FieldText
                                                        onChange={handleInputChange}
                                                        {...fields.username}
                                                        placeholder={"email@domain.com"}
                                                        translate={translate}
                                                        addClass="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                                    />
                                                </div>

                                                <div className="relative mb-4" >
                                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Password
                                                    </label>
                                                    <FieldPassword
                                                        onChange={handleInputChange}
                                                        translate={translate}
                                                        {...fields.password}
                                                        addClass="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                                    />
                                                </div>

                                                {user.isLoading && (
                                                    <div className="flex justify-center">
                                                        <LoaderSmall/>
                                                    </div>
                                                )}

                                                {!user.isLoading && (
                                                    <div>
                                                        <div className="mb-12 pb-1 pt-1 text-center">
                                                            <button
                                                                className="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                                                                type="button"
                                                                onClick={onSubmit}
                                                                data-te-ripple-color="light"
                                                                style={{
                                                                    background: 'linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)'
                                                                }}>
                                                                Sign in with username/password
                                                            </button>

                                                            <Link to="/reset-password">Forgot password?</Link>
                                                        </div>
                                                    </div>
                                                )}

                                                {user.errorMessage && (
                                                    <InfoParagraph
                                                        type="danger"
                                                        className={'mt-5'}
                                                    >
                                                        {translate(user.errorMessage)}
                                                    </InfoParagraph>
                                                )}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default LoginView;
