const stackedResourceReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_STACKED_RESOURCE':
            return Object.assign({}, state, {
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: true}),
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_STACKED_RESOURCE':
            return Object.assign({}, state, {
                data: Object.assign(state.data ? state.data : {}, {[action.data.resource]: action.data.data}),
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_STACKED_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: true,
                errorMessage: action.data?.data
            });
        case 'CREATE_STACKED_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: true}),
                error: false,
                errorMessage: null
            });
        case 'DONE_CREATE_STACKED_RESOURCE':
            return Object.assign({}, state, {
                create: action.data?.data,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: false,
                errorMessage: null
            });
        case 'ERROR_CREATE_STACKED_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: true,
                errorMessage: action.data?.data
            });
        case 'UPDATE_STACKED_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: true}),
                error: false,
                errorMessage: null
            });
        case 'DONE_UPDATE_STACKED_RESOURCE':
            return Object.assign({}, state, {
                update: true,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: false,
                errorMessage: null
            });
        case 'ERROR_UPDATE_STACKED_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: true,
                errorMessage: action.data?.data
            });
        case 'DELETE_STACKED_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: true}),
                error: false,
                errorMessage: null
            });
        case 'DONE_DELETE_STACKED_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: true,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: false,
                errorMessage: null
            });
        case 'ERROR_DELETE_STACKED_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: false,
                error: true,
                errorMessage: action.data?.data
            });
        default:
            return state;
    }
};

export default stackedResourceReducer;