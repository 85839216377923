import {classNames, getProp} from "../../common/util/util-helpers"
import React, { useEffect, useMemo, useRef, useState } from "react";
import ResourceTableHeader from "./resource-table-header";
import { ActionsWidth, fieldToCell, getLabel, TableActions } from "./table-utils";

const getCellMinWidth = (colData, colName, rowRef) => {
    let defaultMinWidth = 150;
    let updatedWidth;

    const ref = rowRef.current[colName];
    const minColumnLabelWidth = !!ref && ref?.firstChild.firstChild.offsetWidth;

    if (minColumnLabelWidth) {
        updatedWidth = minColumnLabelWidth + 50;
    }

    updatedWidth = updatedWidth > 150 ? updatedWidth : defaultMinWidth;

    if (!colData || !colName) return updatedWidth;

    if (!!colData[colName].minWidth && colData[colName].minWidth > updatedWidth) {
        return colData[colName].minWidth;
    } else {
        return updatedWidth;
    }
}

export default function Table({
                                  onView,
                                  onEdit,
                                  onDelete,
                                  onRestore,
                                  actions,
                                  fields,
                                  translate,
                                  resourceData,
                                  headerVisible,
                                  limit,
                                  sort,
                                  sortBy,
                                  onSortChange,
                                  options,
                                  tableKey,
                                  onRowClick,
                                  selectedRows,
                                  onSelectRow,
                                  disableRowSelect = () => false,
                                  onSelectAllClick,
                                  isLoading,
                                  maxHeightClass,
                                  tfoot,
                                  performanceModeLimit = 21
                              }) {
    if (!resourceData.length && !headerVisible) return null;

    const isPerformanceTable = !!limit && performanceModeLimit < Number(limit) || performanceModeLimit < resourceData.length;

    const {stripedRows, horizontalLines, verticalLines, condensed, floatingActions, frozenActionColumn, columnFilters} = options.style;
    const {stickyHeader, rowSelect, rowHover} = options.behaviour;
    const {
        primaryTextClass,
        secondaryTextClass,
        mutedTextClass,
        headerTextClass,
        tdPadding,
        condensedClass,
        tableStripeClass,
        tdPaddingActions,
        verticalLinesClass,
        horizontalLinesClass,
        tdWithSubColumnsPadding,
        tdCondensedWithSubColumnsPadding,
        floatingActionsContainerClass,
    } = options.classes;

    const {IconSortAsc, IconSortDesc} = options.icons;

    const viewportMaxHeightClass = maxHeightClass ?? options?.classes?.maxHeightClass;

    const hasDefaultActions = !!onView || !!onEdit || !!onDelete || !!onRestore;
    const hasActions = (actions && actions.length) || hasDefaultActions;


    const [columns, setColumns] = useState([]);
    const [columnsData, setColumnsData] = useState([]);
    const [stickyColumns, setStickyColumns] = useState([]);
    const [hasSubColumns, setHasSubColumns] = useState(false);
    const [rowMinWidth, setRowMinWidth] = useState(0);
    const [actionsWidth, setActionsWidth] = useState(0);
    const [tableScrollLeft, setTableScrollLeft] = useState(0);


    useEffect(() => {
        let columnsData = options?.columns ? options.columns : {};
        const columnsList = !!Object.values(columnsData).length && Object.values(columnsData);
        let hasSubColumns = false;
        let rowMinWidth = 0;

        let columns = !!columnsList.length
            ? columnsList.filter(column => column.show && !fields[column.name]?.metadata?.hideTable).reduce((memo, column) => {
                if (!!column?.subColumns?.length) {
                    hasSubColumns = true;
                }

                let cellMinWidth = getCellMinWidth(columnsData, column.name, rowRef);

                columnsData[column.name].minWidth = cellMinWidth;

                rowMinWidth = rowMinWidth + cellMinWidth;

                memo.push(column.name);
                return memo
            }, [])
            : Object.keys(fields).filter(column => fields[column].type !== "hidden" && !fields[column.name]?.metadata?.hideTable);

        if (!!columnsList.length) {
            const stickyColumnsList = columnsList.reduce((memo, col) => {
                if (col.frozen) {
                    memo.push(col.name)
                }
                return memo;
            }, []);

            if (rowSelect && !!stickyColumnsList.length) {
                stickyColumnsList.unshift("RowSelectColumn"); // make checkboxes sticky if any column is sticky
            }
            setStickyColumns(stickyColumnsList);
        }

        if (hasSubColumns) {
            setHasSubColumns(true);
        } else {
            setHasSubColumns(false);
        }

        setColumns(columns);
        setColumnsData(columnsData);
        setRowMinWidth(rowMinWidth);
    }, [fields, options]);

    let rowRef = useRef(columns.reduce((memo, it) => {
        memo[it] = null;
        return memo;
    }, {}));

    let actionsColumnRef = useRef(null);

    let actionsWidthRef = useRef(null);
    let tableRef = useRef(null);
    let tableViewPortRef = useRef(null);
    let tableContainerRef = useRef(null); // this will probably go away

    useEffect(() => {
        let resizeTableObserver = null;
        let resizeContainerObserver = null;
        let resizeTableHeightObserver = null;

        const actionsContainerElement = actionsWidthRef.current;
        if (actionsContainerElement) {
            setActionsWidth(actionsContainerElement.offsetWidth);
        }

        if (performanceModeLimit < limit) {
            resizeTableHeightObserver = new ResizeObserver((entries) => {
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }
                    handleHeightChange(entries);
                });
            });

            resizeTableHeightObserver.observe(tableViewPortRef.current);
            handleHeightChange();
        }

        if (!!stickyColumns.length) {
            resizeTableObserver = new ResizeObserver((entries) => {
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }
                    handleSetStickyColumnOffsets();
                });
            });

            resizeTableObserver.observe(tableRef.current);
            handleSetStickyColumnOffsets();
        }

        if (!!frozenActionColumn || !!stickyColumns) {
            resizeContainerObserver = new ResizeObserver((entries) => {
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return;
                    }

                    const hasScrollBar = !!(entries[0].target.scrollWidth - entries[0].target.clientWidth);

                    if (hasScrollBar) {
                        setContainerHasScrollBar(true);
                    } else {
                        setContainerHasScrollBar(false);
                    }
                });
            });

            resizeContainerObserver.observe(tableViewPortRef.current);
        }


        return () => {
            if (!!resizeTableHeightObserver) {
                resizeTableHeightObserver.disconnect();
            }

            if (!!resizeTableObserver) {
                resizeTableObserver.disconnect();
            }

            if (!!resizeContainerObserver) {
                resizeContainerObserver.disconnect();
            }
        }
    }, [frozenActionColumn, options, stickyColumns])

    const [stickyColumnOffsets, setStickyColumnOffsets] = useState(null);

    const [containerHasScrollBar, setContainerHasScrollBar] = useState(null);

    const getFrozenColumnsOffset = () => {
        let offset = 0;
        let update = false;

        const columnsOffset = stickyColumns
            .reduce((memo, it, i) => {
                if (!i || !rowRef.current[stickyColumns[i - 1]]) {
                    memo[it] = offset;
                    return memo;
                }

                offset = rowRef.current[stickyColumns[i - 1]].offsetWidth + offset;

                if (stickyColumnOffsets) {
                    if (offset !== stickyColumnOffsets[columns[i]]) {
                        update = true;
                    }
                }

                memo[it] = offset;

                return memo;
            }, {})


        if (update || !stickyColumnOffsets) {
            return columnsOffset;
        }

        return false;
    }

    const getColumnStyle = (columnName, zIndex = 11, columnsData, length) => {

        const minWidth = isPerformanceTable
            ? !!columnsData && columnsData[columnName]?.minWidth ? columnsData[columnName].minWidth : 150
            : "auto";

        const width = 1 / length * 100;



        if (!!stickyColumns.includes(columnName) && stickyColumnOffsets && !!rowSelect) {
            return {left: stickyColumnOffsets[columnName], zIndex: zIndex, width: width + "%", minWidth: minWidth}
        }

        return {zIndex: zIndex, width: width + "%", minWidth: minWidth};
    }

    const handleSetStickyColumnOffsets = () => {
        const offsets = getFrozenColumnsOffset();

        if (!!offsets) {
            setStickyColumnOffsets(offsets)
        }
    }

    const handleHeightChange = (entries) => {
        if (entries) {
            handleTableVerticalScroll(entries[0].contentRect.height);
        } else {
            handleTableVerticalScroll();
        }
    }

    const filterColumn = (header) => {
        return !(fields[header]?.metadata?.hideTable || header === "RowSelectColumn");
    }

    const rowHeight = hasSubColumns ? (condensed ? 48 : 64) : (condensed ? 36 : 48);
    const headerHeight = 36;

    const [startRow, setStartRow] = useState(0);
    const [endRow, setEndRow] = useState(0); // limit

    useEffect(() => {
        if (limit < performanceModeLimit) {
            setStartRow(0);
        }
    }, [limit])

    const tableHeight = (resourceData.length * rowHeight) + headerHeight;

    const handleTableVerticalScroll = (viewportHeight = tableViewPortRef?.current?.offsetHeight) => {
        const numVisibleItems = Math.trunc(viewportHeight / rowHeight);

        if (performanceModeLimit > limit) { // or some other case
            setStartRow(0);
            setEndRow(limit);
        }

        let currentIndex = !!tableViewPortRef.current ? Math.trunc(tableViewPortRef.current.scrollTop / rowHeight) : 0;

        setStartRow(currentIndex);
        setEndRow(currentIndex + numVisibleItems)
    }

    const handleTableScroll = (e) => {
        const x = getProp(e, 'currentTarget.scrollLeft', 0)
        if (x !== tableScrollLeft && !isLoading) {
            setTableScrollLeft(x)
        }

        if (isPerformanceTable) {
            handleTableVerticalScroll()
        }
    }

    const [areAllSelected, setAreAllSelected] = useState(false);
    useEffect(() => {
        if (!!selectedRows) {
            setAreAllSelected(!!resourceData.length && !resourceData.find(it => !selectedRows[it[tableKey]]));
        }
    }, [selectedRows]);

    useEffect(() => {
        if (!!tableViewPortRef?.current) {
            tableViewPortRef.current.scroll(tableScrollLeft, 0)
        }
    }, [resourceData]);

    return (
        <React.Fragment>
            <div
                ref={tableViewPortRef}
                className={classNames(
                    viewportMaxHeightClass,
                    "relative ring-1 ring-black ring-opacity-5 overflow-auto flex"
                )}
                onScroll={handleTableScroll}
            >
                <div
                    ref={tableContainerRef}

                    className={classNames(
                        isPerformanceTable ? "inline-block" : "table",
                        "min-w-full relative mb-0", // remove mb-0 with bootstrap
                        secondaryTextClass
                    )}
                    style={isPerformanceTable ? {height: tableHeight} : null}
                >
                    {/*table*/}

                    <header className={classNames(
                        stickyHeader ? "sticky top-0 z-[21]" : undefined,
                        isPerformanceTable ? "inline-flex min-w-full" : "table-header-group",
                        headerTextClass
                    )}
                        // min column width
                    >

                        <div className={classNames(
                            verticalLines ? verticalLinesClass : undefined,
                            isPerformanceTable ? "inline-flex" : "table-row",
                            "min-w-full"
                        )} style={{width: rowMinWidth}}>

                            {/*Row selector*/}
                            {rowSelect && (
                                // th
                                <div
                                    ref={ref => rowRef.current["RowSelectColumn"] = ref}
                                    className={
                                        classNames(
                                            "min-w-[3rem] border-b border-tm-gray-300",
                                            stickyColumns.includes("RowSelectColumn") ? "sticky bg-tm-gray-50 left-0 z-[14]" : undefined,
                                            containerHasScrollBar ? "bg-tm-gray-100 " : "", // customize bg
                                            isPerformanceTable ? "flex items-center justify-center" : "table-cell align-middle text-center",
                                        )
                                    }
                                >
                                    <input
                                        type="checkbox"
                                        className="z-10 rounded bg-tm-gray-50 ring-offset-inverse"
                                        checked={areAllSelected}
                                        onChange={() => onSelectAllClick(!areAllSelected)}
                                    />
                                </div>
                            )}

                            {columns.filter(header => filterColumn(header)).map((header, i) => {
                                const columnLabel = fields[header]?.metadata?.labelRaw ?? getLabel(header, columnsData, translate);
                                const isSortable = !!onSortChange && !fields[header]?.metadata?.omitSort;
                                const isColumnSorted = header === sortBy;
                                const isColumnDesc = isColumnSorted && sort === "DESC";
                                const isHeaderFrozen = !!stickyColumns.includes(header);
                                let zIndex = isHeaderFrozen ? 14 : stickyHeader ? 13 : 12;

                                return (
                                    // TD
                                    <div
                                        ref={ref => rowRef.current[header] = ref}
                                        key={header}
                                        className={
                                            classNames(
                                                stickyHeader ? "top-0 sticky border-b border-tm-gray-300 bg-tm-gray-50" : undefined,
                                                isHeaderFrozen ? "sticky" : "",
                                                isHeaderFrozen && containerHasScrollBar ? "bg-tm-gray-100" : "",
                                                !stickyHeader && !isHeaderFrozen ? "relative" : undefined,

                                                isPerformanceTable ? "flex-initial" : "table-cell align-top",
                                                "border-b border-tm-gray-300"
                                            )
                                        }

                                        style={getColumnStyle(header, zIndex, columnsData, columns.length)}
                                    >
                                        <ResourceTableHeader
                                            isSortable={isSortable}
                                            onClick={() => onSortChange(header)}
                                            className={
                                                classNames(
                                                    !i ? "sm:pl-6" : "",
                                                    isSortable ? "cursor-pointer hover:bg-tm-gray-100" : "",
                                                    "p-2 flex items-center font-semibold w-full group"
                                                )
                                            }
                                            columnName={header}
                                        >
                                            <div
                                                className={classNames(
                                                    isSortable ? "mr-2" : undefined,
                                                    "relative z-20 whitespace-nowrap flex items-center"
                                                )}>
                                                {columnLabel}
                                            </div>

                                            {isSortable && (
                                                <span
                                                    className={
                                                        classNames(
                                                            isColumnSorted ? "table-th-column-sorted" : "table-th-column-not-sorted",
                                                            "relative z-20 ml-auto  flex items-center rounded"
                                                        )
                                                    }
                                                >
                                                <IconSortAsc
                                                    className={classNames(isColumnDesc ? "hidden" : "", isColumnSorted ? "text-inherit" : "text-tm-gray-400", "w-5 h-5 transform table-th-icon")}/>

                                                <IconSortDesc
                                                    className={classNames(isColumnDesc ? "" : "hidden", isColumnSorted ? "text-inherit" : "text-tm-gray-400", "w-5 h-5 transform table-th-icon")}/>
                                            </span>
                                            )}
                                        </ResourceTableHeader>
                                    </div>
                                )
                            })}

                            {/*Action column*/}
                            {hasActions && (
                                // th
                                <div
                                    ref={actionsColumnRef}
                                    className={
                                        classNames(
                                            stickyHeader ? "sticky top-0 bg-tm-gray-50" : undefined,
                                            !containerHasScrollBar && floatingActions ? "sticky top-0 right-0" : "",
                                            !frozenActionColumn && !stickyHeader ? "relative" : undefined,
                                            !frozenActionColumn && stickyHeader ? "border-b border-tm-gray-300" : undefined,
                                            frozenActionColumn && !stickyHeader ? "sticky right-0" : undefined,
                                            frozenActionColumn && stickyHeader ? "right-0" : undefined,
                                            containerHasScrollBar && frozenActionColumn ? "bg-tm-gray-50" : "",
                                            condensed && !floatingActions ? condensedClass : undefined,
                                            !condensed && !floatingActions ? tdPaddingActions : undefined,
                                            floatingActions ? "p-0" : tdPaddingActions,
                                            isPerformanceTable ? "inline-flex items-center justify-center" : "table-cell",
                                            "relative text-center border-b border-tm-gray-300 text-left z-30"
                                        )
                                    }
                                    style={floatingActions ? null : {minWidth: actionsWidth}}
                                >
                                    {!floatingActions && (
                                        <span className="relative z-30">{translate("field.actions")}</span>
                                    )}

                                    <ActionsWidth
                                        ref={actionsWidthRef}
                                        translate={translate}
                                        hasDefaultActions={hasDefaultActions}
                                        actions={actions}
                                        paddingClass={condensed ? tdCondensedWithSubColumnsPadding : tdWithSubColumnsPadding}
                                    />
                                </div>
                            )}
                        </div>
                    </header>

                    <div
                        ref={tableRef}
                        className={classNames(
                            isPerformanceTable ? "inline-flex" : "table-row-group",
                            "min-w-full"
                        )}
                    >
                        {/*thead*/}

                        {/*tbody*/}
                        {resourceData.filter((row, i) => {
                            if (!isPerformanceTable) return true;
                            return i >= startRow && i < endRow + 1;
                        }).map((row, rowIndex) => {
                                const isRowStripped = stripedRows && (startRow + rowIndex) % 2 === 1;
                                let isArchived = !!row.ArchivedDate;
                                let rowClass = (isRowStripped ? tableStripeClass : isArchived ? "bg-archived" : undefined) + (!!isLoading ? '' : row.ResourceTableRowColorClass ? " " + row.ResourceTableRowColorClass: "")

                                return (
                                    // MAIN DATA ROW
                                    <div
                                        key={rowIndex}
                                        className={classNames(
                                            verticalLines ? verticalLinesClass : undefined,
                                            rowClass,
                                            isPerformanceTable ? "inline-flex" : "table-row",
                                            "min-w-full group",
                                        )}
                                        style={isPerformanceTable ? {
                                            position: "absolute",
                                            top: headerHeight + (startRow + rowIndex) * rowHeight,
                                            width: rowMinWidth
                                        } : null}
                                    >
                                        {/*Row selector*/}

                                        {rowSelect && (
                                            // td
                                            <div
                                                className={
                                                    classNames(
                                                        horizontalLines ? horizontalLinesClass : undefined,
                                                        stickyColumns.includes("RowSelectColumn") ? "sticky left-0 z-[12]" : undefined, // this can be simplified
                                                        containerHasScrollBar && !isRowStripped ? "bg-tm-gray-50 z-[12]" : "",
                                                        containerHasScrollBar && !!isRowStripped ? "bg-tm-gray-50 z-[12]" : "",
                                                        isPerformanceTable ? "flex items-center justify-center" : "table-cell align-middle text-center",
                                                        !!onRowClick ? "group-hover:bg-tm-gray-100" : undefined,
                                                        !onRowClick && rowHover ? "group-hover:bg-tm-gray-100" : undefined,
                                                        "min-w-[3rem]"
                                                    )
                                                }
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="relative z-10 rounded text-link bg-tm-gray-50 ring-offset-inverse"
                                                    checked={!!selectedRows[row[tableKey]]}
                                                    onChange={(event) => onSelectRow(row[tableKey], event)}
                                                    disabled={disableRowSelect(row)}
                                                />
                                            </div>
                                        )}

                                        {columns.filter(columnName => filterColumn(columnName)).map((columnName, columnIndex) => {
                                            const cellMetadata = resourceData?.cell?.metadata ? resourceData.cell.metadata : [];
                                            const isColumnFrozen = !!stickyColumns.includes(columnName);
                                            let cellData = fieldToCell(fields, columnName, row);
                                            const cellSubColumns = !!columns?.length && !!columnsData[columnName] && columnsData[columnName].subColumns;
                                            let mergedColumnName = columnsData[columnName]?.merged?.[0]?.name;
                                            const canClickOnRow = !isLoading && !!onRowClick;
                                            if (!!mergedColumnName) {
                                                cellData = cellData + " " + fieldToCell(fields, mergedColumnName, row);
                                            }
                                            let cellClass = ""
                                            if (!!fields[columnName]?.metadata?.cellClass) {
                                                cellClass = fields[columnName].metadata.cellClass(row);
                                            }
                                            return (
                                                // td
                                                <div
                                                    onClick={(() => canClickOnRow && onRowClick(row))}
                                                    key={rowIndex + "-" + columnIndex}
                                                    className={
                                                        classNames(
                                                            !columnIndex ? "sm:pl-6" : "",
                                                            !columnIndex ? primaryTextClass : undefined,
                                                            horizontalLines ? horizontalLinesClass : undefined,
                                                            cellMetadata['colTextClass'],
                                                            isColumnFrozen ? "sticky" : "", // add sticky rowClass with darker bg
                                                            containerHasScrollBar && isColumnFrozen && !isRowStripped ? "bg-tm-gray-50 z-[12]" : "",
                                                            containerHasScrollBar && isColumnFrozen && !!isRowStripped ? "bg-tm-gray-50 z-[12]" : "",
                                                            !!canClickOnRow ? "group-hover:bg-tm-gray-100 cursor-pointer" : "",
                                                            !canClickOnRow && rowHover ? "group-hover:bg-tm-gray-100" : "",
                                                            condensed && !hasSubColumns ? condensedClass : undefined,
                                                            hasSubColumns && condensed ? tdCondensedWithSubColumnsPadding : undefined,
                                                            hasSubColumns && !condensed ? tdWithSubColumnsPadding : undefined,
                                                            isPerformanceTable ? "flex flex-col justify-center flex-wrap" : "table-cell align-middle",
                                                            !hasSubColumns && tdPadding,
                                                            cellClass
                                                        )
                                                    }
                                                    style={getColumnStyle(columnName, null, columnsData, columns.length)}
                                                >
                                                    {!isLoading && <div className="truncate max-w-full">{cellData}</div>}

                                                    {!!isLoading && <div>
                                                        <div
                                                            className="bg-tm-gray-200 animate-pulse rounded-md h-4"
                                                            style={{width: Math.floor(Math.random() * (90 - 50) + 50) + "%"}}/>
                                                    </div>}

                                                    {!!cellSubColumns && cellSubColumns.map(subColumn => {
                                                        let subColName = Object.keys(subColumn)[0];
                                                        return (
                                                            <div
                                                                key={rowIndex + "-" + columnIndex + subColumn}
                                                                className={classNames(mutedTextClass, "w-full truncate")}
                                                            >
                                                                {!isLoading && fieldToCell(fields, subColName, row)}
                                                                {!!isLoading && <div>
                                                                    <div
                                                                        className="bg-tm-gray-200 animate-pulse rounded-md h-4 mt-0.5"
                                                                        style={{width: Math.floor(Math.random() * (90 - 50) + 50) + "%"}}/>
                                                                </div>}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}

                                        {hasActions && (
                                            // td
                                            <div className={
                                                classNames(
                                                    horizontalLines ? horizontalLinesClass : undefined,
                                                    verticalLines ? verticalLinesClass : undefined,
                                                    containerHasScrollBar && (frozenActionColumn || floatingActions) ? "sticky right-0 bg-tm-gray-200" : "",
                                                    !containerHasScrollBar && floatingActions ? "sticky right-0 bg-tm-gray-50" : "",
                                                    rowClass,
                                                    !!onRowClick ? "group-hover:bg-tm-gray-100" : "",
                                                    !onRowClick && rowHover ? "group-hover:bg-tm-gray-100" : "",
                                                    condensed && !floatingActions ? condensedClass : undefined,
                                                    !condensed && !floatingActions ? tdPaddingActions : undefined,
                                                    floatingActions ? "p-0" : tdPaddingActions,
                                                    isPerformanceTable ? "flex items-center" : "table-cell",
                                                    "z-20 whitespace-nowrap text-right relative"
                                                )
                                            }
                                                 style={!floatingActions ? {minWidth: actionsWidth} : null}
                                            >
                                                <div
                                                    className={
                                                        classNames(
                                                            floatingActions ? floatingActionsContainerClass : "",
                                                            "space-x-2")
                                                    }
                                                >
                                                    <div
                                                        className="relative z-[2] space-x-2 text-center"
                                                        style={{minWidth: actionsWidth}}
                                                    >
                                                        <TableActions
                                                            hasDefaultActions={hasDefaultActions}
                                                            actions={actions}

                                                            item={row}
                                                            rowIndex={rowIndex}
                                                            onView={onView}
                                                            onEdit={onEdit}
                                                            onDelete={onDelete}
                                                            onRestore={onRestore}
                                                            translate={translate}
                                                            isLoading={isLoading}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                        )}
                    </div>

                    {!!tfoot && tfoot.length && (
                        <div className="table-footer-group font-bold h-12 bg-tm-gray-100 no-wrap">
                            {
                                tfoot.map((row, i) => {
                                    return (
                                        <div
                                            className={isPerformanceTable ? "flex items-center" : "table-row"}
                                            key={i}
                                        >
                                            {
                                                Object.values(options?.columns ?? fields)
                                                    .filter(field => field.type !== 'hidden' && !!getProp(options, 'columns.' + field.name + '.show', true))
                                                    .map(field => {

                                                    if (typeof row[field.name] === "object") {
                                                        return (
                                                            <div className={
                                                                classNames(
                                                                    tdPadding,
                                                                    isPerformanceTable ? "flex items-center" : "table-cell align-middle"
                                                                )}
                                                            >
                                                                {row[field.name]}
                                                            </div>
                                                        )
                                                    }
                                                    else if (row[field.name] !== undefined) {
                                                        return (
                                                            <div className={
                                                                classNames(
                                                                    tdPadding,
                                                                    isPerformanceTable ? "flex items-center" : "table-cell align-middle"
                                                                )}
                                                            >
                                                                {fieldToCell(fields, field.name, row)}
                                                            </div>
                                                        )
                                                    }


                                                    return <div className="table-cell"/>;
                                                })}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}
