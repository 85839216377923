export function getInfoResource(data) {
    return {
        type: 'GET_INFO_RESOURCE',
        data: data
    };
}

export function getInfoResourceSilent(data) {
    return {
        type: 'GET_INFO_RESOURCE_SILENT',
        data: data
    };
}

export function resetCreateUpdateInfoResource() {
    return {
        type: 'RESET_CREATE_UPDATE_INFO_RESOURCE',
        data: []
    }
}

export function doneGetInfoResource(data) {
    return {
        type: 'DONE_GET_INFO_RESOURCE',
        data: data
    };
}

export function errorGetInfoResource(data) {
    return {
        type: 'ERROR_GET_INFO_RESOURCE',
        data: data
    };
}

export function resetInfoResource() {
    return {
        type: 'RESET_INFO_RESOURCE',
        data: []
    }
}

export function createInfoResource(data) {
    return {
        type: 'CREATE_INFO_RESOURCE',
        data: data
    };
}

export function doneCreateInfoResource(data) {
    return {
        type: 'DONE_CREATE_INFO_RESOURCE',
        data: data
    };
}

export function errorCreateInfoResource(data) {
    return {
        type: 'ERROR_CREATE_INFO_RESOURCE',
        data: data
    };
}

export function updateInfoResource(data) {
    return {
        type: 'UPDATE_INFO_RESOURCE',
        data: data
    };
}

export function doneUpdateInfoResource(data) {
    return {
        type: 'DONE_UPDATE_INFO_RESOURCE',
        data: data
    };
}

export function errorUpdateInfoResource(data) {
    return {
        type: 'ERROR_UPDATE_INFO_RESOURCE',
        data: data
    };
}

export function deleteInfoResource(data) {
    return {
        type: 'DELETE_INFO_RESOURCE',
        data: data
    };
}

export function doneDeleteInfoResource(data) {
    return {
        type: 'DONE_DELETE_INFO_RESOURCE',
        data: data
    };
}

export function errorDeleteInfoResource(data) {
    return {
        type: 'ERROR_DELETE_INFO_RESOURCE',
        data: data
    };
}
