import SocketAPI from "./socket";

const socket = new SocketAPI();

// Event
const OnEvent = "OnEvent";

const realtimeMiddleware = store => next => action => {
    switch (action.type) {
        case 'DONE_LOGIN':
            //connect(socket, store)
            break;
        case 'LOGOUT':
            //disconnect(socket, store)
            break;
        default:
            break;
    }

    return next(action);
};

realtimeMiddleware.getUrl = () => {
    return null;
}

realtimeMiddleware.getToken = () => {
    return null;
}

realtimeMiddleware.run = (store, getUrl, getToken) => {
    realtimeMiddleware.getUrl = getUrl;
    realtimeMiddleware.getToken = getToken;

    if (!!getToken()) {
        // connect(socket, store)
    }
}

const connect = (socket, store) => {
    socket.connect(realtimeMiddleware.getUrl(), realtimeMiddleware.getToken())
        .then(() => {
            console.log("realtimeMiddleware:connect")
        })
        .catch((e) => {
            console.log("realtimeMiddleware:error", e)
        });
}

const disconnect = (socket, store) => {
    socket.disconnect()
        .then(() => {
            console.log("notificationMiddleware:disconnect");
        })
        .catch((e) => {
            console.log("notificationMiddleware:error", e)
        });
}

export default realtimeMiddleware
