import React, {Component} from "react";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import ErrorFieldMessage from "../error-field-message";

export default class FieldText extends Component {
    handleMutateValue = (value) => {
        let mutatedValue = value
        if (value === "") {
            return value
        }
        if (this.props.numbersAndLettersOnly) {
            let regex = /[^A-Za-z0-9]+/
            mutatedValue = mutatedValue?.replace(regex, '')
        }
        if (this.props.upperCaseOnly) {
            mutatedValue = mutatedValue?.toUpperCase()
        }
        if (this.props.lowerCaseOnly) {
            mutatedValue = mutatedValue?.toLowerCase()
        }
        if (this.props.numbersOnly) {
            mutatedValue = mutatedValue?.replace(/\D/, '')
        }
        if (this.props.numberMin) {
            mutatedValue = mutatedValue?.replace(/\D/, '')
            if (+mutatedValue < +this.props.numberMin) return this.props.value
        }
        if (this.props.numberMax) {
            if (!/^[0-9,.]*$/g.test(mutatedValue) || (+mutatedValue > +this.props.numberMax)) {
                return this.props.value
            }
        }
        if (this.props.floatMax) {
            if (!/^[0-9,.]*$/g.test(mutatedValue) || (+(mutatedValue.replaceAll(",", "")) > +this.props.floatMax)) {
                return this.props.value
            }
        }

        return mutatedValue ?? this.props.value
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.props.widthClass + (' relative')}>
                    <input type={this.props.type}
                           onChange={(event) => {
                               this.props.onChange(this.props.name, this.handleMutateValue(event.target.value))
                           }}
                           name={this.props.name}
                           value={this.props.value}
                           placeholder={this.props.placeholder}
                           disabled={this.props.disabled}
                           className={this.props.addClass + (this.props.errorMessage ? " border-red-300 text-red-900 placeholder-red-300" : "") + (" block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ")}
                           style={this.props.disabled ? {color: "white"} : {}}
                           autoComplete={this.props.autocomplete}
                           maxLength={this.props.maxLength}
                           onFocus={(event) => {
                               if (this.props.onFocus) {
                                   this.props.onFocus(event);
                               }
                           }}
                    />
                    {this.props.errorMessage && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon
                                className={'h-5 w-5 text-red-500'}
                            />
                        </div>
                    )}
                </div>
                <ErrorFieldMessage
                    errorMessage={this.props.errorMessage}
                    translate={this.props.translate}
                />
            </React.Fragment>
        )
    }
}
