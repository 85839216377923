import {checkUserHelper} from "../services/api-util.js";
import {all, call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {
    doneGetTabsResource,
    doneSaveTabsResource,
    errorGetTabsResource,
    errorSaveTabsResource
} from "../actions/tabsResource";
import {pushNotification} from "../actions/ui";

export function* getTabsResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetTabsResource({resource: action.data.resource, data: result.data}));
    } else {
        yield put(errorGetTabsResource({resource: action.data.resource, data: result.data}));
    }
}

export function* watchGetTabsResource() {
    yield takeLatest('GET_TABS_RESOURCE', getTabsResourceCall);
}

export function* saveTabsResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const calls = action?.data?.commands.map((it) => {
        if (it.request === 'PUT') {
            return call(Api.putResource, user, it.params, it.resource);
        } else {
            return call(Api.updateResource, user, it.params, it.resource);
        }
    });

    const result = yield all(calls);

    if (result.length > 0) {
        if (action.data.onResultCallback && result) {
            action.data.onResultCallback(result)
        }

        yield put(doneSaveTabsResource(result));

        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        yield put(errorSaveTabsResource(result));
    }
}

export function* watchSaveTabsResource() {
    yield takeLatest("SAVE_TABS_RESOURCE", saveTabsResourceCall)
}
