import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDefaultTableOptions, getProp} from "../../../util/util";
import {
    createDataSelect,
    deleteDataSelect,
    getDataSelect,
    updateDataSelect
} from "../../../data/selectors/resourceSelectors";
import {Field, FieldsManager} from "../../../data/services/fields.js";
import LocalStorage from "../../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData} from "../../../util/util-fields";
import {DEFAULT_CRUD_STATE, USER_DATETIME_FORMAT_VALUES, USER_TIMEZONE_VALUES} from "../../../util/util-constants";
import Resources from "../../../data/services/resources";
import ActiveFilters from "../../../components/active-filters";
import ModalConfirm from "../../../components/modal-confirm";
import ModalSaveResource from "../../../components/modal-save-resource";
import NoRecordsTable from "../../../components/no-records-found/no-records-table";
import PaginationNew from "../../../components/pagination-new";
import TableFooter from "../../../components/resource-table/table-footer";
import ResourceTable from "../../../components/resource-table";
import TableFilters from "../../../components/simple-table/table-filters";
import TableCard from "../../../components/simple-table/table-card";
import PageHeader from "../../../components/layout-dashboard/page/page-header";
import Page from "../../../components/layout-dashboard/page";
import {object} from "prop-types";
import ContactPhoto from "../../../components/field-photo";

const AdminPeopleNursesView = ({
                             translate
                         }) => {
    /** Store
     ================================================================= */
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)

    /** Constants
     ================================================================= */
    const pagePath = Resources.Nurses + "_tab"
    const dispatch = useDispatch();

    const getData = getDataSelect({ dispatch: dispatch, user: user.data })
    const createData = createDataSelect({ dispatch: dispatch, user: user.data })
    const updateData = updateDataSelect({ dispatch: dispatch, user: user.data })
    const deleteData = deleteDataSelect({ dispatch: dispatch, user: user.data })
    const restoreData = updateDataSelect({ dispatch: dispatch, user: user.data })

    const data = getProp(resource, 'data.list', [])
    const count = getProp(resource, 'data.count', 0)
    const isLoading = getProp(resource, 'isLoading', false)

    const importModalRef = useRef()

    const metadata = {
        DepartmentID: {
            api: 'api/' + Resources.Departments,
            query: {
                offset: 0,
                limit: 30,
                IsEnabled: 1,
                archived: 0
            },
            searchMap: (it) => ({
                label: it.DepartmentName,
                value: it.DepartmentID,
            })
        }
    }
    /** Fields/Data definitions
     ================================================================= */
    const getFields = (item = null) => {
        const fieldTemplates = {
            FirstName: new Field('FirstName', '', ['empty'], !!item, 'text', {}, {}),
            LastName: new Field('LastName', '', ['empty'], !!item, 'text', {}, {}),
            Email: new Field('Email', '', ['empty'], !!item, 'email', {}, {}),
            PhoneNumber: new Field('PhoneNumber', '', [], false, 'text', {}, {}),
            IsEnabled: new Field('IsEnabled', '', [], false, 'checkbox', {hideDialog: true}, {}),
            Notes: new Field('Notes', '', [], false, 'textarea', {}, {}),
            DepartmentID: new Field('DepartmentID', '', ['empty'], false, 'select-search', { labelField: "DepartmentName" }, {}),
            Timezone: new Field('Timezone', '', [], false, 'select', {}, { values: USER_TIMEZONE_VALUES }),
            DateTimeFormat: new Field('DateTimeFormat', '', [], false, 'select', {}, { values: USER_DATETIME_FORMAT_VALUES }),
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    const getQueryFilterFields = () => ({
        search: new Field('search', '', [''], false, 'search', {
            containerClass: 'col-md-3',
        }, {}),
        archived: new Field('archived', '', [''], false, 'checkbox', {}),
        limit: new Field('limit', 20, [''], false, 'select', {})
    })

    /** State
     ================================================================= */
        // Fields/Query
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), {}, pagePath, translate))
    const [queryFilterFields, setQueryFilterFields] = useState(LocalStorage.has(pagePath + '_state') ? JSON.parse(LocalStorage.get(pagePath + '_state'))?.queryFilterFields : getQueryFilterFields())
    const [query, setQuery] = useState({
        ...DEFAULT_CRUD_STATE,
    })

    // Modals
    const [columnSettingsModalOpen, setColumnSettingsModalOpen] = useState(false)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const [avatar, setAvatar] = useState(undefined)
    const [isStateDirty, setIsStateDirty] = useState(false)
    const [hasImagePath, setHasImagePath] = useState(false);

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return Resources.Nurses
    }

    const getQuery = () => {
        return {
            ...query,
            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        getData({query: getQuery(), resource: getResourceName()})
    }

    /** UI events
     ================================================================= */
    const handleToggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen)
        setErrorMessage(null)
    }

    const handleToggleUpdateModal = (item = null) => {
        setSelectedItem(item)
        setUpdateModalOpen(!updateModalOpen)
        setErrorMessage(null)
    }

    const handleToggleConfirmModal = (item = null) => {
        setSelectedItem(item)
        setConfirmModalOpen(!confirmModalOpen)
    }

    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        handleResetPagination()
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFieldsTmp = Object.assign({}, queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFieldsTmp).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFieldsTmp, it.name, '')
        })

        setQueryFilterFields(queryFilterFieldsTmp)
        handleResetPagination()
    }

    const handleResetPagination = () => {
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    const handleGetImage = (file) => {
        setAvatar(file)
        setIsStateDirty(true)

    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [query])

    /** Render
     ================================================================= */

    return (
        <Page>
            <PageHeader
                title={translate("page.heading." + getResourceName())}
                titleClass="mr-5 text-2xl capitalize"
                buttonLabel={translate("btn.create_new")}
                onButtonClick={handleToggleCreateModal}
            />

            <div className="flex">
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <div className="ml-auto flex items-center relative">

                </div>
            </div>

            <TableCard addClass={'relative z-0'}>
                <TableFilters
                    filterFields={queryFilterFields}
                    maxHeaderFilters={6}
                    handleInputChange={handleFilterInputChange}
                    translate={translate}
                />

                <ResourceTable
                    data={data}
                    fields={getFields()}

                    translate={translate}
                    isLoading={isLoading}

                    options={tableOptions}

                    limit={queryFilterFields?.limit?.value ?? 10}

                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={handleUpdateSort}

                    onRowClick={handleToggleUpdateModal}
                    onEdit={handleToggleUpdateModal}
                    onDelete={handleToggleConfirmModal}
                    onRestore={handleToggleConfirmModal}
                />

                {/*Table footer*/}
                <TableFooter
                    show={!!data.length && !isLoading}
                >
                    <PaginationNew
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={
                            (name, value, currentPage) => name === "offset"
                                ? handleUpdateOffset(value, currentPage)
                                : handleFilterInputChange(name, value)
                        }
                        pageOffset={query.offset}
                        queryFields={queryFilterFields}
                        translate={translate}
                        pageLimit={queryFilterFields?.limit?.value}
                    />
                </TableFooter>

                <NoRecordsTable
                    show={(data.length === 0) && !resource.isLoading}
                    title={translate('text.no_matching_records')}
                    clearFilterText={translate('text.try_without_filters')}
                    onClearFilterClick={handleClearFiltersClick}
                    filters={queryFilterFields}
                />
            </TableCard>

            <ModalSaveResource
                ref={importModalRef}
                title={translate('modal_heading.CreateNew' + 'Nurse')}
                widthClass={"max-w-3xl"}
                gridColsClass={"grid-cols-1"}
                show={createModalOpen}
                onClose={handleToggleCreateModal}
                fields={getFields()}
                onSubmit={(params) => {
                    if (params) {
                        const query = getQuery()

                        createData({
                            params: params,
                            resource: getResourceName(),
                            //file: !!avatar && [avatar],
                            //fileResource: Resources.NursesImage,
                            piggyResource: getResourceName(),
                            query: query,
                            notificationMessage: translate('text.NurseCreatedSuccessfully')
                        })
                        handleToggleCreateModal()
                    }
                }}
                translate={translate}
                metadata={metadata}
                errorMessage={errorMessage}
                renderFields={(fieldTemplate, translate, handleItemInputChange, metadata) => {
                    const fieldsHtml = fieldsToHtml(Object.values(fieldTemplate), translate, handleItemInputChange, metadata)

                    return (
                        <div className={''}>
                            <div className={"relative mb-5"}>
                                <ContactPhoto
                                    imageID={selectedItem?.ContactID ?? null}
                                    onGetImage={(file) => handleGetImage(file)}
                                    stateChangerImagePath={setHasImagePath}
                                    stateChangerIsDirty={setIsStateDirty}
                                    avatarSet={(av) => {
                                        setAvatar(av)
                                        if (importModalRef && importModalRef.current) {
                                            importModalRef.current.handleCanSubmitChange(true)
                                        }
                                    }}
                                    hasImage={hasImagePath}
                                    translate={translate}
                                />
                            </div>
                            <div className="grid gap-y-3">
                                {fieldsHtml}
                            </div>
                        </div>
                    )
                }}
            />

            <ModalSaveResource
                ref={importModalRef}
                title={translate('modal_heading.EditNurse')}
                widthClass={"max-w-md"}
                gridColsClass={"grid-cols-1"}
                show={updateModalOpen}
                onClose={() => handleToggleUpdateModal()}
                fields={getFields(selectedItem)}
                onSubmit={(params) => {
                    if (params) {
                        params['ContactID'] = selectedItem['ContactID']
                        updateData({
                            params: params,
                            resource: getResourceName(),
                            piggyResource: getResourceName(),
                            //file: !!avatar && [avatar],
                            //fileResource: Resources.NursesImage,
                            query: getQuery(),
                            notificationMessage: translate('text.NurseUpdatedSuccessfully')
                        })
                        handleToggleUpdateModal()
                    }
                }}
                translate={translate}
                metadata={metadata}
                errorMessage={errorMessage}
                renderFields={(fieldTemplate, translate, handleItemInputChange, metadata) => {
                    const fieldsHtml = fieldsToHtml(Object.values(fieldTemplate), translate, handleItemInputChange, metadata)

                    return (
                        <div className={''}>
                            <div className={"relative mb-5"}>
                                <ContactPhoto
                                    imageID={selectedItem?.ContactID ?? null}
                                    onGetImage={(file) => handleGetImage(file)}
                                    stateChangerImagePath={setHasImagePath}
                                    stateChangerIsDirty={setIsStateDirty}
                                    avatarSet={(av) => {
                                        setAvatar(av)
                                        if (importModalRef && importModalRef.current) {
                                            importModalRef.current.handleCanSubmitChange(true)
                                        }
                                    }}
                                    hasImage={hasImagePath}
                                    translate={translate}
                                />
                            </div>
                            <div className="grid gap-y-3">
                                {fieldsHtml}
                            </div>
                        </div>
                    )
                }}
            />

            <ModalConfirm
                title={'Confirm'}
                show={!!confirmModalOpen}
                text={selectedItem?.ArchivedDate ? translate('message.confirm_restore_Nurse') : translate('message.confirm_archive_Nurse')}
                onClose={() => handleToggleConfirmModal()}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={() => {
                    if (selectedItem?.ArchivedDate) { // Restore
                        restoreData({
                            params: {
                                ...selectedItem,
                                ArchivedDate: 1
                            },
                            resource: getResourceName(),
                            piggyResource: getResourceName(),
                            query: getQuery(),
                            notificationMessage: translate('text.NurseRestoredSuccessfully')
                        })
                    } else { // Delete
                        deleteData({
                            query: {
                                ContactID: selectedItem['ContactID']
                            },
                            piggyQuery: getQuery(),
                            piggyResource: getResourceName(),
                            resource: getResourceName(),
                            notificationMessage: translate('text.NurseArchivedSuccessfully')
                        })
                    }
                    handleToggleConfirmModal()
                }}
            />
        </Page>
    )
}

export default AdminPeopleNursesView
