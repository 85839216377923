import React from "react";
import {classNames} from "../../../common/util/util-helpers";

export default function Page(props) {
    const defaultClass = "space-y-4 lg:col-span-12 px-5 bg-inverse ";
    const {replaceClass, className} = props;
    return (
        <div className={classNames(replaceClass ?? defaultClass, className)}>
            {props.children}
        </div>
    )
}
