import React, {useState} from "react";
import LayoutDashboard from "../../components/layout-dashboard";
import Page from "../../components/layout-dashboard/page";
import PageHeader from "../../components/layout-dashboard/page/page-header.js";
import {Field} from "../../data/services/fields.js";
import NavResponsive from "../../components/nav-responsive";
import MobileTabs from "../../components/tabs/mobile-tabs";
import ResourceListTab from "../../components/resource-list-tab";
import Resources from "../../data/services/resources";
import {USER_DATETIME_FORMAT_VALUES, USER_TIMEZONE_VALUES} from "../../util/util-constants";
import AdminDashboardView from "./dashboard";
import {fieldsToHtml} from "../../util/util-fields";
import {longTableColumn} from "../../util/util";
import AdminPeopleView from "./people";

const AdminView = (props) => {
    /** Constants
     ================================================================= */
    const {translate, match} = props

    /** Helpers
     ================================================================= */
    const getTabs = () => ([
        { name: 'ServiceAreas', resource: Resources.ServiceAreas, current: true, visible: true},
        { name: 'Departments', resource: Resources.Departments, current: false, visible: true},
        { name: 'People', resource: Resources.Nurses, current: false, visible: true},
        { name: 'Dashboard', resource: Resources.AdminDashboardReports, current: false, visible: true}
    ])

    /** Fields/Data definitions
     ================================================================= */

    /** State
     ================================================================= */
    const [tabs, setTabs] = useState(getTabs())
    const [selectedTab, setSelectedTab] = useState(tabs[0].resource)

    /** UI events
     ================================================================= */
    const handleTabChange = (resource) => {
        if (resource === selectedTab) return false

        setTabs(tabs.map((it) => {
            it.current = it.resource === resource;

            return it;
        }))
        setSelectedTab(resource)
    }

    /** Render
     ================================================================= */

    return (
        <LayoutDashboard
            {...props}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.Admin')}
                />

                <div className="border-b border-tm-gray-300 sm:px-6 lg:px-8 relative z-20" id={"scroll-to-top"}>
                    <div className="hidden lg:block">
                        <NavResponsive
                            className="relative pr-10 z-20"
                            tabs={tabs}
                            onTabChange={handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5 lg:hidden">
                        <MobileTabs
                            tabs={tabs}
                            onTabChange={handleTabChange}
                            translate={translate}
                        />
                    </div>
                </div>


                {selectedTab === Resources.ServiceAreas && (
                    <div>
                        <ResourceListTab
                            resourceKey={"ServiceAreaID"}
                            deleteKey={"ServiceAreaID"}
                            dialogTitle={"ServiceArea"}

                            resourceName={Resources.ServiceAreas}

                            originalFields={getDefaultTabFields(Resources.ServiceAreas)}

                            translate={translate}
                            originalQueryFilterFields={{
                                search: new Field('search', '', [''], false, 'search', {
                                    containerClass: 'col-md-3',
                                }, {}),
                                archived: new Field('archived', '', [''], false, 'checkbox', {}),
                                limit: new Field('limit', 20, [''], false, 'select', {})
                            }}

                            dialogClasses={{
                                widthClass: 'w-xl'
                            }}

                            renderCreateForm={(fieldTemplate, translate, handleItemInputChange, metadata) => {
                                const serviceAreaHtml = fieldsToHtml(Object.values(fieldTemplate).slice(0, 7), translate, handleItemInputChange, metadata)
                                const contactHtml = fieldsToHtml(Object.values(fieldTemplate).slice(7, 13), translate, handleItemInputChange, metadata)
                                return (
                                    <div className={'grid grid-cols-2 gap-5'}>
                                        <div>
                                            <h5 className="text-tm-white-700 text-base mb-3">Service Area</h5>
                                            {serviceAreaHtml}
                                        </div>
                                        <div>
                                            <h5 className="text-tm-white-700 text-base mb-3">Contact</h5>
                                            {contactHtml}
                                        </div>
                                    </div>
                                )
                            }}

                            renderUpdateForm={(fieldTemplate, translate, handleItemInputChange, metadata) => {
                                const serviceAreaHtml = fieldsToHtml(Object.values(fieldTemplate).slice(0, 7), translate, handleItemInputChange, metadata)
                                const contactHtml = fieldsToHtml(Object.values(fieldTemplate).slice(7, 13), translate, handleItemInputChange, metadata)
                                return (
                                    <div className={'grid grid-cols-2 gap-5'}>
                                        <div>
                                            <h5 className="text-tm-white-700 text-base mb-3">Service Area</h5>
                                            {serviceAreaHtml}
                                        </div>
                                        <div>
                                            <h5 className="text-tm-white-700 text-base mb-3">Contact</h5>
                                            {contactHtml}
                                        </div>
                                    </div>
                                )
                            }}
                        />
                    </div>
                )}

                {selectedTab === Resources.Departments && (
                    <div>
                        <ResourceListTab
                            resourceKey={"DepartmentID"}
                            deleteKey={"DepartmentID"}
                            dialogTitle={"Department"}

                            resourceName={Resources.Departments}

                            originalFields={getDefaultTabFields(Resources.Departments)}

                            translate={translate}
                            originalQueryFilterFields={{
                                search: new Field('search', '', [''], false, 'search', {
                                    containerClass: 'col-md-3',
                                }, {}),
                                archived: new Field('archived', '', [''], false, 'checkbox', {}),
                                IsEnabled: new Field('IsEnabled', '', [''], false, 'checkbox', {}, {htmlFor: 'IsEnabledFilter'}),
                                limit: new Field('limit', 20, [''], false, 'select', {})
                            }}
                        />
                    </div>
                )}

                {selectedTab === Resources.Nurses && (
                    <div>
                        <AdminPeopleView
                            translate={translate}
                            match={match}
                        />
                    </div>
                )}

                {selectedTab === Resources.AdminDashboardReports && (
                    <div>
                        <AdminDashboardView
                            translate={translate}
                        />
                    </div>
                )}

            </Page>
        </LayoutDashboard>
    )
}

export default AdminView

const getDefaultTabFields = (key) => {
    switch (key) {
        case Resources.ServiceAreas:
            return {
                ServiceAreaName: new Field('ServiceAreaName', '', [], false, 'text', {}, {}),
                StartDate: new Field('StartDate', '', [], false, 'date', {}, {}),
                Contact: new Field('Contact', '', [], false, 'text', {}, {}),
                ContactPhone: new Field('ContactPhone', '', [], false, 'text', {}, {}),
                ContactEmail: new Field('ContactEmail', '', [], false, 'text', {}, {}),
                IsEnabled: new Field('IsEnabled', '', [], false, 'checkbox', {}, {}),
                Notes: new Field('Notes', '', [], false, 'textarea', {
                    render: (it) => longTableColumn(it.Notes)
                }, { rows: 10 }),
                DirectorManagerName: new Field('DirectorManagerName', '', [], false, 'text', { hideTable: true }, {}),
                DirectorManagerPhone: new Field('DirectorManagerPhone', '', [], false, 'text', { hideTable: true }, {}),
                OperationsContact: new Field('OperationsContact', '', [], false, 'text', { hideTable: true }, {}),
                OperationsContactPhone: new Field('OperationsContactPhone', '', [], false, 'text', { hideTable: true }, {}),
                OperationsAfterHoursContact: new Field('OperationsAfterHoursContact', '', [], false, 'text', { hideTable: true }, {}),
                EmergencyContact: new Field('EmergencyContact', '', [], false, 'text', { hideTable: true }, {}),
            }
        case Resources.Departments:
            return {
                DepartmentName: new Field('DepartmentName', '', [], false, 'text', {}, {}),
                StartDate: new Field('StartDate', '', [], false, 'date', {}, {}),
                IsEnabled: new Field('IsEnabled', '', [], false, 'checkbox', {}, {}),
                Notes: new Field('Notes', '', [], false, 'textarea', {}, {}),
            }
        case Resources.Nurses:
            return {
                FirstName: new Field('FirstName', '', ['empty'], false, 'text', {}, {}),
                LastName: new Field('LastName', '', ['empty'], false, 'text', {}, {}),
                Email: new Field('Email', '', ['empty'], false, 'email', {}, {}),
                PhoneNumber: new Field('PhoneNumber', '', [], false, 'text', {}, {}),
                IsEnabled: new Field('IsEnabled', '', [], false, 'checkbox', {hideDialog: true}, {}),
                Notes: new Field('Notes', '', [], false, 'textarea', {}, {}),
                DepartmentID: new Field('DepartmentID', '', [], false, 'select-search', { labelField: "DepartmentName" }, {}),
                Timezone: new Field('Timezone', '', [], false, 'select', {}, { values: USER_TIMEZONE_VALUES }),
                DateTimeFormat: new Field('DateTimeFormat', '', [], false, 'select', {}, { values: USER_DATETIME_FORMAT_VALUES }),
            }
        case Resources.AdminDashboardReports:
            return {
                DepartmentID: new Field('DepartmentID', '', [], false, 'select-search', { labelField: "DepartmentName" }, {}),
                DueDate: new Field('DueDate', '', [], false, 'date', {}, {}),
                Description: new Field('Description', '', [], false, 'textarea', {}, {}),
            }
        default:
            return {}
    }
}
