import React, {Component} from "react";
import ErrorFieldMessage from "../error-field-message";

export default class FieldTextarea extends Component {

    render() {
        return (
            <React.Fragment>
                <textarea
                    rows={(this.props.rows ? this.props.rows : 4)}
                    className={this.props.addClass + (this.props.errorMessage ? " border-red-300 text-red-900 placeholder-red-300" : "") + (" rounded-xl border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 text-sm")}
                    onChange={(event) => {
                        this.props.onChange(this.props.name, event.target.value);
                    }}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                />
                <ErrorFieldMessage
                    errorMessage={this.props.errorMessage}
                    translate={this.props.translate}
                />
            </React.Fragment>
        )
    }
}
