import React from "react";
import {classNames} from "../../common/util/util-helpers";

export default function TableCard({id, addClass = '', children = null}) {
    return (
        <div
            id={id}
            className={classNames("md:bg-inverse rounded-lg md:border border-tm-gray-300 md:shadow-lg relative", addClass)}>
            {children}
        </div>
    )
}
