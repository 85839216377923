import React, {useEffect, useMemo, useState} from "react";
import ReactCrop from "react-image-crop";

function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                //reject(new Error('Canvas is empty'));
                return;
            }
            blob.name = fileName;
            let fileImageObject = new File([blob], fileName);
            resolve(fileImageObject);
        }, 'image/png');
    });
}

async function makeClientCrop(crop, imageRef) {
    if (!!imageRef && crop.width && crop.height) {
        return await getCroppedImg(
            imageRef,
            crop,
            'newFile.jpeg'
        );
    }
}

export default function CropImage({file, defaultCrop, getCroppedFile}) {
    const [imageElement, setImageElement] = useState();

    const onCropComplete = crop => {
        makeClientCrop(crop, imageElement).then(r => getCroppedFile(r));
    };

    const onImageLoaded = image => {
        setImageElement(image);
    };

    const [crop, setCrop] = useState(defaultCrop ?? {
        unit: '%',
        x: 0,
        y: 0,
        height: 100,
        aspect: 1,
    })

    const src = useMemo(() => URL.createObjectURL(file), [file]);

    useEffect(() => {
        // For initial crop only, after image element is loaded
        if (!!crop && !!imageElement) {
            makeClientCrop(crop, imageElement).then(r => getCroppedFile(r));
        }
    }, [imageElement])

    return (
        <React.Fragment>
            {!!src && (
                <div className="relative">
                    <ReactCrop
                       onChange={c => setCrop(c)}
                       crop={crop}
                       src={src}
                       onComplete={onCropComplete}
                       onImageLoaded={onImageLoaded}
                    />
                </div>
            )}
        </React.Fragment>
    )
}