import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDefaultTableOptions, getDefaultTableOptionsJSON, getProp, saveTableColumns} from "../../util/util";
import {
    createDataSelect,
    deleteDataSelect,
    getDataSelect,
    updateDataSelect
} from "../../data/selectors/resourceSelectors";
import {FieldsManager} from "../../data/services/fields.js";
import LocalStorage from "../../util/localStorage";
import PageHeader from "../layout-dashboard/page/page-header.js";
import {fillFieldsFromData} from "../../util/util-fields";
import Page from "../layout-dashboard/page";
import ActiveFilters from "../active-filters";
import TableFilters from "../simple-table/table-filters";
import ResourceTable from "../resource-table";
import NoRecordsTable from "../no-records-found/no-records-table";
import TableCard from "../simple-table/table-card";
import ModalSaveResource from "../modal-save-resource";
import ModalConfirm from "../modal-confirm";
import TableOptionsDialog from "../table-options-dialog";
import {cloneDeep} from "../../common/util/util-vanilla";
import {
    DocumentDownloadIcon,
    DotsVerticalIcon, TrashIcon,
} from "@heroicons/react/outline";
import {Popover, Transition} from "@headlessui/react";
import {classNames} from "../../common/util/util-helpers";
import {download} from "../../data/actions/download";
import {currentDate} from "../../common/util/util-dates";
import LoaderSmall from "../loader-small";
import {DEFAULT_CRUD_STATE} from "../../util/util-constants";
import TableFooter from "../resource-table/table-footer";
import PaginationNew from "../pagination-new";
import Tippy from "@tippyjs/react";

const ResourceListTab = ({
                             translate,
                             mainID,
                             dialogTitle = 'Resource',
                             resourceName,
                             primaryKey = 'Id',
                             resourceKey = 'Id',
                             tableCustomActions = [],
                             getRawFields,
                             deleteKey,
                             disableCreate,
                             disableEdit,
                             disableDelete,
                             customDeleteVisible,
                             disableRestore,
                             disablePagination,
                             tableCustomDefaults = {},
                             originalFields,
                             processFields,
                             tfoot,
                             originalQueryFilterFields,
                             metadata,
                             tableFiltersContainerClass,
                             performanceModeLimit,

                             fetchDataCallback,
                             createDataCallback,
                             updateDataCallback,
                             deleteDataCallback,
                             resourceData,
                             customHtml,
                             additionalExcels,
                             onDownloadExcel,

                             renderCreateForm,
                             renderUpdateForm,
                             renderDeleteForm, // TODO

                             handleModalInputChange,
                             hideLimit,
                             hideSearch,
                             removePageHeaderLabel,

                             dialogClasses
}) => {
    /** Store
     ================================================================= */
    const user = useSelector((state) => state.user)
    const resource = resourceData ?? useSelector((state) => state.resource)
    const downloadIsLoading = useSelector((state) => state.download.isLoading)

    /** Constants
     ================================================================= */
    const pagePath = resourceName + "_tab"
    const dispatch = useDispatch();

    const getData = getDataSelect({ dispatch: dispatch, user: user.data })
    const createData = createDataSelect({ dispatch: dispatch, user: user.data })
    const updateData = updateDataSelect({ dispatch: dispatch, user: user.data })
    const deleteData = deleteDataSelect({ dispatch: dispatch, user: user.data })
    const restoreData = updateDataSelect({ dispatch: dispatch, user: user.data })

    const data = getProp(resource, 'data.list', [])
    const count = getProp(resource, 'data.count', 0)
    const isLoading = getProp(resource, 'isLoading', false)

    /** Fields/Data definitions
     ================================================================= */
    const getFields = (item = null) => {
        const fieldTemplates = cloneDeep(originalFields);

        return fillFieldsFromData(processFields ? processFields(fieldTemplates, cloneDeep(item)) : fieldTemplates, item);
    };

    const getQueryFilterFields = () => ({
        ...originalQueryFilterFields
    })

    /** State
     ================================================================= */
    // Fields/Query
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), tableCustomDefaults, pagePath, translate))
    const [queryFilterFields, setQueryFilterFields] = useState(LocalStorage.has(pagePath + '_state') ? JSON.parse(LocalStorage.get(pagePath + '_state'))?.queryFilterFields : getQueryFilterFields())
    const [query, setQuery] = useState({
        ...DEFAULT_CRUD_STATE,
    })

    // Modals
    const [columnSettingsModalOpen, setColumnSettingsModalOpen] = useState(false)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return resourceName ?? "";
    }

    const getQuery = () => {
        return {
            ...query,
            limit: hideLimit ? 100 : 0,
            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        if (fetchDataCallback) {
            fetchDataCallback({query: getQuery(), resource: getResourceName()})
        } else {
            getData({query: getQuery(), resource: getResourceName()})
        }
    }

    const downloadExcel = (resource = '') => {
        dispatch(download({
            user: user.data,
            resource: resource,
            query: Object.assign({},
                { format: 'EXCEL', name: resource + currentDate() + '.xlsx' },
                getQuery()
            )
        }))
    }

    /** UI events
     ================================================================= */
    const handleToggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen)
        setErrorMessage(null)
    }

    const handleToggleUpdateModal = (item = null) => {
        setSelectedItem(item)
        setUpdateModalOpen(!updateModalOpen)
        setErrorMessage(null)
    }

    const handleToggleConfirmModal = (item = null) => {
        setSelectedItem(item)
        setConfirmModalOpen(!confirmModalOpen)
    }

    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        handleResetPagination()
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFieldsTmp = Object.assign({}, queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFieldsTmp).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFieldsTmp, it.name, '')
        })

        setQueryFilterFields(queryFilterFieldsTmp)
        handleResetPagination()
    }

    const handleResetPagination = () => {
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    const handleToggleColumnSettingsDialog = () => {
        setColumnSettingsModalOpen(!columnSettingsModalOpen)
    }

    const handleSetTableColumnOptions = (columns) => {
        let tableOptionsTmp = cloneDeep(tableOptions)

        tableOptionsTmp.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        setTableOptions(tableOptionsTmp)
        setColumnSettingsModalOpen(false)
        saveTableColumns(pagePath, tableOptionsTmp)
    }

    const getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(getFields(), tableCustomDefaults, translate);
    }

    const handleSetOptions = (options) => {
        setTableOptions(options)
        saveTableColumns(pagePath, options)
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [query])

    /** Render
     ================================================================= */

    return (
        <Page>
            <PageHeader
                removePageHeaderLabel={removePageHeaderLabel}
                title={translate("page.heading." + resourceName)}
                titleClass="mr-5 text-2xl capitalize"
                customHtml={customHtml}
                buttonLabel={translate("btn.create_new")}
                onButtonClick={handleToggleCreateModal}
                buttonHidden={!!disableCreate}
            />

            <div className="flex">
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <div className="ml-auto flex items-center relative">
                    {additionalExcels && (
                        <Popover className="text-gray-400 cursor-pointer items-center flex justify-center">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className="btn-icon mr-2"
                                    >
                                        <DotsVerticalIcon
                                            className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'w-6 h-6')}
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="absolute z-10 right-0 transform top-12 mt-3 px-2 w-screen max-w-sm sm:px-0">
                                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div className="relative grid bg-inverse p-3">
                                                    {downloadIsLoading && (
                                                        <div className={'flex items-center justify-center'}>
                                                            <LoaderSmall/>
                                                        </div>
                                                    )}
                                                    {!downloadIsLoading && additionalExcels.map((it) => (
                                                        <div
                                                            className="flex items-start rounded-lg transition ease-in-out duration-150 cursor-pointer"
                                                            onClick={() => downloadExcel(it.resource)}
                                                        >
                                                            <div
                                                                className="flex items-center rounded-lg hover:bg-gray-50 w-full p-2">
                                                                <div
                                                                    className="flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full ">
                                                                    <DocumentDownloadIcon className={'w-6 h-6'}/>
                                                                </div>
                                                                <div className="ml-4">
                                                                    <p className=" text-sm text-gray-900">
                                                                        {it.name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                    )}

                    {onDownloadExcel && (
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon mr-2"
                                onClick={onDownloadExcel}
                            >
                                {downloadIsLoading ? (
                                    <div className={'flex items-center justify-center w-5 h-5'}>
                                        <LoaderSmall svgClass={'text-gray-200 animate-spin dark:text-gray-600 fill-primary w-5 h-5'}/>
                                    </div>
                                ) :
                                    <DocumentDownloadIcon className="w-5 h-5"/>
                                }
                            </button>
                        </Tippy>
                    )}
                </div>
            </div>

            <TableCard addClass={'relative z-0'}>
                <TableFilters
                    filterFields={queryFilterFields}
                    maxHeaderFilters={6}
                    containerClass={tableFiltersContainerClass}
                    handleInputChange={handleFilterInputChange}
                    translate={translate}
                    hideLimit={!!hideLimit}
                    hideSearch={!!hideSearch}
                    selects={metadata ?? {}}
                />

                <ResourceTable
                    data={data}
                    fields={getFields()}

                    translate={translate}
                    isLoading={isLoading}

                    options={tableOptions}

                    limit={queryFilterFields?.limit?.value ?? 10}

                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={handleUpdateSort}

                    onRowClick={!disableEdit && handleToggleUpdateModal}
                    onEdit={!disableEdit && handleToggleUpdateModal}
                    onDelete={!disableDelete && handleToggleConfirmModal}
                    onRestore={(!disableDelete || !disableRestore) && handleToggleConfirmModal}

                    actions={[...tableCustomActions].concat(!!customDeleteVisible ? [
                        {
                            action: handleToggleConfirmModal,
                            icon: TrashIcon, // make this a function
                            visible: (item) => customDeleteVisible(item),
                            label: false, // make this a function
                            title: translate('btn.delete'),
                            disabled: false,
                            class: false,
                            iconClass: false
                        }
                    ] : [])}

                    tfoot={tfoot}
                    performanceModeLimit={performanceModeLimit}
                />

                {/*Table footer*/}
                <TableFooter
                    show={!!data.length && !isLoading && !disablePagination}
                >
                    <PaginationNew
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={
                            (name, value, currentPage) => name === "offset"
                                ? handleUpdateOffset(value, currentPage)
                                : handleFilterInputChange(name, value)
                        }
                        pageOffset={query.offset}
                        queryFields={queryFilterFields}
                        translate={translate}
                        pageLimit={hideLimit ? 100 : queryFilterFields?.limit?.value}
                    />
                </TableFooter>

                <NoRecordsTable
                    show={(data.length === 0) && !resource.isLoading}
                    title={translate('text.no_matching_records')}
                    clearFilterText={translate('text.try_without_filters')}
                    onClearFilterClick={handleClearFiltersClick}
                    filters={queryFilterFields}
                />
            </TableCard>

            <ModalSaveResource
                title={translate('modal_heading.CreateNew' + dialogTitle)}
                widthClass={ dialogClasses?.widthClass ? dialogClasses.widthClass : "max-w-md" }
                gridColsClass={ dialogClasses?.gridColsClass ? dialogClasses?.gridColsClass : "grid-cols-1" }
                show={createModalOpen}
                onClose={handleToggleCreateModal}
                fields={FieldsManager.cleanFields(originalFields)}
                handleInputChange={handleModalInputChange}
                getRawFields={getRawFields}
                onSubmit={(params) => {
                    if (params) {
                        const query = getQuery()
                        params[primaryKey] = mainID

                        if (createDataCallback) {
                            createDataCallback({
                                params: params,
                                resource: getResourceName(),
                                piggyResource: getResourceName(),
                                query: getQuery(),
                                notificationMessage: translate('text.' + dialogTitle + 'CreatedSuccessfully'),
                                onResultCallback: (res) => {
                                    if (res?.data?.id || !res?.data?.message) {
                                        handleToggleCreateModal()
                                    } else {
                                        setErrorMessage(res?.data?.message.includes(" ") ? res?.data?.message.replaceAll(" ", "_") : res?.data?.message)
                                    }
                                }
                            })
                        } else {
                            createData({
                                params: params,
                                resource: getResourceName(),
                                piggyResource: getResourceName(),
                                query: query,
                                notificationMessage: translate('text.' + dialogTitle + 'CreatedSuccessfully')
                            })
                            handleToggleCreateModal()
                        }
                    }
                }}
                translate={translate}
                metadata={metadata ?? {}}
                renderFields={renderCreateForm ? renderCreateForm : null}
                errorMessage={errorMessage}
            />

            <ModalSaveResource
                title={translate('modal_heading.Edit' + dialogTitle)}
                widthClass={ dialogClasses?.widthClass ? dialogClasses.widthClass : "max-w-md" }
                gridColsClass={ dialogClasses?.gridColsClass ? dialogClasses?.gridColsClass : "grid-cols-1" }
                show={updateModalOpen}
                onClose={() => handleToggleUpdateModal()}
                fields={getFields(selectedItem)}
                handleInputChange={handleModalInputChange}
                onSubmit={(params) => {
                    if (params) {
                        if (updateDataCallback) {
                            params[primaryKey] = selectedItem[primaryKey]
                            params[resourceKey] = selectedItem[resourceKey]
                            updateDataCallback({
                                item: selectedItem,
                                params: params,
                                resource: getResourceName(),
                                piggyResource: getResourceName(),
                                query: getQuery(),
                                notificationMessage: translate('text.' + dialogTitle + 'UpdatedSuccessfully'),
                                onResultCallback: (res) => {
                                    if (res?.data?.id || !res?.data?.message) {
                                        handleToggleUpdateModal()
                                    } else {
                                        setErrorMessage(res?.data?.message.includes(" ") ? res?.data?.message.replaceAll(" ", "_") : res?.data?.message)
                                    }
                                }
                            })
                        } else {
                            params[primaryKey] = selectedItem[primaryKey]
                            params[resourceKey] = selectedItem[resourceKey]
                            updateData({
                                params: params,
                                resource: getResourceName(),
                                piggyResource: getResourceName(),
                                query: getQuery(),
                                notificationMessage: translate('text.' + dialogTitle + 'UpdatedSuccessfully')
                            })
                            handleToggleUpdateModal()
                        }
                    }
                }}
                translate={translate}
                metadata={metadata ?? {}}
                renderFields={renderUpdateForm ? renderUpdateForm : null}
                errorMessage={errorMessage}
            />

            <ModalConfirm
                title={'Confirm'}
                show={!!confirmModalOpen}
                text={selectedItem?.ArchivedDate ? translate('message.confirm_restore_' + dialogTitle) : translate('message.confirm_archive_' + dialogTitle)}
                onClose={() => handleToggleConfirmModal()}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={() => {
                    let key = primaryKey;
                    if (!!deleteKey) {
                        key = deleteKey;
                    }

                    if (deleteDataCallback) {
                        deleteDataCallback({
                            item: selectedItem,
                            query: {
                                [key]: selectedItem[key]
                            },
                            piggyQuery: getQuery(),
                            piggyResource: getResourceName(),
                            resource: getResourceName(),
                            notificationMessage: translate('text.' + dialogTitle + 'ResourceDeletedSuccessfully')
                        })
                    } else if (selectedItem?.ArchivedDate) { // Restore
                        restoreData({
                            params: {
                                ...selectedItem,
                                ArchivedDate: 1
                            },
                            resource: getResourceName(),
                            piggyResource: getResourceName(),
                            query: getQuery(),
                            notificationMessage: translate('text.' + dialogTitle + 'RestoredSuccessfully')
                        })
                    } else { // Delete
                        deleteData({
                            query: {
                                [key]: selectedItem[key]
                            },
                            piggyQuery: getQuery(),
                            piggyResource: getResourceName(),
                            resource: getResourceName(),
                            notificationMessage: translate('text.' + dialogTitle + 'ArchivedSuccessfully')
                        })
                    }
                    handleToggleConfirmModal()
                }}
            />

            <TableOptionsDialog
                show={columnSettingsModalOpen}
                pagePath={pagePath}
                columns={tableOptions.columns}
                setTableColumnOptions={handleSetTableColumnOptions}
                getDefaultTableColumnOptions={getDefaultTableColumnOptions}
                onClose={handleToggleColumnSettingsDialog}
                translate={translate}
            />
        </Page>
    )
}

export default ResourceListTab
