import {classNames} from "../../common/util/util-helpers";
import React, {useMemo, useState} from "react";
import { fieldToCell, getLabel, TableActions } from "./table-utils";

export default function TableVertical({fields, resourceData, options, tableKey, isLoading, actions, onView, onEdit, onDelete, onRestore, translate}) {
    const [focus, setFocus] = useState("");
    const hasDefaultActions = !!onView || !!onEdit || !!onDelete || !!onRestore;

    let columnsData = options?.columns ? options.columns : {};
    const columnsList = !!Object.values(columnsData).length && Object.values(columnsData);
    let hasSubColumns = false;

    const columns = useMemo(() => !!columnsList.length
        ? columnsList.filter(column => column.show && column.showMobile).reduce((memo, column) => {
            if (!!column?.subColumns?.length) {
                hasSubColumns = true;
            }

            memo.push(column.name);
            return memo
        }, [])
        : Object.keys(fields)
    , [resourceData, columnsList]
    );

    const onTableBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setFocus(null);
        }
    }

    return (
        <div className="space-y-8">
            {
                resourceData.map((row, rowIndex) => {
                    const isTableFocused = row[tableKey] === focus;
                    return (
                        <div
                            key={row[tableKey]}
                            tabIndex={0}
                            onClick={() => null}
                            className={classNames(
                                isTableFocused ? "ring ring-link" : undefined,
                                "relative border border-tm-gray-300 rounded-lg overflow-hidden bg-inverse block w-full"
                            )}
                            onFocus={() => setFocus(row[tableKey])}
                            onBlur={onTableBlur}
                        >
                            {columns.map((colName, i) => {
                                const cellData = fieldToCell(fields, colName, row);

                                return (
                                    <div className={
                                        classNames(!!i ? "border-t" : undefined , "w-full flex h-10 items-center border-tm-gray-300")
                                    }>
                                        <div className={classNames("w-32 bg-tm-gray-50 mr-2 h-full flex shrink-0 items-center px-3 border-tm-gray-300")}>
                                            <span className="truncate font-bold">{getLabel(colName, columnsData)}</span>
                                        </div>

                                        {!isLoading && <p className="truncate max-w-full">{cellData}</p>}
                                    </div>
                                )
                            })}

                            {isTableFocused && (
                                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-10">
                                    <div className="p-2 bg-inverse rounded-full border border-tm-gray-300">
                                        <TableActions
                                            hasDefaultActions={hasDefaultActions}
                                            actions={actions}

                                            item={row}
                                            rowIndex={rowIndex}
                                            onView={onView}
                                            onEdit={onEdit}
                                            onDelete={onDelete}
                                            onRestore={onRestore}
                                            translate={translate}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                })
            }
        </div>
    )
}
