import React, { useEffect, useState } from "react";
import DesktopView from "./desktop-view";
import MobileView from "./mobile-view";
import LocalStorage from "../../util/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { logoutClear } from "../../data/actions/user";
import { LAYOUT_DASHBOARD_MENU_ITEMS } from "../../util/util-constants";
import Notifications from "./notifications";
import LoaderSmall from "../loader-small";
import { classNames } from "../../common/util/util-helpers";
import DemoMenu from "./demo-menu";

const LayoutDashboard = (props) => {

    /** Constants
     ================================================================= */
    const {isDirty, translate} = props
    const currentPath = props.match.path;
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user)
    const ui = useSelector((state) => state.ui)

    const [menuOpen, setMenuOpen] = useState(false)
    const [globalSearchOpen, setGlobalSearchOpen] = useState(false)
    const [logout, setLogout] = useState(false)
    const [darkTheme, setDarkTheme] = useState(LocalStorage.get("IS_THEME_DARK") ?? false)
    const [selectedItem, setSelectedItem] = useState({
        selectedMenuItem: null
    })
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)
    const [blockedLocation, setBlockedLocation] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)

    const menuItems = props.menuItems ?? LAYOUT_DASHBOARD_MENU_ITEMS(currentPath)
    /** UI events
     ================================================================= */
    const handleToggleDarkMode = () => {
        document.documentElement.classList.toggle('dark')
        setDarkTheme(!darkTheme)
        LocalStorage.set("IS_THEME_DARK", !LocalStorage.get("IS_THEME_DARK"))
    }

    const handleToggleGlobalSearch = () => {
        setGlobalSearchOpen(!globalSearchOpen)
    }

    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation) {
            handleShowConfirmDialog(nextLocation)
            return false
        }

        return true
    }

    const handleShowConfirmDialog = (location) => {
        setBlockedLocation(location)
        setConfirmedNavigation(true)
        setConfirmModal(() => () => {
            props.history.push(location.pathname + (location.search ?? ""))
            setConfirmModal(false)
            setConfirmedNavigation(false)
        })
    }

    /** Lifecycles
     ================================================================= */
    useEffect(() => {
        darkTheme && document.documentElement.classList.add('dark')
        document.title = props.translate("page_title." + props.match.path, [props.match.params.id]) + " | Nursing";
    }, [])

    useEffect(() => {
        if (!!logout) {
            LocalStorage.remove('user')
            LocalStorage.remove('lookup')
            dispatch(logoutClear())
            props.history.push('/login')
        }
    }, [logout])

    useEffect(() => {
        if (user?.logout) {
            setLogout(true);
        }
    }, [user])

    /** Helpers
     ================================================================= */


    /** Render
     ================================================================= */
    return (
        <div className="bg-inverse">
            <DemoMenu
                match={props.match}
                onLogout={() => setLogout(true)}
            />

            <div className={
                classNames(
                    props.bodyClass ?? "min-h-[calc(100vh-4rem)]",
                    "pt-5",
                    props.addBodyClass
                )}
            >
                {props.children}
            </div>

            <Notifications
                dispatch={dispatch}
                translate={props.translate}
                ui={ui}
            />

            {props.fullScreenLoader && (
                <div
                    className={'fixed top-0 left-0 w-screen h-screen z-50 flex justify-center items-center bg-opacity-inverse'}>
                    <LoaderSmall/>
                </div>
            )}
        </div>
    )
}

export default LayoutDashboard;
