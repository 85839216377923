import React, {Component} from "react";
import Select from "react-select";
import {getCustomStyles} from "../../data/themes";
import {scrollIntoView} from "../../common/util/util-vanilla";

export default class MultiSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const pairs = this.props.values ? this.props.values : {};

        let values = Object.keys(pairs).sort((a, b) => {
            return (pairs[a] == pairs[b]) ? 0 : ((pairs[a] > pairs[b]) ? 1 : -1);
        }).map((key, i) => ({value: key, label: pairs[key]}));

        return (
            <React.Fragment>
                <Select
                    placeholder={this.props.placeholder ? this.props.placeholder : "Select"}
                    isMulti
                    styles={getCustomStyles()}
                    options={values}
                    defaultValue={this.props.value}
                    onChange={(selectedOptions) => {
                        this.props.onChange(this.props.name, selectedOptions);
                    }}
                    onInputChange={(e) => {
                        if (this.props.onInputChange) {
                            this.props.onInputChange(this.props.name, e)
                        }
                    }}
                    value={Array.isArray(this.props.value) ? this.props.value.map(value => {
                        value.value = String(value.value);
                        return value
                    }) : this.props.value}
                    closeMenuOnSelect={false}
                    className={"select-css" + (this.props.addClass ? " " + this.props.addClass : "")}
                    isDisabled={this.props.disabled}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                />
                {this.props.errorMessage && (
                    <div className={"invalid-feedback"}>
                        {!!this.props.translate && (
                            this.props.errorMessage.reduce((memo, it) => {
                                return this.props.translate(it) + (!!memo ? "," + memo : "")
                            }, "")
                        )}
                        {!this.props.translate && (
                            <span>Field required*</span>
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }
}
