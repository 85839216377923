export function getStackedResource(data) {
    return {
        type: 'GET_STACKED_RESOURCE',
        data: data
    };
}

export function doneGetStackedResource(data) {
    return {
        type: 'DONE_GET_STACKED_RESOURCE',
        data: data
    };
}

export function errorGetStackedResource(data) {
    return {
        type: 'ERROR_GET_STACKED_RESOURCE',
        data: data
    };
}


export function createStackedResource(data) {
    return {
        type: 'CREATE_STACKED_RESOURCE',
        data: data
    };
}

export function doneCreateStackedResource(data) {
    return {
        type: 'DONE_CREATE_STACKED_RESOURCE',
        data: data
    };
}

export function errorCreateStackedResource(data) {
    return {
        type: 'ERROR_CREATE_STACKED_RESOURCE',
        data: data
    };
}

export function updateStackedResource(data) {
    return {
        type: 'UPDATE_STACKED_RESOURCE',
        data: data
    };
}

export function doneUpdateStackedResource(data) {
    return {
        type: 'DONE_UPDATE_STACKED_RESOURCE',
        data: data
    };
}

export function errorUpdateStackedResource(data) {
    return {
        type: 'ERROR_UPDATE_STACKED_RESOURCE',
        data: data
    };
}

export function deleteStackedResource(data) {
    return {
        type: 'DELETE_STACKED_RESOURCE',
        data: data
    };
}

export function doneDeleteStackedResource(data) {
    return {
        type: 'DONE_DELETE_STACKED_RESOURCE',
        data: data
    };
}

export function errorDeleteStackedResource(data) {
    return {
        type: 'ERROR_DELETE_STACKED_RESOURCE',
        data: data
    };
}