import {call, put, takeEvery, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util.js";
import {
    doneDeleteDocument,
    doneDownload, doneDownloadStacked, doneUploadDocument, errorDeleteDocument,
    errorDownload, errorDownloadStacked, errorUploadDocument,
} from "../actions/download";
import {doneGetResource} from "../actions/resource";
import {pushNotification} from "../actions/ui";
import {doneGetSecondResource} from "../actions/secondResource";
import {doneGetStackedResource, errorGetStackedResource} from "../actions/stackedResource";

export function* downloadCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.downloadFile, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneDownload(result.data));
            }
        } else {
            yield put(doneDownload(result.data));
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        yield put(errorDownload(result.data));
    }
}

export function* watchDownload() {
    yield takeLatest('DOWNLOAD', downloadCall);
}

export function* downloadStackedCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.downloadFile, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneDownloadStacked({resource: action.data.resource, data: result.data}));

        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        yield put(errorDownloadStacked({resource: action.data.resource, data: result.data}));
    }
}

export function* watchDownloadStacked() {
    yield takeEvery('DOWNLOAD_STACKED', downloadStackedCall);
}

export function* uploadDocumentCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result;
    if (action.data.files && action.data.files.length > 0) {
        result = yield call(Api.uploadDocuments, user, action.data.resource, action.data.files, action.data.params);
    }

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
            }
        }
        if (action.data.piggySecondResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggySecondResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
        yield put(doneUploadDocument(result.data));
    } else {
        yield put(errorUploadDocument(result.data));
    }
}

export function* watchUploadDocument() {
    yield takeLatest('UPLOAD_DOCUMENT', uploadDocumentCall);
}

export function* deleteDocumentCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.deleteResource, user, action.data.params, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.piggySecondResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggySecondResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUploadDocument(result.data));
            }
        } else {
            yield put(doneDeleteDocument(result.data));
        }
        if (action.data.piggySecondResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggySecondResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }

        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        yield put(errorDeleteDocument(result.data));
    }
}

export function* watchDeleteDocument() {
    yield takeLatest('DELETE_DOCUMENT', deleteDocumentCall);
}
