import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../components/layout";
import {Field, FieldsManager} from "../../data/services/fields.js";
import FieldText from "../../components/field-text";
import {resetPassword, resetPasswordConfirm, resetUserMessage} from "../../data/actions/user";
import LoaderSmall from "../../components/loader-small";
import {Link} from "react-router-dom";
import InfoBar from "../../components/info-paragraph/info-bar";
import {CheckCircleIcon, ChevronLeftIcon, EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import PasswordStrengthMeter from "../../components/passwordStrengthMeter";
import FieldPassword from "../../components/field-password";

const ResetPasswordConfirmView = (props) => {

    /** Constants
     ================================================================= */
    const {history, translate, match} = props

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user)

    const email = match.params && match.params.email
    const token = match.params && match.params.token

    /** Fields/Data definitions
     ================================================================= */
    const getFields = () => ({
        password: new Field('password', '', ['empty']),
        password_confirm: new Field('password_confirm', '', ['empty']),
    })


    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [passwordsMatch, setPasswordsMatch] = useState(true)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false)

    /** Lifecycle
     ================================================================= */

    /** Data events
     ================================================================= */
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit(event);
        }
    };

    const onSubmit = (event) => {
        event && event.preventDefault();

        const validatedFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            if (validatedFields.password.value === validatedFields.password_confirm.value) {
                setPasswordsMatch(true)
                dispatch(resetPasswordConfirm({
                    username: email,
                    token: token,
                    password: validatedFields.password.value
                }));
            } else {
                setPasswordsMatch(false)
            }
        } else {
            setFields(validatedFields);
        }
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
    }

    /** Render
     ================================================================= */
    return (
        <Layout user={user} history={history}>
            <section className="gradient-form h-[100vh] w-full bg-neutral-200 dark:bg-neutral-700">
                <div className="container h-full m-auto w-full p-10">
                    <div
                        className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200">
                        <div className="w-full xl:w-1/2">
                            <div
                                className="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
                                <div className="">
                                    <div className="px-4 md:px-0">
                                        <div className="md:mx-6 md:p-12">
                                            <div className="text-center mb-12">
                                                <img
                                                    className="mx-auto w-24"
                                                    src="/images/nursing_temp_logo.png"
                                                    alt="logo"/>
                                            </div>
                                            {!user.resetConfirm && (
                                                <>
                                                    <form onKeyPress={onKeyDown}>
                                                        <h2 className="text-2xl font-bold leading-9 tracking-tight text-center">
                                                            {translate('text.reset_password')}
                                                        </h2>

                                                        <div className="relative">
                                                            <label htmlFor="password"
                                                                   className="block text-sm font-medium leading-6">
                                                                Password
                                                            </label>

                                                            <div className="relative">
                                                                {!isPasswordVisible && (
                                                                    <FieldPassword
                                                                        onChange={handleInputChange} {...fields.password}
                                                                        onFocus={false}
                                                                        addClass="form-control"
                                                                    />
                                                                )}

                                                                {!!isPasswordVisible && (
                                                                    <FieldText
                                                                        className="form-control"
                                                                        onFocus={false}
                                                                        onChange={handleInputChange} {...fields.password}
                                                                        placeholder={''} addClass={'form-control'}
                                                                    />
                                                                )}


                                                                <div
                                                                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                                                    className="absolute opacity-75 cursor-pointer text-tm-gray-600 right-4 top-2"
                                                                >
                                                                    {
                                                                        !isPasswordVisible
                                                                            ? <EyeIcon className="w-5 h-5"/>
                                                                            : <EyeOffIcon className="w-5 h-5"/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="relative">
                                                            <label htmlFor="password_confirm"
                                                                   className="block text-sm font-medium leading-6">
                                                                Password
                                                            </label>

                                                            <div className="relative">
                                                                {!isPasswordConfirmVisible && (
                                                                    <FieldPassword
                                                                        onChange={handleInputChange} {...fields.password_confirm}
                                                                        onFocus={false}
                                                                        addClass="form-control"
                                                                    />
                                                                )}

                                                                {!!isPasswordConfirmVisible && (
                                                                    <FieldText
                                                                        className="form-control"
                                                                        onFocus={false}
                                                                        onChange={handleInputChange} {...fields.password_confirm}
                                                                        placeholder={''} addClass={'form-control'}
                                                                    />
                                                                )}


                                                                <div
                                                                    onClick={() => setIsPasswordConfirmVisible(!isPasswordConfirmVisible)}
                                                                    className="absolute opacity-75 cursor-pointer text-tm-gray-600 right-4 top-2"
                                                                >
                                                                    {
                                                                        !isPasswordConfirmVisible
                                                                            ? <EyeIcon className="w-5 h-5"/>
                                                                            : <EyeOffIcon className="w-5 h-5"/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="font-semibold my-5">
                                                            <PasswordStrengthMeter
                                                                password={fields.password.value}/>
                                                        </div>

                                                        {user.isLoading && (
                                                            <div className="flex justify-center">
                                                                <LoaderSmall/>
                                                            </div>
                                                        )}

                                                        <div className="flex justify-content-end items-center">
                                                            <button
                                                                className="btn btn-primary w-full justify-center disabled:bg-transparent disabled:border-primary"
                                                                disabled={user.isLoading}
                                                                onClick={onSubmit}
                                                            >
                                                                {translate('btn.reset_password')}
                                                            </button>
                                                        </div>
                                                    </form>

                                                    <div className="min-h-[2.5rem] mt-4">
                                                        {!passwordsMatch && (
                                                            <InfoBar
                                                                type="danger"
                                                                addClass="justify-content-center"
                                                            >
                                                                {translate('reset_password.no_match')}
                                                            </InfoBar>
                                                        )}
                                                    </div>

                                                    <div className="min-h-[2.5rem] mt-4">
                                                        {user.error && (
                                                            <InfoBar
                                                                type="danger"
                                                                addClass="justify-content-center"
                                                            >
                                                                {translate(user.errorMessage)}
                                                            </InfoBar>
                                                        )}
                                                    </div>

                                                    <div className="flex justify-content-end items-center">
                                                        <Link className="btn btn-text justify-center w-full font-semibold"
                                                              to={"/login"}
                                                        >
                                                            <ChevronLeftIcon className="w-5 h-5 -ml-1 mr-1"/>
                                                            {translate('btn.back_to_login')}
                                                        </Link>
                                                    </div>
                                                </>
                                            )}

                                            {!!user.resetConfirm && (
                                                <React.Fragment>
                                                    <div className="mx-auto w-full max-w-sm lg:w-96 text-center">
                                                        <div>
                                                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight">
                                                                {translate('reset_password.successfully')}
                                                            </h2>
                                                        </div>

                                                        <div className="flex justify-content-end items-center mt-20">
                                                            <Link className="btn btn-primary w-full justify-center"
                                                                  to={"/login"}
                                                            >
                                                                <ChevronLeftIcon className="w-5 h-5 -ml-1 mr-1"/>
                                                                {translate('btn.back_to_login')}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ResetPasswordConfirmView;
