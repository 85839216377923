import axios from "axios";
import Env from "../../util/env";
import {processError, processResponse} from "./api-util";
import FileSaver from "file-saver";

export default class Api {

    /** SSO calls
     ========================================================= */
    static login(username, password) {
        return axios.post(
            Env.getAuthApiUrl('token'),
            ('grant_type=password&username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password)),
            {
                headers: {
                    'Authorization': 'Basic ' + Env.getPublicToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
            .then((response) => {
                return {
                    'status': 0,
                    'data': response.data
                };
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static refresh(token) {
        return axios.post(
            Env.getAuthApiUrl('refresh'),
            'grant_type=refresh_token&refresh_token=' + token,
            {
                headers: {
                    'Authorization': 'Basic ' + Env.getPublicToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
            .then((response) => {
                return {
                    'status': 0,
                    'data': response.data
                };
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static register(params) {
        return axios.post(
            Env.getApiUrl('api_pub/register'),
            params,
            {}
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static resetPassword(username) {
        return axios.post(
            Env.getApiUrl('api_pub/reset-password'),
            {
                username: username
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static resetPasswordConfirm(username, token, password) {
        return axios.post(
            Env.getApiUrl('api_pub/reset-password-confirm'),
            {
                username: username,
                token: token,
                password: password
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static getAuthResource(user, query, resource) {
        return axios.get(
            Env.getAuthApiUrl('api/' + resource, query),
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    /** Resource calls
     ========================================================= */
    static getPublicResource(user, query, resource) {
        return axios.get(
            Env.getApiUrl('api_pub/' + resource, query),
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static getResource(user, query, resource) {
        return axios.get(
            Env.getApiUrl('api/' + resource, query),
            {
                headers: !!user ? {
                    'Authorization': 'Bearer ' + user.access_token
                } : {}
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static createResource(user, params, resource) {
        return axios.post(
            Env.getApiUrl('api/' + resource),
            params,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static updateResource(user, params, resource) {
        return axios.patch(
            Env.getApiUrl('api/' + resource),
            params,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static updatePublicResource(params, resource) {
        return axios.patch(
            Env.getApiUrl('api_pub/' + resource),
            params,
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static createResourceWithImage(user, params, resource, image) {
        const formData = new FormData();
        formData.append('Image', image);

        Object.keys(params).forEach((key) => {
            formData.append(key, params[key]);
        });

        return axios.post(
            Env.getApiUrl('api/' + resource),
            formData,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static deleteResource(user, query, resource) {
        return axios.delete(
            Env.getApiUrl('api/' + resource, query),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }


    static getLookups(user, query) {
        return axios.get(
            Env.getApiUrl('api/lookup', query),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static userInfo(user) {
        return axios.get(
            Env.getApiUrl('api/user'),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response)
            })
            .catch((error) => {
                return processError(error);
            });
    }
    static uploadDocument(user, resource, file, params) {
        const formData = new FormData();
        formData.append('file', file);
        if (file.params) {
            Object.keys(file.params).forEach(key => {
                formData.append(key, file.params[key]);
            })
        }
        if (params) {
            Object.keys(params).forEach((it) => {
                formData.append(it, params[it]);
            });
        }
        return axios.post(
            Env.getApiUrl('api/' + (resource ? resource : "documents")),
            formData,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static downloadFile(user, resource, query) {
        return axios({
            url: Env.getApiUrl('api/' + resource, query),
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + user.access_token
            }
        }).then((response) => {
            FileSaver.saveAs(new Blob([response.data]), query.name);

            return {
                'status': 0,
                'data': true
            };
        }).catch((error) => {
            return {
                'status': 1,
                'data': false
            };
        });
    }

    static uploadFile(user, resource, file, params = {}) {
        const formData = new FormData();
        formData.append('Document', file);
        Object.keys(params).forEach((it) => {
            formData.append(it, params[it]);
        })

        return axios.post(
            Env.getApiUrl('api/' + resource),
            formData,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static uploadFiles(user, resource, files, params = [], descriptions = {}) {
        const requests = files.map((file, i) => {
            const formData = new FormData();
            formData.append('Document', file);
            if (params && params[i]) {
                Object.keys(params[i]).forEach((it) => {
                    formData.append(it, params[i][it]);
                });
            }
            if (params.id) {
                formData.append('id', params.id);
            }

            if (descriptions && descriptions[i]) {
                formData.append("Description", descriptions[i]);
            }

            return axios.post(
                Env.getApiUrl('api/' + resource),
                formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            )
        });

        return axios.all(requests)
            .then(axios.spread((response) => {
                return processResponse(response);
            }))
            .catch((error) => {
                return processError(error);
            })
    }
}
