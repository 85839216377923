import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util.js";
import {
    doneGetResource
} from "../actions/resource";
import {pushNotification} from "../actions/ui";
import {
    doneCreateInfoResource, doneDeleteInfoResource,
    doneGetInfoResource, doneUpdateInfoResource,
    errorCreateInfoResource, errorDeleteInfoResource,
    errorGetInfoResource, errorUpdateInfoResource
} from "../actions/infoResource";
import {doneGetSecondResource} from "../actions/secondResource";

export function *getInfoResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetInfoResource(result.data));
    } else {
        yield put(errorGetInfoResource(result.data));
    }
}

export function* watchGetInfoResource() {
    yield takeLatest('GET_INFO_RESOURCE', getInfoResourceCall);
}

export function* watchGetInfoResourceSilent() {
    yield takeLatest('GET_INFO_RESOURCE_SILENT', getInfoResourceCall);
}

export function* createInfoResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetInfoResource(resPiggy.data));
                yield put(doneCreateInfoResource(result.data));
            }
        } else {
            yield put(doneCreateInfoResource(result.data));
        }
        
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorCreateInfoResource(result.data));
    }
}

export function* watchCreateInfoResource() {
    yield takeLatest('CREATE_INFO_RESOURCE', createInfoResourceCall);
}

export function *updateInfoResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetInfoResource(resPiggy.data));
                yield put(doneUpdateInfoResource(result.data));
            }
        } else {
            yield put(doneUpdateInfoResource(result.data));
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorUpdateInfoResource(result.data));
    }
}

export function* watchUpdateInfoResource() {
    yield takeLatest('UPDATE_INFO_RESOURCE', updateInfoResourceCall);
}

export function* deleteInfoResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.secondPiggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetInfoResource(resPiggy.data));
                yield put(doneDeleteInfoResource(result.data));
            }
        } else {
            yield put(doneDeleteInfoResource(result.data));
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorDeleteInfoResource(result.data));
    }
}

export function* watchDeleteInfoResource() {
    yield takeLatest('DELETE_INFO_RESOURCE', deleteInfoResourceCall);
}
