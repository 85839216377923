import React, {useEffect, useState} from "react";
import LayoutDashboard from "../../components/layout-dashboard";
import Page from "../../components/layout-dashboard/page";
import PageHeader from "../../components/layout-dashboard/page/page-header.js";
import Resources from "../../data/services/resources";
import {Field, FieldsManager} from "../../data/services/fields";
import {fieldsToHtml} from "../../util/util-fields";
import Card from "../../components/card";
import ModalDefault from "../../components/modal/modal-default";
import {getProp} from "../../util/util";
import TableCard from "../../components/simple-table/table-card";
import ResourceTable from "../../components/resource-table";
import PageFooter from "../../components/layout-dashboard/page/page-footer";
import {useDispatch, useSelector} from "react-redux";
import {getDataSelect} from "../../data/selectors/resourceSelectors";
import LoaderSmall from "../../components/loader-small";
import TableFooter from "../../components/resource-table/table-footer";
import PaginationNew from "../../components/pagination-new";
import {Chart} from "react-google-charts";
import {toFrontDate} from "../../common/util/util-dates";
import NoRecordsTable from "../../components/no-records-found/no-records-table";
import ReportLayoutLegend from "../../components/report-layout-legend";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";

const AdminReportsView = (props) => {
    /** Store
     ================================================================= */
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)

    /** Constants
     ================================================================= */
    const {translate, match} = props

    const dispatch = useDispatch();
    const getData = getDataSelect({ dispatch: dispatch, user: user.data })

    const data = getProp(resource, 'data.data', {})
    const dataItems = getProp(resource, 'data.dataItems', {})
    const modalCount = getProp(resource, 'data.reportsCount', 0)
    const isLoading = getProp(resource, 'isLoading', false)

    const metadata = {
        DepartmentID: {
            api: 'api/' + Resources.Departments,
            query: {
                offset: 0,
                limit: 30,
                IsEnabled: 1,
                archived: 0
            },
            searchMap: (it) => ({
                label: it.DepartmentName,
                value: it.DepartmentID,
            })
        }
    }

    /** Helpers
     ================================================================= */
    const getAverage = (key, data) => {
        const sum = Object.values(data).reduce((memo, it) => {
            return memo + it[key]
        }, 0)

        return Object.keys(data).length > 0 ? (sum / Object.keys(data).length).toFixed(2) : 0;
    }

    /** Fields/Data definitions
     ================================================================= */
    const getDecimalColumn = (value, indented) => {
        return value && value > 0 ? <div className={!indented ? 'font-bold' : ''}>{value.toFixed(2)}</div> : '';
    }

    const getFields = () => ({
        PickBaseReport: new Field('PickBaseReport', '', ['empty'], false, 'select', {
            addContainerClass: "mt-4"
        }, {
            values: {
                "Summary Report": "Summary Report",
                "Detailed Report": "Detailed Report",
                "Graphic Report": "Graphic Report"
            },
            omitSort: true
        }),
        DateFrom: new Field('DateFrom', '', [], false, 'date', {}, {isClearable: true}),
        DepartmentID: new Field('DepartmentID', '', [], false, 'select-search', { labelField: "DepartmentName" }, {isClearable: true}),
        DateTo: new Field('DateTo', '', [], false, 'date', {
            valueToBack: it => it ? it.replace("00:00:00", "23:59:59") : null
        }, {isClearable: true}),
    })

    const getModalFields = () => ({
        ServiceAreaName: new Field('ServiceAreaName', '', [], false, 'custom', {
            render: (item) => item.IsIndented ?
                (
                    <div className={'font-normal ml-12'}>{item.ServiceArea}</div>
                ) :
                (
                    <div className={'cursor-pointer'} onClick={() => handleToggleCollapseRow(item)}>
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-1.5 py-0.5 text-blue-700">
                            {!collapsedTableRows[item.ServiceAreaID] ?
                                <ChevronDownIcon className="h-5 w-5 text-blue-700"/> :
                                <ChevronUpIcon className="h-5 w-5 text-blue-700"/>
                            }
                            {item.ServiceAreaName}
                        </span>
                    </div>
                )
        }),
        Accessibility: new Field('Accessibility', '', [], false, 'text', { render: (item) => getDecimalColumn(item.Accessibility, item.IsIndented) }),
        Timeliness: new Field('Timeliness', '', [], false, 'text', { render: (item) => getDecimalColumn(item.Timeliness, item.IsIndented) }),
        Accuracy: new Field('Accuracy', '', [], false, 'text', { render: (item) => getDecimalColumn(item.Accuracy, item.IsIndented) }),
        Attitude: new Field('Attitude', '', [], false, 'text', { render: (item) => getDecimalColumn(item.Attitude, item.IsIndented) }),
        Operations: new Field('Operations', '', [], false, 'text', { render: (item) => getDecimalColumn(item.Operations, item.IsIndented) }),
        ServiceAverage: new Field('ServiceAverage', '', [], false, 'text', { render: (item) => getDecimalColumn(item.ServiceAverage, item.IsIndented) }),
    })

    const getModalNotesFields = () => ({
        ServiceAreaName: new Field('ServiceAreaName', '', [], false, 'text'),
        ServiceAreaShift: new Field('ServiceAreaShift', '', [], false, 'text'),
        Rating: new Field('Rating', '', [], false, 'text'),
        Department: new Field('Department', '', [], false, 'text'),
        Diagnostic: new Field('Diagnostic', '', [], false, 'text'),
        Comment: new Field('Comment', '', [], false, 'text'),
    })

    const getModalData = () => {
        let arr = []

        Object.keys(data).forEach(it => {
            arr.push({
                ...data[it],
                ServiceAreaID: it
            })

            if (!collapsedTableRows[it]) {
                // Day
                arr.push({
                    ...data[it]['Day'],
                    ServiceArea: 'Day',
                    IsIndented: true
                })
                // Evening
                arr.push({
                    ...data[it]['Evening'],
                    ServiceArea: 'Evening',
                    IsIndented: true
                })
                // Night
                arr.push({
                    ...data[it]['Night'],
                    ServiceArea: 'Night',
                    IsIndented: true
                })
            }
        })

        return arr
    }
    /** State
     ================================================================= */
    const [localQuery, setLocalQuery] = useState({
        offset: 0,
        paginationPage: 1,
        limit: 10
    })
    const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false)
    const [fields, setFields] = useState(getFields())
    const [canSubmit, setCanSubmit] = useState(false)
    const [collapsedTableRows, setCollapsedTableRows] = useState({})
    const [modalData, setModalData] = useState(getModalData())

    /** UI events
     ================================================================= */
    const handleToggleCollapseRow = (item) => {
        setCollapsedTableRows((prevState => ({
            ...prevState,
            [item.ServiceAreaID]: !collapsedTableRows[item.ServiceAreaID]
        })))
    }

    const handleCollapseAllRows = () => {
        setCollapsedTableRows((prevState => ({
            ...prevState,
            ...Object.keys(data).reduce((memo, it) => {
                memo[it] = true
                return memo
            }, {})
        })))
    }

    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
        setCanSubmit(true)
    }

    const handleToggleDetailsModal = () => {
        if (!viewDetailsModalOpen) {
            const validatedFields = FieldsManager.validateFields(fields)
            if (FieldsManager.checkFieldsForErrors(validatedFields)) {
                fetchData()
                setFields(validatedFields)
                setViewDetailsModalOpen(true)
                setLocalQuery({
                    offset: 0,
                    paginationPage: 1,
                    limit: 10
                })
            } else {
                setFields(validatedFields)
            }
        } else {
            setViewDetailsModalOpen(false)
        }
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        getData({
            query: {
                ...FieldsManager.getFieldKeyValues(fields)
                },
            resource: fields.PickBaseReport.value == "Graphic Report" ? Resources.ReportsGraph : Resources.Reports
        })
    }

    /** Lifecycles
     ================================================================= */
    useEffect(() => {
        if (!isLoading && Object.keys(data).length) {
            setModalData(getModalData())
        }
    }, [isLoading])

    useEffect(() => {
        setModalData(getModalData())
    }, [collapsedTableRows])

    useEffect(() => {
        setCollapsedTableRows({})
    }, [viewDetailsModalOpen])

    /** Render
     ================================================================= */
    const fieldsHtml = fieldsToHtml(Object.values(fields), translate, handleInputChange,metadata);

    const modalDataItems = Object.keys(dataItems).map(it => {
        return {
            ...dataItems[it],
            ServiceAreaID: it
        }
    })

    const modalGraphHeader = [
        "Day",
        "Accessibility",
        "Timeliness",
        "Accuracy",
        "Attitude",
        "Operations",
    ]

    const modalGraphData = Object.keys(data).map(it => {
        return [toFrontDate(it), data[it].Accessibility, data[it].Timeliness, data[it].Accuracy, data[it].Attitude, data[it].Operations]
    })

    return (
        <LayoutDashboard
            {...props}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.Reports')}
                />

                <Card addBodyClass={'grid gap-5 grid-cols-2'}>
                    {fieldsHtml}
                </Card>

            </Page>

            <ModalDefault
                show={viewDetailsModalOpen}
                widthClass={'max-w-full'}
                title={`${getProp(fields, 'PickBaseReport.value', '')} - Date Range`}

                close={handleToggleDetailsModal}
                closeButtonLabel={translate('btn.cancel')}
                onClose={handleToggleDetailsModal}
            >
                <div className="p-5">
                    {getProp(fields, 'PickBaseReport.value', '') === "Summary Report" && (
                        <div>
                            <PageHeader
                                title={`Department List`}
                                afterTitle={isLoading ? (
                                    <div className={'flex justify-center h-5'}>
                                        <LoaderSmall
                                            svgClass="text-gray-200 animate-spin dark:text-gray-600 fill-primary w-5 h-5 ml-3"/>
                                    </div>
                                ) : (
                                    <div className="ml-3">
                                        (reports included - {getProp(resource, 'data.reportsCount', 0)})
                                    </div>
                                )}
                            >
                                <div className={'flex'}>
                                    <div className={'cursor-pointer'} onClick={() => setCollapsedTableRows({})}>
                                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-1.5 py-0.5 text-blue-700">
                                            <ChevronDownIcon className="h-5 w-5 text-blue-700"/>
                                            Expand All Rows
                                        </span>
                                    </div>
                                    <div className={'cursor-pointer ml-2'} onClick={handleCollapseAllRows}>
                                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-1.5 py-0.5 text-blue-700">
                                            <ChevronUpIcon className="h-5 w-5 text-blue-700"/>
                                            Collapse All Rows
                                        </span>
                                    </div>
                                </div>
                            </PageHeader>

                            <TableCard addClass={'relative z-0 mt-3'}>
                                <ResourceTable
                                    data={modalData}
                                    fields={getModalFields()}
                                    maxHeightClass={"max-h-[calc(100vh-30rem)]"}

                                    translate={translate}
                                    isLoading={isLoading}

                                    limit={modalData.length}
                                    performanceModeLimit={1000}

                                    tfoot={[{
                                        ServiceAreaName:
                                            <div className={'font-bold text-gray-700 text-right'}>
                                                Average
                                            </div>,
                                        Accessibility:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Accessibility", data)}
                                            </div>,
                                        Timeliness:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Timeliness", data)}
                                            </div>,
                                        Accuracy:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Accuracy", data)}
                                            </div>,
                                        Attitude:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Attitude", data)}
                                            </div>,
                                        Operations:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Operations", data)}
                                            </div>,
                                        ServiceAverage:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("ServiceAverage", data)}
                                            </div>,
                                    }]}
                                />

                                {modalData.length && (
                                    <div className={'my-5'}>
                                        <ReportLayoutLegend/>
                                    </div>
                                )}
                            </TableCard>
                        </div>
                    )}
                    {getProp(fields, 'PickBaseReport.value', '') === "Detailed Report" && (
                        <div>
                            <PageHeader
                                title={'Department List'}
                                afterTitle={isLoading ? (
                                    <div className={'flex justify-center h-5'}>
                                        <LoaderSmall
                                            svgClass="text-gray-200 animate-spin dark:text-gray-600 fill-primary w-5 h-5 ml-3"/>
                                    </div>
                                ) : (
                                    <div className="ml-3">
                                        (reports included - {getProp(resource, 'data.reportsCount', 0)})
                                    </div>
                                )}
                            >
                                <div className={'flex'}>
                                    <div className={'cursor-pointer'} onClick={() => setCollapsedTableRows({})}>
                                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-1.5 py-0.5 text-blue-700">
                                            <ChevronDownIcon className="h-5 w-5 text-blue-700"/>
                                            Expand All Rows
                                        </span>
                                    </div>
                                    <div className={'cursor-pointer ml-2'} onClick={handleCollapseAllRows}>
                                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-1.5 py-0.5 text-blue-700">
                                            <ChevronUpIcon className="h-5 w-5 text-blue-700"/>
                                            Collapse All Rows
                                        </span>
                                    </div>
                                </div>
                            </PageHeader>

                            <TableCard addClass={'relative z-0 my-3'}>
                                <ResourceTable
                                    data={modalData}
                                    fields={getModalFields()}
                                    maxHeightClass={"max-h-[calc(100vh-30rem)]"}

                                    translate={translate}
                                    isLoading={isLoading}

                                    limit={modalData.length}
                                    performanceModeLimit={1000}

                                    tfoot={[{
                                        ServiceAreaName:
                                            <div className={'font-bold text-gray-700 text-right'}>
                                                Average
                                            </div>,
                                        Accessibility:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Accessibility", data)}
                                            </div>,
                                        Timeliness:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Timeliness", data)}
                                            </div>,
                                        Accuracy:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Accuracy", data)}
                                            </div>,
                                        Attitude:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Attitude", data)}
                                            </div>,
                                        Operations:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Operations", data)}
                                            </div>,
                                        ServiceAverage:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("ServiceAverage", data)}
                                            </div>,
                                    }]}
                                />

                                {modalData.length && (
                                    <div className={'my-5'}>
                                        <ReportLayoutLegend/>
                                    </div>
                                )}
                            </TableCard>

                            <PageHeader
                                title={'Notes'}
                            />

                            <TableCard addClass={'relative z-0 mt-3'}>
                                <ResourceTable
                                    data={modalDataItems.slice(localQuery.offset, localQuery.offset + localQuery.limit)}
                                    fields={getModalNotesFields()}

                                    translate={translate}
                                    isLoading={isLoading}

                                    limit={10}

                                    tfoot={[{
                                        ServiceAreaShift:
                                            <div className={'font-bold text-gray-700 text-right'}>
                                                Average
                                            </div>,
                                        Rating:
                                            <div className={'font-bold text-gray-700 '}>
                                                {getAverage("Rating", dataItems)}
                                            </div>,
                                    }]}
                                />

                                {/*Table footer*/}
                                <TableFooter
                                    show={!isLoading}
                                >
                                    <PaginationNew
                                        count={modalDataItems.length}
                                        isLoading={isLoading}
                                        handleQueryChange={(name, value, currentPage) => {
                                            setLocalQuery((prevState => ({
                                                ...prevState,
                                                offset: value
                                            })))
                                        }}
                                        pageOffset={localQuery.offset}
                                        pageLimit={localQuery.limit}
                                        translate={translate}
                                    />
                                </TableFooter>
                            </TableCard>
                        </div>
                    )}
                    {getProp(fields, 'PickBaseReport.value', '') === "Graphic Report" && (
                        <div className={'flex justify-center flex-col items-center'} key={window.innerWidth}>
                            {isLoading ? (
                                <div className={'flex justify-center h-5'}>
                                    <LoaderSmall
                                        svgClass="text-gray-200 animate-spin dark:text-gray-600 fill-primary w-5 h-5 ml-3"/>
                                </div>
                            ) : (
                                <>
                                    {!!Object.keys(data).length ? (
                                        <>
                                            <Chart
                                                chartType="LineChart"
                                                width="100%"
                                                height="500px"
                                                data={[
                                                    modalGraphHeader,
                                                    ...modalGraphData
                                                ]}
                                                options={{
                                                    chart: {
                                                        title: " ",
                                                    },
                                                    height: 500,
                                                    pointSize: 5,
                                                    series: {
                                                        // Gives each series an axis name that matches the Y-axis below.
                                                        0: { axis: "Temps", pointShape: 'circle' },
                                                        1: { axis: "Daylight", pointShape: 'circle' },
                                                    },
                                                    axes: {
                                                        // Adds labels to each axis; they don't have to match the axis names.
                                                        y: {
                                                            Temps: { label: " " },
                                                            Daylight: { label: " " },
                                                        },
                                                    },
                                                }}
                                            />

                                            <div className={'my-5'}>
                                                <ReportLayoutLegend/>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <NoRecordsTable
                                                show={true}
                                                title={translate('text.no_matching_records')}
                                            />
                                        </div>
                                    )}
                                </>
                            )}

                        </div>
                    )}
                </div>
            </ModalDefault>

            <PageFooter
                actionSubmit={handleToggleDetailsModal}
                actionSubmitLabel={'View Report'}
                canSubmit={canSubmit}
                translate={translate}
                actionCancel={() => {
                    setFields(getFields())
                    setCanSubmit(false)
                }}
            />
        </LayoutDashboard>
    )
}

export default AdminReportsView
