import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import {
    checkPerm,
    getUserClientType,
    getUserClientTypeName,
    getUserClientTypeRelation,
    getUserFullName
} from "../../util/util";
import Resources from "../../data/services/resources";
import {READ_PERM, USER_CLIENT_TYPE_NURSE} from "../../util/util-constants";

const navigation = [
    { name: 'Dashboard', href: '/home', visible: checkPerm(Resources.NurseDashboardReports, READ_PERM) },
    { name: 'Reports', href: '/reports', visible: checkPerm(Resources.AdminDashboardReports, READ_PERM)  },
    { name: 'Admin', href: '/admin', visible: checkPerm(Resources.AdminDashboardReports, READ_PERM)  },
].filter(it => it.visible)

const DemoMenu = (props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const currentPath = props.match.path;

    const ClientTypeID = getUserClientType()

    return (
        <header className="bg-inverse border-b ">
            <nav className="mx-auto flex max-w-full items-center justify-between px-6 py-2 lg:px-12" aria-label="Global">
                <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Nursing</span>
                    <img className="h-14 w-auto" src="/images/nursing_temp_logo.png" alt="" />
                </a>
                <div>
                    {getUserFullName()} ({getUserClientTypeName()}{getUserClientTypeRelation() ? ' - ' + getUserClientTypeRelation() : ''})

                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex">
                    {navigation.map((item, index) => (
                        <a key={item.name} href={item.href} className={
                            "text-sm font-semibold leading-6 text-gray-900 px-5 " +
                            (index != 0 ? 'border-l ' : '')
                        }>
                            <span className={item.href == currentPath ? 'border-b border-primary pb-2' : ''}>{item.name}</span>
                        </a>
                    ))}
                    <a href="#" onClick={props.onLogout} className="text-sm font-semibold leading-6 text-gray-900 border-l pl-5">
                        Log Out
                    </a>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                className="h-12 w-auto"
                                src="/images/nursing_temp_logo.png"
                                alt=""
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-6">
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Log Out
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}

export default DemoMenu
