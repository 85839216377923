import React, {Component} from 'react';
import Select, {components, createFilter} from 'react-select';
import {getCustomStyles} from "../../data/themes/index";
import {scrollIntoView} from "../../common/util/util-vanilla";
import ErrorFieldMessage from "../error-field-message";

const CustomOption = props => {
    return <components.Option {...props} />;
};

export default class FieldSelectSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const pairs = this.props.values ? this.props.values : {};

        let values = Object.keys(pairs);

        if (!this.props.omitSort) {
            values = values.sort((a, b) => {
                return (pairs[a] == pairs[b]) ? 0 : ((pairs[a] > pairs[b]) ? 1 : -1);
            });
        }

        if (!!this.props.valueSort) {
            values = Object.keys(pairs).sort((a, b) => {
                return pairs[a] - pairs[b]
            });
        }

        if (!!this.props.reverseValueSort || this.props.name === "Year") {
            values = Object.keys(pairs).sort((a, b) => {
                return pairs[b] - pairs[a]
            });
        }

        if (!!this.props.keySort) {
            values = values.sort((a, b) => a - b);
        }

        if (!!this.props.reverseKeySort) {
            values = values.sort((a, b) => b - a);
        }

        values = values.map((key, i) => ({value: key, label: pairs[key]}));
        const filterOnInputChange = (newValue) => {
            const newValueToLowerCase = newValue.toLowerCase();
            values.sort(function (a, b) {
                return a.label.toLowerCase().indexOf(newValueToLowerCase) < b.label.toLowerCase().indexOf(newValueToLowerCase) ? -1 : 1;
            });
        }

        const filterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'any',
        };

        return (
            <React.Fragment>
                <Select
                    ref={this.props.innerRef}
                    placeholder={this.props.placeholder ? this.props.placeholder : "Select..."}
                    isMulti={this.props.isMulti}
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption ? (!this.props.options ? selectedOption.value : selectedOption) : "");
                    }}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                    className={"select-css-search p-0 " + (this.props.addClass) + (" block w-full rounded-full border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ")}
                    styles={getCustomStyles()}
                    value={!this.props.options ? (this.props.value ? {
                        value: this.props.value,
                        label: pairs[this.props.value]
                    } : null) : this.props.value}
                    isClearable={!!this.props.isClearable}
                    isSearchable={true}
                    isDisabled={this.props.disabled}
                    name={this.props.name}
                    options={this.props.options ? this.props.options : values}
                    filterOption={createFilter(filterConfig)}
                    components={{Option: CustomOption}}
                    autoFocus={this.props.autoFocus}
                    onInputChange={(newValue) => {
                        if (this.props.onInputChangeProp) {
                            filterOnInputChange(newValue)
                        }
                    }}
                    menuPlacement={this.props.menuPlacement}
                    menuPortalTarget={this.props.hasPortal ? document.body : null}
                />
                <ErrorFieldMessage
                    errorMessage={this.props.errorMessage}
                    translate={this.props.translate}
                />
            </React.Fragment>
        );
    }
}
