import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDefaultTableOptions, getProp} from "../../util/util";
import {
    createDataSelect,
    deleteDataSelect,
    getDataSelect,
    updateDataSelect
} from "../../data/selectors/resourceSelectors";
import {Field, FieldsManager} from "../../data/services/fields.js";
import LocalStorage from "../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData} from "../../util/util-fields";
import {
    DASHBOARD_REPORT_STATUS_DUE,
    DEFAULT_CRUD_STATE,
    REPORT_LAYOUT_LEGEND_DATA,
    USER_DATETIME_FORMAT_VALUES,
    USER_TIMEZONE_VALUES
} from "../../util/util-constants";
import Resources from "../../data/services/resources";
import ActiveFilters from "../../components/active-filters";
import ModalConfirm from "../../components/modal-confirm";
import ModalSaveResource from "../../components/modal-save-resource";
import NoRecordsTable from "../../components/no-records-found/no-records-table";
import PaginationNew from "../../components/pagination-new";
import TableFooter from "../../components/resource-table/table-footer";
import ResourceTable from "../../components/resource-table";
import TableFilters from "../../components/simple-table/table-filters";
import TableCard from "../../components/simple-table/table-card";
import PageHeader from "../../components/layout-dashboard/page/page-header";
import Page from "../../components/layout-dashboard/page";
import {DownloadIcon, EyeIcon, PencilIcon, ViewListIcon} from "@heroicons/react/outline";
import ModalDefault from "../../components/modal/modal-default";
import {getSecondDataSelect, updateSecondDataSelect} from "../../data/selectors/secondResourceSelectors";
import LoaderSmall from "../../components/loader-small";
import DisplayDataGrid from "../../components/display-data/display-data-grid";
import LayoutDashboard from "../../components/layout-dashboard";
import DialogDefaultFooter from "../../components/modal/modal-footer";
import ReportLayoutLegend from "../../components/report-layout-legend";
import Badge from "../../components/badge";
import {cloneDeep, groupBy} from "../../common/util/util-vanilla";

const NurseDashboardView = (props) => {
    const {
        translate
    } = props
    
    /** Store
     ================================================================= */
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)
    const secondResource = useSelector((state) => state.secondResource)

    /** Constants
     ================================================================= */
    const pagePath = Resources.NurseDashboardReports + "_tab"
    const dispatch = useDispatch();

    const getData = getDataSelect({ dispatch: dispatch, user: user.data })

    const getSecondData = getSecondDataSelect({ dispatch: dispatch, user: user.data })
    const updateSecondData = updateSecondDataSelect({ dispatch: dispatch, user: user.data })

    const data = getProp(resource, 'data.list', [])
    const count = getProp(resource, 'data.count', 0)
    const isLoading = getProp(resource, 'isLoading', false)

    const secondData = getProp(secondResource, 'data.data.items', [])
    const secondIsLoading = getProp(secondResource, 'isLoading', false)

    const groupedReportItemsData = groupBy(secondData, 'ServiceAreaID')

    /** Fields/Data definitions
     ================================================================= */
    const getFields = (item = null) => {
        const fieldTemplates = {
            Description: new Field('Description', '', [], false, 'textarea', {}, {}),
            DueDate: new Field('DueDate', '', [], false, 'date', {}, {}),
            CompleteDate: new Field('CompleteDate', '', [], false, 'datetimezone', {}, {}),
            DashboardReportStatusID: new Field('DashboardReportStatusID', '', [], false, 'select-search', { labelField: "DashboardReportStatus" }, {}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    const getReportModalFields = (data = null) => {
        const fieldTemplate = {
            ServiceAreaName: new Field('ServiceAreaName', '', [], false, 'custom', {
                hideLabel: true,
                addContainerClass: getFieldWidthClass('ServiceAreaName'),
                render: (item) => (
                    <div>
                        {item.ServiceAreaName} {item.Contact && (<span className={'font-normal'}> - {item.Contact}</span>)}
                        {item.Notes && (<div className={'leading-[1rem] font-normal text-xs ml-1 mt-1'}>{item.Notes}</div>)}
                    </div>
                )
            }),
            ServiceAreaShift: new Field('ServiceAreaShift', '', [], false, 'readonly', { hideLabel: true, addContainerClass: getFieldWidthClass('ServiceAreaShift') }),
            Accessibility: new Field('Accessibility', '', ['integer'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Accessibility') }, {numberMax: 5, numberMin: 1}),
            Timeliness: new Field('Timeliness', '', ['integer'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Timeliness') }, {numberMax: 5, numberMin: 1}),
            Accuracy: new Field('Accuracy', '', ['integer'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Accuracy') }, {numberMax: 5, numberMin: 1}),
            Attitude: new Field('Attitude', '', ['integer'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Attitude') }, {numberMax: 5, numberMin: 1}),
            Operations: new Field('Operations', '', ['integer'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Operations') }, {numberMax: 5, numberMin: 1}),
            EntryNotes: new Field('EntryNotes', '', [''], false, 'textarea', { hideLabel: true, addContainerClass: getFieldWidthClass('EntryNotes') }, {rows: 2, addClass: 'min-h-[3rem] w-full' }),
            DashboardReportEntryID: new Field('DashboardReportEntryID', '', [''], false, 'hidden', {}, {}),
            Notes: new Field('Notes', '', [''], false, 'hidden', {}, {}),
            Contact: new Field('Contact', '', [''], false, 'hidden', {}, {}),
        }

        return fillFieldsFromData(fieldTemplate, data)
    }

    const getTableFields = () => {
        if (secondData) {
            return secondData.map(it => getReportModalFields(it))
        }

        return []
    }

    const getGroupedTableFields = () => {
        if (secondData) {
            const groupedReportItemsData = groupBy(secondData, 'ServiceAreaID')
            return Object.keys(groupedReportItemsData).reduce((memo, it) => {
                memo[it] = groupedReportItemsData[it].map(elem => getReportModalFields(elem))
                return memo
            }, {})
        }

        return []
    }

    const getQueryFilterFields = () => ({
        search: new Field('search', '', [''], false, 'search', {
            containerClass: 'col-md-3',
        }, {}),
        archived: new Field('archived', '', [''], false, 'checkbox', {}),
        limit: new Field('limit', 20, [''], false, 'select', {})
    })

    /** State
     ================================================================= */
        // Fields/Query
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), {}, pagePath, translate))
    const [queryFilterFields, setQueryFilterFields] = useState(LocalStorage.has(pagePath + '_state') ? JSON.parse(LocalStorage.get(pagePath + '_state'))?.queryFilterFields : getQueryFilterFields())
    const [query, setQuery] = useState({
        ...DEFAULT_CRUD_STATE,
    })

    // Modals
    const [reportsModalOpen, setReportsModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [tableFields, setTableFields] = useState(getTableFields())
    const [groupedTableFields, setGroupedTableFields] = useState(getGroupedTableFields())
    const [canSubmit, setCanSubmit] = useState(false)
    const [legendValuesToggle, setLegendValuesToggle] = useState(false)

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return Resources.NurseDashboardReports
    }

    const getQuery = () => {
        return {
            ...query,
            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        getData({query: getQuery(), resource: getResourceName()})
    }

    const fetchSecondData = () => {
        getSecondData({query: { DashboardReportID: getProp(selectedItem, 'DashboardReportID', 0) }, resource: Resources.NurseDashboardReportsSingle})
    }

    /** UI events
     ================================================================= */
    const handleModalInputChange = (name, value, index) => {
        let fieldsTmp = [...tableFields]

        fieldsTmp[index] = FieldsManager.updateField(fieldsTmp[index], name, value)

        setTableFields(fieldsTmp)
        setCanSubmit(true)
    }

    const handleModalGroupedInputChange = (name, value, key, i) => {
        let fieldsTmp = cloneDeep(groupedTableFields)

        fieldsTmp[key][i] = FieldsManager.updateField(fieldsTmp[key][i], name, value)

        setGroupedTableFields(fieldsTmp)
        setCanSubmit(true)
    }

    const handleToggleReportsModal = (item = null) => {
        setSelectedItem(item)
        setReportsModalOpen(!reportsModalOpen)
    }

    useEffect(() => {
        if (reportsModalOpen && selectedItem) {
            fetchSecondData()
        }
    }, [reportsModalOpen])

    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        handleResetPagination()
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFieldsTmp = Object.assign({}, queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFieldsTmp).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFieldsTmp, it.name, '')
        })

        setQueryFilterFields(queryFilterFieldsTmp)
        handleResetPagination()
    }

    const handleResetPagination = () => {
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [query])

    useEffect(() => {
        if (!secondIsLoading && secondData) {
            setTableFields(getTableFields())
            setGroupedTableFields(getGroupedTableFields())
        }
    }, [secondIsLoading])

    /** Render
     ================================================================= */

    return (
        <LayoutDashboard
            {...props}
        >
            <Page>
                <PageHeader
                    title={translate("page.heading." + getResourceName())}
                    titleClass="mr-5 text-2xl capitalize"
                />

                <div className="flex">
                    <ActiveFilters
                        filterFields={queryFilterFields}
                        onLabelClick={handleFilterInputChange}
                        onClearFiltersClick={handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="ml-auto flex items-center relative">

                    </div>
                </div>

                <TableCard addClass={'relative z-0'}>
                    <TableFilters
                        filterFields={queryFilterFields}
                        maxHeaderFilters={6}
                        handleInputChange={handleFilterInputChange}
                        translate={translate}
                    />

                    <ResourceTable
                        data={data}
                        fields={getFields()}

                        translate={translate}
                        isLoading={isLoading}

                        options={tableOptions}

                        limit={queryFilterFields?.limit?.value ?? 10}

                        sort={query.sort}
                        sortBy={query.sortBy}
                        onSortChange={handleUpdateSort}

                        onRowClick={handleToggleReportsModal}

                        actions={[
                            {
                                action: handleToggleReportsModal,
                                icon: EyeIcon, // make this a function
                                visible: (item) => item.DashboardReportStatusID != DASHBOARD_REPORT_STATUS_DUE,
                                label: false, // make this a function
                                title: translate('btn.view'),
                                disabled: false,
                                class: false,
                                iconClass: false
                            },
                            {
                                action: handleToggleReportsModal,
                                icon: PencilIcon, // make this a function
                                visible: (item) => item.DashboardReportStatusID == DASHBOARD_REPORT_STATUS_DUE,
                                label: false, // make this a function
                                title: translate('btn.edit'),
                                disabled: false,
                                class: false,
                                iconClass: false
                            },
                        ]}
                    />

                    {/*Table footer*/}
                    <TableFooter
                        show={!!data.length && !isLoading}
                    >
                        <PaginationNew
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? handleUpdateOffset(value, currentPage)
                                    : handleFilterInputChange(name, value)
                            }
                            pageOffset={query.offset}
                            queryFields={queryFilterFields}
                            translate={translate}
                            pageLimit={queryFilterFields?.limit?.value}
                        />
                    </TableFooter>

                    <NoRecordsTable
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_matching_records')}
                        clearFilterText={translate('text.try_without_filters')}
                        onClearFilterClick={handleClearFiltersClick}
                        filters={queryFilterFields}
                    />
                </TableCard>

                <ModalDefault
                    show={!!reportsModalOpen}
                    title={"Dashboard Reports"}
                    widthClass={'w-full'}
                    translate={translate}
                    onClose={handleToggleReportsModal}
                    closeButtonLabel={translate("btn.close")}

                    buttonLabel={parseInt(getProp(secondResource, 'data.data.DashboardReportStatusID', 0)) === 1 && translate("btn.save")}
                    buttonDisabled={!canSubmit}
                    onButtonClick={() => {
                        const validatedGroupFields = Object.values(groupedTableFields)
                            .map((fieldsArray) => fieldsArray.map(fields => FieldsManager.validateFields(fields)))

                        if (validatedGroupFields.flat().reduce((memo, fields) => (memo && FieldsManager.checkFieldsForErrors(fields)), true)) {
                            updateSecondData({
                                resource: Resources.NurseDashboardReports,
                                params: {
                                    DashboardReportID: getProp(selectedItem, 'DashboardReportID', 0),
                                    Entries: validatedGroupFields.flat().map(it => FieldsManager.getFieldKeyValues(it))
                                },
                                secondPiggyResource: Resources.NurseDashboardReportsSingle,
                                piggyResource: getResourceName(),
                                query: {
                                    DashboardReportID: getProp(selectedItem, 'DashboardReportID', 0),
                                    ...getQuery()
                                },
                                notificationMessage: 'Report updated Successfully!'
                            })
                        } else {
                            setGroupedTableFields(validatedGroupFields)
                        }
                    }}
                    options={!secondIsLoading && parseInt(getProp(secondResource, 'data.data.DashboardReportStatusID', 0)) > 1 && (
                        <div onClick={() => setLegendValuesToggle(!legendValuesToggle)}>
                            <Badge
                                type={'outline'}
                                addClass={'cursor-pointer flex items-center justify-center text-primary'}
                            >
                                <EyeIcon className={'w-4 h-4 mr-2 text-primary'}/> Toggle Legend Values
                            </Badge>
                        </div>
                    )}
                    customButtonsHTML={!secondIsLoading && (
                        <div className={'m-auto'}>
                            <ReportLayoutLegend/>
                        </div>
                    )}
                    addCustomButtonsClass={'m-auto'}
                >
                    <div className="p-5 relative">
                        {!!secondIsLoading && (
                            <div className={'flex justify-center h-12'}>
                                <LoaderSmall
                                    svgClass="text-gray-200 animate-spin dark:text-gray-600 fill-primary w-12 h-12"/>
                            </div>
                        )}
                        {!secondIsLoading && (
                            <div className="grid grid-cols-1 border-2 max-h-[79vh] overflow-y-scroll">
                                {parseInt(getProp(secondResource, 'data.data.DashboardReportStatusID', 0)) > 1 && (
                                    <div className="grid grid-cols-1 border-1 relative">
                                        <div className="grid grid-cols-12 divide-x sticky top-0 left-0 z-10">
                                            {Object.keys(getReportModalFields()).filter(it => getReportModalFields()[it].type != 'hidden').map(it => {
                                                return (
                                                    <div className={`text-sm top-0 sticky border-b border-tm-gray-300 bg-tm-gray-50 flex-initial border-b border-tm-gray-300 p-2 flex items-center font-semibold w-full group ${getFieldWidthClass(it)}`}>
                                                        {translate("field." + it)}
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        {Object.values(groupedReportItemsData).map((item, index) => {
                                            const previewData = item.map((it, index) => {
                                                const data = legendValuesToggle ? Object.assign({}, it, {
                                                    Accessibility: REPORT_LAYOUT_LEGEND_DATA[it.Accessibility].text,
                                                    Timeliness: REPORT_LAYOUT_LEGEND_DATA[it.Timeliness].text,
                                                    Accuracy: REPORT_LAYOUT_LEGEND_DATA[it.Accuracy].text,
                                                    Attitude: REPORT_LAYOUT_LEGEND_DATA[it.Attitude].text,
                                                    Operations: REPORT_LAYOUT_LEGEND_DATA[it.Operations].text
                                                }) : it

                                                return (
                                                    <div className={`col-span-9 mx-1 my-2 pb-2 ${index != (item.length - 1) ? "border-b-2" : ""}`}>
                                                        <DisplayDataGrid
                                                            addGridClass={"grid-cols-9 p-1 text-tm-gray-700 font-bold"}
                                                            displayList={Object.values(getReportModalFields()).filter(it => it.name != "ServiceAreaName" && it.type != 'hidden')}
                                                            data={data}
                                                            translate={translate}
                                                        />
                                                    </div>
                                                )
                                            })

                                            return (
                                                <div className={'grid grid-cols-12 col-span-full mx-1 my-2 pb-2 border-b-2'}>
                                                    <DisplayDataGrid
                                                        addGridClass={"col-span-3 grid-cols-3 row-span-3 p-1 text-tm-gray-700 font-bold"}
                                                        displayList={Object.values({
                                                            ServiceAreaName: new Field('ServiceAreaName', '', [], false, 'custom', {
                                                                hideLabel: true,
                                                                addContainerClass: getFieldWidthClass('ServiceAreaName'),
                                                                render: (item) => (
                                                                    <div>
                                                                        {item.ServiceAreaName} {item.Contact && (<span className={'font-normal'}> - {item.Contact}</span>)}
                                                                        {item.Notes && (<div className={'leading-[1rem] font-normal text-xs ml-1 mt-1'}>{item.Notes}</div>)}
                                                                    </div>
                                                                )
                                                            })
                                                        })}
                                                        data={item[0]}
                                                        translate={translate}
                                                    />
                                                    {previewData}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                                {parseInt(getProp(secondResource, 'data.data.DashboardReportStatusID', 0)) === 1 && (
                                    <>
                                        <div className="grid grid-cols-12 divide-x sticky top-0 left-0  z-10">
                                            {Object.keys(getReportModalFields()).filter(it => getReportModalFields()[it].type != 'hidden').map(it => {
                                                return (
                                                    <div className={`text-sm top-0 sticky border-b border-tm-gray-300 bg-tm-gray-50 flex-initial border-b border-tm-gray-300 xl:p-2 flex items-center font-semibold w-full group ${getFieldWidthClass(it)}`}>
                                                        {translate("field." + it)}
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        {Object.keys(groupedTableFields).map((item) => {
                                            const fieldsHtmlArr = groupedTableFields[item].map((it, i) => {
                                                return (
                                                    <div className={`${i != (groupedTableFields[item].length - 1) ? 'border-b-2' : ''} col-span-9 grid gap-2 grid-cols-9`}>
                                                        {fieldsToHtml(Object.values(Object.assign({}, it)).slice(1, 10), translate, (name, value) => handleModalGroupedInputChange(name, value, item, i), {})}
                                                    </div>
                                                )
                                            })

                                            return (
                                                <div className={`grid grid-cols-12 gap-2 mx-1 my-2 pb-2 border-b-2 `}>
                                                    <DisplayDataGrid
                                                        addGridClass={"grid-cols-1 p-1 text-tm-gray-700 font-bold col-span-3 row-span-3"}
                                                        displayList={Object.values(groupedTableFields[item][0]).slice(0, 1)}
                                                        data={{
                                                            ServiceAreaName: groupedTableFields[item][0].ServiceAreaName.value,
                                                            Notes: groupedTableFields[item][0].Notes.value,
                                                            Contact: groupedTableFields[item][0].Contact.value,
                                                        }}
                                                        translate={translate}
                                                    />

                                                    {fieldsHtmlArr}
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </ModalDefault>
            </Page>
        </LayoutDashboard>
    )
}

export default NurseDashboardView

const getFieldWidthClass = (key) => {
    switch (key) {
        case 'ServiceAreaName':
            return 'col-span-3'
        case 'EntryNotes':
            return 'col-span-3'
        case 'ServiceAreaShift':
        case 'Accessibility':
        case 'Timeliness':
        case 'Accuracy':
        case 'Attitude':
        case 'Operations':
            return 'col-span-1 min-w-[100px] text-center'
    }
}
