import React, {Component} from "react";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import ErrorFieldMessage from "../error-field-message";

export default class FieldPassword extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="relative">
                    <input type={"password"}
                           onChange={(event) => {
                               this.props.onChange(this.props.name, event.target.value);
                           }}
                           name={this.props.name}
                           value={this.props.value}
                           placeholder={this.props.placeholder}
                           disabled={this.props.disabled}
                           className={this.props.addClass + (this.props.errorMessage ? " border-red-300 text-red-900 placeholder-red-300" : "") + (" block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ")}
                    />
                    {this.props.errorMessage && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon
                                className={'h-5 w-5 text-red-500'}
                            />
                        </div>
                    )}
                </div>
                <ErrorFieldMessage
                    errorMessage={this.props.errorMessage}
                    translate={this.props.translate}
                />
            </React.Fragment>
        )
    }
}
