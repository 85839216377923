const tabResourceReducer = (state = {}, action) => {
    switch (action.type) {
        case 'CLEAR_TABS_RESOURCE':
            return Object.assign({}, state, {
                data: {},
                isLoading: {},
                error: false,
                errorMessage: null
            });
        case 'GET_TABS_RESOURCE':
            return Object.assign({}, state, {
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: true}),
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_TABS_RESOURCE':
            return Object.assign({}, state, {
                data: Object.assign(state.data ? state.data : {}, {[action.data.resource]: action.data.data}),
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_TABS_RESOURCE':
            return Object.assign({}, state, {
                data: Object.assign(state.data ? state.data : {}, {[action.data.resource]: null}),
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.resource]: false}),
                error: true,
                errorMessage: action.data
            });

        case 'SAVE_TABS_RESOURCE':
            return Object.assign({}, state, {
                isLoadingGlobal: true,
                updateResult: null
            });
        case 'DONE_SAVE_TABS_RESOURCE':
            return Object.assign({}, state, {
                isLoadingGlobal: false,
                updateResult: action.data
            });
        default:
            return state;
    }
};

export default tabResourceReducer;
