import React, { useEffect, useRef, useState } from "react";
import Table from "./table";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import TableVertical from "./table-vertical";
import EyeIcon from "@heroicons/react/outline/EyeIcon";
import { PencilIcon } from "@heroicons/react/outline";
import TrashIcon from "@heroicons/react/outline/TrashIcon";
import ReplyIcon from "@heroicons/react/outline/ReplyIcon";
import { getSkeletonData } from "./table-utils";
import Tippy from "@tippyjs/react";

const BREAKPOINT_SMALL = 640;
const BREAKPOINT_MEDIUM = 768;
const BREAKPOINT_LARGE = 1024;
const BREAKPOINT_XL = 1280;
const BREAKPOINT_2XL = 1536;

export function CrudActions({item, onView, onEdit, onDelete, onRestore, rowIndex, isLoading, translate}) {
    return (
        <React.Fragment>
            {!!onView && (
                <Tippy content={translate("btn.view")}>
                    <button
                        disabled={isLoading}
                        className="btn-table-action"
                        onClick={(e) => {
                            onView(item, rowIndex);
                        }}
                    >
                        <EyeIcon className="h-4 w-4"/>
                    </button>
                </Tippy>
            )}

            {!!onEdit && (
                <Tippy content={translate("btn.edit")}>
                    <button
                        id={rowIndex === 0 ? "table-edit-action" : undefined}
                        disabled={isLoading}
                        className="btn-table-action"
                        onClick={(e) => {
                            onEdit(item, rowIndex);
                        }}
                    >
                        <PencilIcon className="h-4 w-4"/>
                    </button>
                </Tippy>
            )}

            {!!onDelete && !!item && !item["ArchivedDate"] && (
                <Tippy content={translate("btn.delete")}>
                    <button
                        disabled={isLoading}
                        className="btn-table-action"
                        onClick={(e) => {
                            onDelete(item, rowIndex);
                        }}
                    >
                        <TrashIcon className="h-4 w-4"/>
                    </button>
                </Tippy>
            )}

            {!!onRestore && !!item && !!item["ArchivedDate"] && (
                <Tippy content={translate("btn.restore")}>
                    <button
                        disabled={isLoading}
                        className="btn-table-action"
                        onClick={(e) => {
                            onRestore(item, rowIndex);
                        }}
                    >
                        <ReplyIcon className="h-4 w-4"/>
                    </button>
                </Tippy>
            )}
        </React.Fragment>
    )
}

let resourceData = [];

const getOptions = (options = {}) => {
    let defaultOptions = {
        behaviour: { // table specific settings (not customizable by user)
            stickyHeader: true,
            rowSelect: false,
            rowHover: false
        },
        style: {
            stripedRows: false,
            horizontalLines: true,
            verticalLines: true,
            condensed: false,
            floatingActions: true,
            frozenActionColumn: false,
        },
        classes: {
            primaryTextClass: "font-bold", // Primary column
            secondaryTextClass: "text-tm-gray-700 leading-5", // applies to whole table
            mutedTextClass: "text-tm-gray-500",
            headerTextClass: "text-sm font-semibold text-tm-gray-900 capitalize", // text class, rename
            tdPadding: "h-12 px-3",

            tdWithSubColumnsPadding: "h-16 pl-3 pr-6",
            tdCondensedWithSubColumnsPadding: "h-12 pl-3 pr-6",

            condensedClass: "h-9",
            tableStripeClass: "bg-tm-gray-50",
            tdPaddingActions: "align-middle pl-3 pr-6",
            verticalLinesClass: "divide-x divide-tm-gray-300",
            horizontalLinesClass: "border-b border-tm-gray-300",
            floatingActionsContainerClass: "z-10 hidden group-hover:flex absolute right-0 py-2 bg-inherit border-l border-tm-gray-300 border-dashed h-full flex items-center"
        },
        icons: {
            IconSortAsc: ArrowSmDownIcon,
            IconSortDesc: ArrowSmUpIcon
        }
    }

    let setOptions = Object.keys(defaultOptions).reduce((memo, option) => {
        if (!!options[option]) {
            memo[option] = Object.assign({}, defaultOptions[option], options[option])
        } else {
            memo[option] = defaultOptions[option];
        }

        return memo;
    }, {});

    setOptions.columns = options.columns;

    return setOptions;
}

export default function ResourceTable({maxHeightClass, data, fields, translate, limit, sort, sortBy, onSortChange, onFilterChange, clearTableColumnFields, headerVisible, options, tableKey, onView, onEdit, onDelete, onRestore, actions, selectedRows, onSelectRow, onRowClick, onSelectAllClick, disableRowSelect, isLoading, verticalTable, tfoot, performanceModeLimit}) {
    const tableContainerRef = useRef(null);
    const [verticalTableIsVisible, setVerticalTableIsVisible] = useState(false);

    if (!!data.length) {
        resourceData = data;
    }

    if (!resourceData.length && isLoading && !!limit) {
        resourceData = getSkeletonData(fields, limit);
    }

    if (data.length === 0 && !isLoading) {
        resourceData = [];
    }

    useEffect(() => {
        if (verticalTable === true) {
            setVerticalTableIsVisible(true);
        } else if (typeof verticalTable === 'string') {
            window.addEventListener('resize', watchWindowBreakpoint);
        }

        watchWindowBreakpoint();

        return () => {
            document.removeEventListener("resize", watchWindowBreakpoint);
        }
    }, [verticalTable])

    const watchWindowBreakpoint = () => {
        if (verticalTable === 'sm' && window.innerWidth <= BREAKPOINT_SMALL) {
            setVerticalTableIsVisible(true);
        } else if (verticalTable === 'md' && window.innerWidth <= BREAKPOINT_MEDIUM) {
            setVerticalTableIsVisible(true);
        } else if (verticalTable === 'lg' && window.innerWidth <= BREAKPOINT_LARGE) {
            setVerticalTableIsVisible(true);
        } else if (verticalTable === 'xl' && window.innerWidth <= BREAKPOINT_XL) {
            setVerticalTableIsVisible(true);
        } else if (verticalTable === '2xl' && window.innerWidth <= BREAKPOINT_2XL) {
            setVerticalTableIsVisible(true);
        } else {
            setVerticalTableIsVisible(false)
        }
    }

    return (
        <div className={verticalTableIsVisible ? "bg-tm-gray-100" : undefined} ref={tableContainerRef}>
            {!verticalTableIsVisible && (
                <Table
                    addClass="min-w-full"
                    maxHeightClass={maxHeightClass}
                    translate={translate}

                    options={getOptions(options)}
                    fields={fields}
                    resourceData={resourceData}
                    headerVisible={headerVisible}
                    onFilterChange={onFilterChange}
                    clearTableColumnFields={clearTableColumnFields}
                    limit={limit}

                    tableKey={tableKey}
                    selectedRows={selectedRows}

                    onSelectRow={onSelectRow}
                    onSelectAllClick={onSelectAllClick}
                    disableRowSelect={disableRowSelect}

                    sort={sort}
                    sortBy={sortBy}
                    onSortChange={onSortChange}
                    isLoading={isLoading}

                    // Default CRUD actions
                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onRestore={onRestore}

                    actions={actions}

                    onRowClick={onRowClick}

                    tfoot={tfoot}
                    performanceModeLimit={performanceModeLimit}
                />
            )}

            {!!verticalTableIsVisible && (
                <TableVertical
                    fields={fields}
                    resourceData={resourceData}
                    options={getOptions(options)}

                    verticalTable={verticalTable}

                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onRestore={onRestore}

                    actions={actions}

                    tableKey={tableKey}

                    isLoading={isLoading}

                    translate={translate}
                />
            )}
        </div>
    )
}
