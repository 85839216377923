import {checkUserHelper} from "../services/api-util.js";
import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {
    doneCreateSecondResource, doneDeleteSecondResource,
    doneGetSecondResource, doneUpdateSecondResource,
    errorCreateSecondResource, errorDeleteSecondResource,
    errorGetSecondResource
} from "../actions/secondResource";
import {pushNotification} from "../actions/ui";
import {doneGetResource, doneUpdateResource, errorUpdateResource} from "../actions/resource";
import {doneGetInfoResource} from "../actions/infoResource";

export function *getSecondResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetSecondResource(result.data));
    } else {
        yield put(errorGetSecondResource(result.data));
    }
}

export function* watchGetSecondResource() {
    yield takeLatest('GET_SECOND_RESOURCE', getSecondResourceCall);
}

export function* watchGetSecondResourceSilent() {
    yield takeLatest('GET_SECOND_RESOURCE_SILENT', getSecondResourceCall);
}

export function* createSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    }

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateSecondResource(result.data));
            }
        } else {
            yield put(doneCreateSecondResource(result.data));
        }

        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        yield put(errorCreateSecondResource(result.data));
    }
}

export function* watchCreateSecondResource() {
    yield takeLatest('CREATE_SECOND_RESOURCE', createSecondResourceCall);
}

export function *updateSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }
        }
        if (action.data.infoPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.infoPiggyQuery ?? action.data.query, action.data.infoPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetInfoResource(resPiggy.data));
            }
        }
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneUpdateSecondResource(result.data));
            }
        } else {
            yield put(doneUpdateSecondResource(result.data));
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorUpdateResource(result.data));
    }
}

export function* watchUpdateSecondResource() {
    yield takeLatest('UPDATE_SECOND_RESOURCE', updateSecondResourceCall);
}

export function* deleteSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
            }
        }

        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        } else {
            yield put(doneDeleteSecondResource(result.data));
        }

        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        yield put(errorDeleteSecondResource(result.data));
    }
}

export function* watchDeleteSecondResource() {
    yield takeLatest('DELETE_SECOND_RESOURCE', deleteSecondResourceCall);
}
