import {classNames, getProp} from "../../../common/util/util-helpers";
import React from "react";
import {
    toFrontDate,
    toFrontDateTime,
    toFrontDateTimeFromUTC,
    toLocalTimeFromUTC
} from "../../../common/util/util-dates";
import { genericMoneyFormatter } from '../../../common/util/util-vanilla'
import { CheckCircleIcon } from '@heroicons/react/solid'
import XIcon from '@heroicons/react/solid/XIcon'

export default function DisplayDataGrid({
                                            displayList,
                                            data,
                                            gridClass = "grid gap-4",
                                            addGridClass = "grid-cols-12",
                                            translate
                                        }) {
    return (
        <div className={classNames(gridClass, addGridClass)}>
            {displayList.map(it => fieldData(it, data, translate))}
        </div>
    )
}

const fieldData = (item, data, translate) => {
    return (
        <div
            key={item.name}
            className={item?.metadata?.addContainerClass}
        >
            {!item?.metadata?.hideLabel && (
                <dt className={item.labelClass ?? "flex text-sm font-medium text-tm-gray-500"}>
                    {item?.metadata?.labelRaw ? item?.metadata?.labelRaw : translate("field." + (item?.metadata?.label ?? item.name))}

                    <span className="pl-3">
                    {!!item?.metadata?.fieldOptions && item.metadata.fieldOptions(item)}
                </span>
                </dt>
            )}

            <dd className={item.dataDisplayClass ?? "mt-1 text-sm text-tm-gray-700"}>
                {processData(item, data, translate) ?? "/"}
            </dd>
        </div>
    )
}

const processData = (item, data, translate) => {
    switch (item.type) {
        case 'custom':
            if (!!item?.metadata?.render) {
                return item.metadata.render(data);
            }
            return getProp(data, item?.name, "") || "/";
        case 'select':
            return item?.props?.values ? item.props.values[getProp(data, item?.name, "")] : getProp(data, item?.name, "")
        case 'select-search':
            return item?.metadata?.labelField ? data[item?.metadata?.labelField] : data[item.name.replace("ID", "")];
        case 'money':
            return <div className="">{genericMoneyFormatter(data[item.name])}</div>
        case "date":
            return toFrontDate(data[item.name])
        case "datetimezone":
            return toFrontDateTimeFromUTC(data[item.name])
        case 'checkbox':
        case 'switch':
        case 'toggle':
            return getProp(data, item?.name, "") ?
                <div className="text-left">
                    <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
                </div> :
                <div className="text-left">
                    <XIcon className="w-4 h-4 text-tm-gray-400 inline"/>
                </div>
        case "file":
            return (
                <a
                    className="flex text-primary hover:text-tm-gray-900"
                    title={translate("text.click_to_download")}
                    href={item?.metadata?.filePath}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-1.5em w-1.5em mr-1"
                         viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd"
                              d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                              clipRule="evenodd"/>
                    </svg>

                    {data[item.name]}
                </a>
            )
        default:
            return getProp(data, item?.name, "") || "/";
    }
}
