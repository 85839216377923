import React, {Fragment} from "react";
import {Transition} from "@headlessui/react";
import ExclamationIcon from "@heroicons/react/outline/ExclamationIcon";
import CheckCircleIcon from "@heroicons/react/outline/CheckCircleIcon";
import XIcon from "@heroicons/react/outline/XIcon";

const newLineToHTML = (text) => {
    if (text && typeof text === "string") {
        text = text.replace(/\n/g, "<br />")
    }

    return text;
}

export default function Notification({notification, closeMessage, translate}) {
    const message = typeof notification.message === "string" ? translate(notification.message) : notification.message;

    return (
        <React.Fragment key={notification.id}>
            <Transition
                show={!notification.destroy}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    id={"resource-notification-" + notification.id}
                    className="max-w-sm w-full bg-inverse border border-divider shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                >
                    <div className="p-4">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                {!!notification.isError
                                    ? <ExclamationIcon className="h-6 w-6 text-red-400"
                                                       aria-hidden="true"/>
                                    : <CheckCircleIcon className="h-6 w-6 text-green-400"
                                                       aria-hidden="true"/>
                                }

                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-default">Notification:</p>
                                {typeof notification.message === "string" ?
                                    (
                                        <p className="mt-1 text-sm text-tm-gray-500"
                                           dangerouslySetInnerHTML={{__html: newLineToHTML(message)}}/>
                                    )
                                    :
                                    message
                                }
                            </div>
                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    className="bg-inverse rounded-md inline-flex text-icon hover:text-muted focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-link"
                                    onClick={() => closeMessage(notification.id)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </React.Fragment>
    )
}
