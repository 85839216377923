import React, {Component} from "react";
import {scrollIntoView} from "../../common/util/util-vanilla";
import ErrorFieldMessage from "../error-field-message";

export default class FieldCheckbox extends Component {

    render() {
        return (
            <React.Fragment>
                <input
                    ref={this.props.innerRef}
                    id={this.props.id}
                    type={"checkbox"}
                    className={(this.props.className ? this.props.className + " " : "") + (this.props.disabled ? " checkbox-disabled" : "")}
                    name={this.props.name}
                    checked={!!this.props.value && (this.props.value !== "0")}
                    onChange={(event) => {
                        this.props.onChange(this.props.name, !!event.target.checked);
                    }}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                    disabled={this.props.disabled}
                />
                <ErrorFieldMessage
                    errorMessage={this.props.errorMessage}
                    translate={this.props.translate}
                />
            </React.Fragment>
        )
    }
}
