import React, { useEffect, useState } from 'react'
import InfoParagraph from '../info-paragraph'

const getPermissions = async () => {
    await navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
            this.setState({
                videoAvailable: true,
            })

            stream.getTracks().forEach((track) => {
                track.stop()
            })

            if (this.isSafari) {
                this.setState({
                    cameraPermission: true
                })
            }
        })
        .catch(() => this.setState({
            videoAvailable: false
        }))
}

export default function CameraCanvas ({ videoRef, onVideoClick, children, errorMessage }) {

    const [videoErrorMessage, setVideoErrorMessage] = useState(false)

    useEffect(() => {
        return () => closeStream()
    }, [])

    const getVideo = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: { width: 1920, height: 1920 }
            })
            .then(stream => {
                let video = videoRef.current
                video.srcObject = stream
                video.play()
            })
            .catch(err => {
                setVideoErrorMessage(true)
                console.error(err)
            })
    }

    const closeStream = () => {
        let video = videoRef.current
        let stream = video?.srcObject
        let tracks = stream?.getTracks()

        if (!!tracks) {
            tracks.forEach(function (track) {
                track.stop()
            })
        }

        if (video?.srcObject) {
            video.srcObject = null
        }
    }

    useEffect(() => {
        getVideo()
    }, [videoRef])


    return (
        <React.Fragment>
            {videoErrorMessage && (
                <div>
                    <div className="p-4">
                        <InfoParagraph
                            type={'danger'}
                        >
                            {errorMessage}
                        </InfoParagraph>
                    </div>
                </div>
            )}
            {!videoErrorMessage && (
                <div
                    onClick={onVideoClick}
                    className="h-full flex flex-col justify-center bg-black relative"
                >
                    <video id="video" className="w-full" ref={videoRef}/>

                    {children}
                </div>
            )}
        </React.Fragment>
    )
}