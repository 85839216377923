import React, {useState} from "react";
import Resources from "../../../data/services/resources";
import LayoutDashboard from "../../../components/layout-dashboard";
import Page from "../../../components/layout-dashboard/page";
import PageHeader from "../../../components/layout-dashboard/page/page-header";
import NavResponsive from "../../../components/nav-responsive";
import MobileTabs from "../../../components/tabs/mobile-tabs";
import AdminPeopleNursesView from "./nurses-view";
import AdminPeopleServicemanView from "./serviceman-view";


const AdminPeopleView = (props) => {
    /** Constants
     ================================================================= */
    const {translate} = props

    /** Helpers
     ================================================================= */
    const getTabs = () => ([
        { name: 'Nurses', resource: Resources.Nurses, current: true, visible: true},
        { name: 'Serviceman', resource: Resources.ReportsServiceman, current: false, visible: true}
    ])

    /** Fields/Data definitions
     ================================================================= */

    /** State
     ================================================================= */
    const [tabs, setTabs] = useState(getTabs())
    const [selectedTab, setSelectedTab] = useState(tabs[0].resource)

    /** UI events
     ================================================================= */
    const handleTabChange = (resource) => {
        if (resource === selectedTab) return false

        setTabs(tabs.map((it) => {
            it.current = it.resource === resource;

            return it;
        }))
        setSelectedTab(resource)
    }

    /** Render
     ================================================================= */

    return (
        <Page>
            <div className="border-b border-tm-gray-300 sm:px-6 lg:px-8 relative z-20" id={"scroll-to-top"}>
                <div className="hidden lg:block">
                    <NavResponsive
                        className="relative pr-10 z-20"
                        tabs={tabs}
                        onTabChange={handleTabChange}
                        translate={translate}
                    />
                </div>

                <div className="mb-5 lg:hidden">
                    <MobileTabs
                        tabs={tabs}
                        onTabChange={handleTabChange}
                        translate={translate}
                    />
                </div>
            </div>

            {selectedTab === Resources.Nurses && (
                <div>
                    <AdminPeopleNursesView
                        translate={translate}
                    />
                </div>
            )}

            {selectedTab === Resources.ReportsServiceman && (
                <div>
                    <AdminPeopleServicemanView
                        translate={translate}
                    />
                </div>
            )}

        </Page>
    )
}

export default AdminPeopleView
