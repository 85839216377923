export function getSingleRecordResource(data) {
    return {
        type: 'GET_SINGLE_RECORD_RESOURCE',
        data: data
    };
}

export function doneGetSingleRecordResource(data) {
    return {
        type: 'DONE_GET_SINGLE_RECORD_RESOURCE',
        data: data
    };
}

export function errorGetSingleRecordResource(data) {
    return {
        type: 'ERROR_GET_SINGLE_RECORD_RESOURCE',
        data: data
    };
}

export function resetSingleRecordResource() {
    return {
        type: 'RESET_SINGLE_RECORD_RESOURCE',
        data: []
    }
}
