import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import ModalDefault from "../modal/modal-default";
import {FieldsManager} from "../../data/services/fields.js";
import {fieldsToHtml} from "../../util/util-fields";
import InfoParagraph from "../info-paragraph";
import {classNames} from "../../common/util/util-helpers";
import {cloneDeep, scrollErrorIntoViewFields} from "../../common/util/util-vanilla";

const ModalSaveResource = forwardRef(({
                                show,
                                title,
                                widthClass,
                                translate,
                                onClose,
                               closeButtonLabel,
                                onSubmit,
                                hideXbtn = false,


                                fields,

                                getRawFields,
                                metadata,
                                gridColsClass,

                                renderFields,

                               buttonLabel= undefined,
                               customButtonsHTML= undefined,
                               refreshFields= undefined,
                               handleInputChange= undefined,
                               gridClass = undefined,
                               errorMessage = null,
                               htmlBefore = null,
                               htmlAfter = null,
                                startSubmit = false
                           }, ref) => {

    /** State
     ================================================================= */
    const [fieldTemplate, setFieldTemplate] = useState(fields)
    const [canSubmit, setCanSubmit] = useState(startSubmit)

    /** UI events
     ================================================================= */
    const handleItemInputChange = (name, value) => {
        const fieldsTmp = handleInputChange
            ? handleInputChange(fieldTemplate, name, value)
            : FieldsManager.updateField(fieldTemplate, name, value)

        setFieldTemplate(fieldsTmp)
        setCanSubmit(true)
    }

    const handleValidateFields = () => {
        let validatedFields = FieldsManager.validateFields(fieldTemplate);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            if (!!getRawFields) {
                return validatedFields
            }
            setFieldTemplate(validatedFields)
            setCanSubmit(false)
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            scrollErrorIntoViewFields([validatedFields])
            setFieldTemplate(validatedFields)
        }
    }

    const onUpdateFieldTemplate = (fieldsUpdate) => {
        setFieldTemplate(cloneDeep(fieldsUpdate))
    }
    /** Lifecycles
     ================================================================= */
    useEffect(() => {
        if (!!show) {
            setFieldTemplate(fields)
            setCanSubmit(startSubmit)
        }
    }, [show, refreshFields])

    useImperativeHandle(ref, () => ({
        handleCanSubmitChange(parentCanSubmit) {
            setCanSubmit(parentCanSubmit)
        },
    }))

    /** Render
     ================================================================= */

    const fieldsHtml = !!renderFields ? renderFields(fieldTemplate, translate, handleItemInputChange, metadata, onUpdateFieldTemplate) : fieldsToHtml(Object.values(Object.assign({}, fieldTemplate)), translate, handleItemInputChange, metadata);

    return (
        <ModalDefault
            show={show}
            title={title}
            widthClass={widthClass}
            translate={translate}
            onClose={onClose}
            onButtonClick={onSubmit ? () => onSubmit(handleValidateFields(), fieldTemplate) : null}
            buttonDisabled={!canSubmit}
            buttonLabel={buttonLabel ?? translate("btn.save")}
            closeButtonLabel={onClose ? (closeButtonLabel ?? translate("btn.close")) : null}
            customButtonsHTML={customButtonsHTML}
            hideXbtn={hideXbtn}
        >
            {errorMessage && (
                <div className={"pt-6 px-6"}>
                    <InfoParagraph type="danger">
                        {translate(errorMessage)}
                    </InfoParagraph>
                </div>
            )}

            {htmlBefore}

            <div
                className={gridClass ?? classNames("grid gap-4 p-6", gridColsClass ?? "grid-cols-6", "grid gap-4")}>
                {fieldsHtml}
            </div>

            {htmlAfter}
        </ModalDefault>
    )
})

export default ModalSaveResource
