import React, { useEffect } from "react";
import LoaderSmall from "../../components/loader-small";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../common/util/util-helpers";

export default function LoggingInView({history}) {
    const user = LocalStorage.get('user')

    useEffect(() => {
        if (getProp(user, 'Contact.UserType', "0") === "1") {
            window.open("/admin","_self")
        } else if (getProp(user, 'Contact.UserType', "0") === "2") {
            window.open("/home","_self")
        } else if (getProp(user, 'Contact.UserType', "0") === "3") {
            window.open("/serviceman-reports","_self")
        }
    }, [user])

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-inverse">
            <div className="text-center">
                <LoaderSmall/>
                <p className="text-tm-gray-700 text-base tracking-wide font-bold">Logging in</p>
            </div>
        </div>
    )
}
