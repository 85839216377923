import React from "react";
import {classNames} from "../../common/util/util-helpers";
import {formatMoney} from "../../util/util";
import {numberWithCommasToBack} from "../../util/util-formaters";

export default function FieldMoney({item = {}, onChange}) {
    if (!item.props) {
        item.props = {}
    }

    const removeCommas = (e) => {
        e.target.value = e.target.value.replace(/,/g, '');

        const target = e.target;
        setTimeout(() => {
            target.setSelectionRange(0,0)
        }, 10)
    }

    const addCommas = (e) => {
        e.target.value = numberWithCommasToBack(e.target.value);
        e.target.value = formatMoney(e.target.value);
        onChange(item.name, e.target.value);
    }

    const handleInputChange = (e) => {
        let value = e.target.value;
        let original = value.toString().split(".");
        if (original[1] && original[1].length > 2) {
            return original[0] + (original[1] ? "." + original[1].charAt(0) + original[1].charAt(1) : "");
        } else {
            value = value.replace(/[^0-9.]/g, '');
            value = value.replace(/,/g, "");
        }
        onChange(item.name, value)
    }

    return (
        <input
            id={item.props.id ?? item.name}
            onChange={handleInputChange}
            onBlur={addCommas}
            onFocus={removeCommas}
            name={item.name}
            value={item.value}
            placeholder={item?.props?.placeholder}
            disabled={item.disabled}
            autoFocus={item.props.autofocus}
            className={
                classNames(
                    item?.props?.className ?? "form-control",
                    !!item.errorMessage ? item?.metadata?.errorClass ?? "form-control-error" : undefined
                )
            }
        />
    )
}
