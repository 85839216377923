import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util.js";
import {
    doneCreateResource,
    doneDeleteResource,
    doneGetResource,
    doneGetResourceDetails,
    doneUpdateResource,
    errorCreateResource,
    errorDeleteResource,
    errorGetResource,
    errorGetResourceDetails,
    errorUpdateResource
} from "../actions/resource";
import {doneGetSecondResource} from "../actions/secondResource";
import {pushNotification} from "../actions/ui";
import {doneGetTabsResource} from "../actions/tabsResource";
import {doneGetStackedResource} from "../actions/stackedResource";
import {doneGetInfoResource} from "../actions/infoResource";
import {doneGetHistoryResource, errorGetHistoryResource} from "../actions/historyResource";

export function *getResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResource(result.data));
    } else {
        yield put(errorGetResource(result.data));
    }
}

export function* watchGetResource() {
    yield takeLatest('GET_RESOURCE', getResourceCall);
}

export function* watchGetResourceSilent() {
    yield takeLatest('GET_RESOURCE_SILENT', getResourceCall);
}

export function *getResourceDetailsCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResourceDetails(result.data));
    } else {
        yield put(errorGetResourceDetails(result.data));
    }
}

export function* watchGetResourceDetails() {
    yield takeLatest('GET_RESOURCE_DETAILS', getResourceDetailsCall);
}

export function* createDocumentResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.uploadDocument,
        user,
        action.data.resource,
        action.data.file,
        Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {}));

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }
    
    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else {
            yield put(doneCreateResource(result.data));
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorCreateResource(result.data));
    }
}

export function* watchCreateDocumentResource() {
    yield takeLatest('CREATE_DOCUMENT_RESOURCE', createDocumentResourceCall);
}

export function* createResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    }

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        console.log('action.data.file', action.data.file)
        if (action.data.file && action.data.file.length > 1) {
            const id = getIdForImageResource(action, result);
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: id}));
        } else if (action.data.file && action.data.file[0]) {
            const id = getIdForImageResource(action, result);
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: id}));
        }


        if (action.data.tabsPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.tabsPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetTabsResource({resource: action.data.tabsPiggyResource, data: resPiggy.data}));
            }
        }
        if (action.data.stackedPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.stackedPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetStackedResource({resource: action.data.stackedPiggyResource, data: resPiggy.data}));
            }
        }
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }
        if (action.data.infoPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.infoPiggyQuery ?? action.data.query, action.data.infoPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetInfoResource(resPiggy.data));
            }
        }
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else {
            yield put(doneCreateResource(result.data));
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorCreateResource(result.data));
    }
}

export function* watchCreateResource() {
    yield takeLatest('CREATE_RESOURCE', createResourceCall);
}

export function *updateResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.file && action.data.file.length > 1) {
            yield call(Api.uploadFiles,
                user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file,
                Object.assign(action.data.fileParams ? action.data.fileParams : action.data.params, {id: result.data.id}));
        } else if (action.data.file && action.data.file[0]) {
            yield call(Api.uploadFile, user,
                action.data.fileResource ? action.data.fileResource : action.data.resource,
                action.data.file[0],
                action.data.fileParams ? action.data.fileParams : action.data.params);
        }

        if (action.data.tabsPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.tabsPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetTabsResource({resource: action.data.tabsPiggyResource, data: resPiggy.data}));
            }
        }
        if (action.data.stackedPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.stackedPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetStackedResource({resource: action.data.stackedPiggyResource, data: resPiggy.data}));
            }
        }
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }
        } else {
            yield put(doneUpdateResource(result.data));
        }
        if (action.data.infoPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.infoPiggyQuery ?? action.data.query, action.data.infoPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetInfoResource(resPiggy.data));
            }
        }
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorUpdateResource(result.data));
    }
}

export function* watchUpdateResource() {
    yield takeLatest('UPDATE_RESOURCE', updateResourceCall);
}

export function* deleteResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.stackedPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.stackedPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetStackedResource({resource: action.data.stackedPiggyResource, data: resPiggy.data}));
            }
        }
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneDeleteResource(result.data));
            }
        } else {
            yield put(doneDeleteResource(result.data));
        }
        if (action.data.infoPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.infoPiggyQuery ?? action.data.piggyQuery, action.data.infoPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetInfoResource(resPiggy.data));
            }
        }
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
            }
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorDeleteResource(result.data));
    }
}

export function* watchDeleteResource() {
    yield takeLatest('DELETE_RESOURCE', deleteResourceContactCall);
}

export function* getHistoryResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetHistoryResource(result.data));
    } else {
        yield put(errorGetHistoryResource(result.data));
    }
}

export function* watchGetHistoryResource() {
    yield takeLatest('GET_HISTORY_RESOURCE', getHistoryResourceCall);
}


function getIdForImageResource(action, result) {
    return result.data.id;
}
