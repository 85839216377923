import React, {useEffect, useState} from "react";
import LocalStorage from "../../util/localStorage";
import {logoutClear} from "../../data/actions/user";
import {useDispatch, useSelector} from "react-redux";

const PublicLayout = (props) => {

    /** Constants
     ================================================================= */
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user)

    const [logout, setLogout] = useState(false)

    /** Lifecycles
     ================================================================= */
    useEffect(() => {
        if (!!logout) {
            LocalStorage.remove('user')
            LocalStorage.remove('lookup')
            dispatch(logoutClear())
            props.history.push('/login')
        }
    }, [logout])

    useEffect(() => {
        if (user?.logout) {
            setLogout(true);
        }
    }, [user])

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export default PublicLayout
