import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDefaultTableOptions, getProp} from "../../../util/util";
import {
    createDataSelect,
    deleteDataSelect,
    getDataSelect,
    updateDataSelect
} from "../../../data/selectors/resourceSelectors";
import {Field, FieldsManager} from "../../../data/services/fields.js";
import LocalStorage from "../../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData} from "../../../util/util-fields";
import {
    DASHBOARD_REPORT_STATUS_DUE,
    DEFAULT_CRUD_STATE,
    REPORT_LAYOUT_LEGEND_DATA,
    USER_DATETIME_FORMAT_VALUES,
    USER_TIMEZONE_VALUES
} from "../../../util/util-constants";
import Resources from "../../../data/services/resources";
import ActiveFilters from "../../../components/active-filters";
import ModalConfirm from "../../../components/modal-confirm";
import ModalSaveResource from "../../../components/modal-save-resource";
import NoRecordsTable from "../../../components/no-records-found/no-records-table";
import PaginationNew from "../../../components/pagination-new";
import TableFooter from "../../../components/resource-table/table-footer";
import ResourceTable from "../../../components/resource-table";
import TableFilters from "../../../components/simple-table/table-filters";
import TableCard from "../../../components/simple-table/table-card";
import PageHeader from "../../../components/layout-dashboard/page/page-header";
import Page from "../../../components/layout-dashboard/page";
import {DownloadIcon, EyeIcon, PencilIcon, TrashIcon, ViewListIcon} from "@heroicons/react/outline";
import ModalDefault from "../../../components/modal/modal-default";
import {getSecondDataSelect} from "../../../data/selectors/secondResourceSelectors";
import LoaderSmall from "../../../components/loader-small";
import DisplayDataGrid from "../../../components/display-data/display-data-grid";
import ReportLayoutLegend from "../../../components/report-layout-legend";
import Badge from "../../../components/badge";
import {groupBy} from "../../../common/util/util-vanilla";

const AdminDashboardView = ({
                              translate
                          }) => {
    /** Store
     ================================================================= */
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)
    const secondResource = useSelector((state) => state.secondResource)

    /** Constants
     ================================================================= */
    const pagePath = Resources.AdminDashboardReports + "_tab"
    const dispatch = useDispatch();

    const getData = getDataSelect({ dispatch: dispatch, user: user.data })
    const createData = createDataSelect({ dispatch: dispatch, user: user.data })
    const updateData = updateDataSelect({ dispatch: dispatch, user: user.data })
    const deleteData = deleteDataSelect({ dispatch: dispatch, user: user.data })
    const restoreData = updateDataSelect({ dispatch: dispatch, user: user.data })

    const getSecondData = getSecondDataSelect({ dispatch: dispatch, user: user.data })

    const data = getProp(resource, 'data.list', [])
    const count = getProp(resource, 'data.count', 0)
    const isLoading = getProp(resource, 'isLoading', false)

    const secondData = getProp(secondResource, 'data.data.items', [])
    const secondIsLoading = getProp(secondResource, 'isLoading', false)

    const departmentApi = {
        api: 'api/' + Resources.Departments,
        query: {
            offset: 0,
            limit: 30,
            IsEnabled: 1,
            archived: 0
        },
        searchMap: (it) => ({
            label: it.DepartmentName,
            value: it.DepartmentID,
        })
    }
    const metadata = {
        DepartmentID: departmentApi,
        DepartmentIDs: departmentApi
    }

    /** Fields/Data definitions
     ================================================================= */
    const getFields = (item = null) => {
        const fieldTemplates = {
            Description: new Field('Description', '', ['empty'], false, 'textarea', {}, {}),
            DepartmentIDs: new Field('DepartmentIDs', '', [item ? '' : 'empty'], false, item ? 'hidden' : 'select-search', {
                labelField: "DepartmentName",
                hideTable: true
            }, {
                multi: true
            }),
            DepartmentID: new Field('DepartmentID', '', [!item ? '' : 'empty'], false, 'select-search', {
                labelField: "DepartmentName",
                hideDialog: !item
            }, {
            }),
            DueDate: new Field('DueDate', '', ['empty'], false, 'date', {}, {}),
            CompleteDate: new Field('CompleteDate', '', [], false, 'date', {hideDialog: true}, {}),
            DashboardReportStatusID: new Field('DashboardReportStatusID', '', [], false, 'select-search', { hideDialog: true, labelField: "DashboardReportStatus" }, {}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    const getReportModalFields = (data = null) => {
        const fieldTemplate = {
            ServiceAreaName: new Field('ServiceAreaName', '', [], false, 'custom', {
                hideLabel: true,
                addContainerClass: getFieldWidthClass('ServiceAreaName'),
                render: (item) => (
                    <div>
                        {item.ServiceAreaName} {item.Contact && (<span className={'font-normal'}> - {item.Contact}</span>)}
                        {item.Notes && (<div className={'leading-[1rem] font-normal text-xs ml-1 mt-1'}>{item.Notes}</div>)}
                    </div>
                )
            }),
            ServiceAreaShift: new Field('ServiceAreaShift', '', [], false, 'custom', { hideLabel: true, addContainerClass: getFieldWidthClass('ServiceAreaShift') }),
            Accessibility: new Field('Accessibility', '', ['integer_or_empty'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Accessibility') }),
            Timeliness: new Field('Timeliness', '', ['integer_or_empty'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Timeliness') }),
            Accuracy: new Field('Accuracy', '', ['integer_or_empty'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Accuracy') }),
            Attitude: new Field('Attitude', '', ['integer_or_empty'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Attitude') }),
            Operations: new Field('Operations', '', ['integer_or_empty'], false, 'integer', { hideLabel: true, addContainerClass: getFieldWidthClass('Operations') }),
            EntryNotes: new Field('EntryNotes', '', [''], false, 'textarea', { hideLabel: true, addContainerClass: getFieldWidthClass('EntryNotes')}, {rows: 2, addClass: 'min-h-[3rem] ' }),
        }

        return fillFieldsFromData(fieldTemplate, data)
    }

    const getQueryFilterFields = () => ({
        search: new Field('search', '', [''], false, 'search', {
            containerClass: 'col-md-3',
        }, {}),
        limit: new Field('limit', 20, [''], false, 'select', {})
    })

    /** State
     ================================================================= */
        // Fields/Query
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), {}, pagePath, translate))
    const [queryFilterFields, setQueryFilterFields] = useState(LocalStorage.has(pagePath + '_state') ? JSON.parse(LocalStorage.get(pagePath + '_state'))?.queryFilterFields : getQueryFilterFields())
    const [query, setQuery] = useState({
        ...DEFAULT_CRUD_STATE,
    })

    // Modals
    const [columnSettingsModalOpen, setColumnSettingsModalOpen] = useState(false)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [reportsModalOpen, setReportsModalOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [legendValuesToggle, setLegendValuesToggle] = useState(false)

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return Resources.AdminDashboardReports
    }

    const getQuery = () => {
        return {
            ...query,
            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        getData({query: getQuery(), resource: getResourceName()})
    }

    const fetchSecondData = () => {
        getSecondData({query: { DashboardReportID: getProp(selectedItem, 'DashboardReportID', 0) }, resource: Resources.AdminDashboardReportsSingle})
    }

    /** UI events
     ================================================================= */
    const handleToggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen)
        setErrorMessage(null)
    }

    const handleToggleUpdateModal = (item = null) => {
        setSelectedItem(item)
        setUpdateModalOpen(!updateModalOpen)
        setErrorMessage(null)
    }

    const handleToggleConfirmModal = (item = null) => {
        setSelectedItem(item)
        setConfirmModalOpen(!confirmModalOpen)
    }

    const handleToggleReportsModal = (item = null) => {
        setSelectedItem(item)
        setReportsModalOpen(!reportsModalOpen)
        setLegendValuesToggle(false)
    }

    useEffect(() => {
        if (reportsModalOpen && selectedItem) {
            fetchSecondData()
        }
    }, [reportsModalOpen])

    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        handleResetPagination()
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFieldsTmp = Object.assign({}, queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFieldsTmp).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFieldsTmp, it.name, '')
        })

        setQueryFilterFields(queryFilterFieldsTmp)
        handleResetPagination()
    }

    const handleResetPagination = () => {
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [query])

    /** Render
     ================================================================= */
    const groupedReportItemsData = groupBy(secondData, 'ServiceAreaID')

    return (
        <Page>
            <PageHeader
                title={translate("page.heading." + getResourceName())}
                titleClass="mr-5 text-2xl capitalize"
                buttonLabel={translate("btn.create_new")}
                onButtonClick={handleToggleCreateModal}
            />

            <div className="flex">
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <div className="ml-auto flex items-center relative">

                </div>
            </div>

            <TableCard addClass={'relative z-0'}>
                <TableFilters
                    filterFields={queryFilterFields}
                    maxHeaderFilters={6}
                    handleInputChange={handleFilterInputChange}
                    translate={translate}
                />

                <ResourceTable
                    data={data}
                    fields={getFields()}

                    translate={translate}
                    isLoading={isLoading}

                    options={tableOptions}

                    limit={queryFilterFields?.limit?.value ?? 10}

                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={handleUpdateSort}

                    onRowClick={handleToggleUpdateModal}

                    actions={[
                        {
                            action: handleToggleUpdateModal,
                            icon: EyeIcon, // make this a function
                            visible: (item) => item.DashboardReportStatusID != DASHBOARD_REPORT_STATUS_DUE,
                            label: false, // make this a function
                            title: translate('btn.view'),
                            disabled: false,
                            class: false,
                            iconClass: false
                        },
                        {
                            action: handleToggleUpdateModal,
                            icon: PencilIcon, // make this a function
                            visible: (item) => item.DashboardReportStatusID == DASHBOARD_REPORT_STATUS_DUE,
                            label: false, // make this a function
                            title: translate('btn.edit'),
                            disabled: false,
                            class: false,
                            iconClass: false
                        },
                        {
                            action: handleToggleConfirmModal,
                            icon: TrashIcon, // make this a function
                            visible: (item) => item.DashboardReportStatusID == DASHBOARD_REPORT_STATUS_DUE,
                            label: false, // make this a function
                            title: translate('btn.delete'),
                            disabled: false,
                            class: false,
                            iconClass: false
                        },
                        {
                            action: handleToggleReportsModal,
                            icon: ViewListIcon, // make this a function
                            visible: () => true,
                            label: false, // make this a function
                            title: translate('btn.Reports'),
                            disabled: false,
                            class: false,
                            iconClass: false
                        },
                    ]}
                />

                {/*Table footer*/}
                <TableFooter
                    show={!!data.length && !isLoading}
                >
                    <PaginationNew
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={
                            (name, value, currentPage) => name === "offset"
                                ? handleUpdateOffset(value, currentPage)
                                : handleFilterInputChange(name, value)
                        }
                        pageOffset={query.offset}
                        queryFields={queryFilterFields}
                        translate={translate}
                        pageLimit={queryFilterFields?.limit?.value}
                    />
                </TableFooter>

                <NoRecordsTable
                    show={(data.length === 0) && !resource.isLoading}
                    title={translate('text.no_matching_records')}
                    clearFilterText={translate('text.try_without_filters')}
                    onClearFilterClick={handleClearFiltersClick}
                    filters={queryFilterFields}
                />
            </TableCard>

            <ModalDefault
                show={!!reportsModalOpen}
                title={"Dashboard Reports"}
                widthClass={'w-full'}
                translate={translate}
                onClose={handleToggleReportsModal}
                closeButtonLabel={translate("btn.close")}

                options={!secondIsLoading && parseInt(getProp(secondResource, 'data.data.DashboardReportStatusID', 0)) > 1 && (
                    <div onClick={() => setLegendValuesToggle(!legendValuesToggle)}>
                        <Badge
                            type={'outline'}
                            addClass={'cursor-pointer flex items-center justify-center text-primary'}
                        >
                            <EyeIcon className={'w-4 h-4 mr-2 text-primary'}/> Toggle Legend Values
                        </Badge>
                    </div>
                )}
                customButtonsHTML={!secondIsLoading && (
                    <div className={'m-auto'}>
                        <ReportLayoutLegend/>
                    </div>
                )}
                addCustomButtonsClass={'m-auto'}
            >
                <div className="p-5">
                    {!!secondIsLoading && (
                        <div className={'flex justify-center h-12'}>
                            <LoaderSmall
                                svgClass="text-gray-200 animate-spin dark:text-gray-600 fill-primary w-12 h-12"/>
                        </div>
                    )}
                    {!secondIsLoading && (
                        <div className="grid grid-cols-12 border-2 max-h-[79vh] overflow-y-scroll">
                            <div className="grid grid-cols-12 col-span-full divide-x sticky top-0 left-0 z-10">
                                {Object.keys(getReportModalFields()).map(it => {
                                    return (
                                        <div className={`top-0 sticky border-b border-tm-gray-300 bg-tm-gray-50 flex-initial border-b border-tm-gray-300 p-2 flex items-center font-semibold w-full group ${getFieldWidthClass(it)}`}>
                                            {translate("field." + it)}
                                        </div>
                                    )
                                })}
                            </div>

                            {Object.values(groupedReportItemsData).map((item, index) => {
                                const previewData = item.map((it, index) => {
                                    const data = legendValuesToggle ? Object.assign({}, it, {
                                        Accessibility: REPORT_LAYOUT_LEGEND_DATA[it.Accessibility].text,
                                        Timeliness: REPORT_LAYOUT_LEGEND_DATA[it.Timeliness].text,
                                        Accuracy: REPORT_LAYOUT_LEGEND_DATA[it.Accuracy].text,
                                        Attitude: REPORT_LAYOUT_LEGEND_DATA[it.Attitude].text,
                                        Operations: REPORT_LAYOUT_LEGEND_DATA[it.Operations].text
                                    }) : it

                                    return (
                                        <div className={`col-span-9 mx-1 my-2 pb-2 ${index != (item.length - 1) ? "border-b-2" : ""}`}>
                                            <DisplayDataGrid
                                                addGridClass={"grid-cols-9 p-1 text-tm-gray-700 font-bold"}
                                                displayList={Object.values(getReportModalFields()).filter(it => it.name != "ServiceAreaName")}
                                                data={data}
                                                translate={translate}
                                            />
                                        </div>
                                    )
                                })

                                return (
                                    <div className={'grid grid-cols-12 col-span-full mx-1 my-2 pb-2 border-b-2'}>
                                        <DisplayDataGrid
                                            addGridClass={"col-span-3 grid-cols-3 row-span-3 p-1 text-tm-gray-700 font-bold"}
                                            displayList={Object.values({
                                                ServiceAreaName: new Field('ServiceAreaName', '', [], false, 'custom', {
                                                    hideLabel: true,
                                                    addContainerClass: getFieldWidthClass('ServiceAreaName'),
                                                    render: (item) => (
                                                        <div>
                                                            {item.ServiceAreaName} {item.Contact && (<span className={'font-normal'}> - {item.Contact}</span>)}
                                                            {item.Notes && (<div className={'leading-[1rem] font-normal text-xs ml-1 mt-1'}>{item.Notes}</div>)}
                                                        </div>
                                                    )
                                                })
                                            })}
                                            data={item[0]}
                                            translate={translate}
                                        />
                                        {previewData}
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            </ModalDefault>

            <ModalSaveResource
                title={translate('modal_heading.CreateNewReport')}
                widthClass={"max-w-3xl"}
                gridColsClass={"grid-cols-1"}
                show={createModalOpen}
                onClose={handleToggleCreateModal}
                fields={getFields()}
                onSubmit={(params) => {
                    if (params) {
                        params.DepartmentIDs = params.DepartmentIDs.join(",")

                        const query = getQuery()

                        createData({
                            params: params,
                            resource: getResourceName(),
                            piggyResource: getResourceName(),
                            query: query,
                            notificationMessage: translate('text.ReportCreatedSuccessfully')
                        })
                        handleToggleCreateModal()
                    }
                }}
                translate={translate}
                metadata={metadata}
                errorMessage={errorMessage}
            />

            <ModalSaveResource
                title={getProp(selectedItem, 'DashboardReportStatusID', 0) == DASHBOARD_REPORT_STATUS_DUE ? translate('modal_heading.EditReport') : 'Dashboard Report Info'}
                widthClass={"max-w-md"}
                gridColsClass={"grid-cols-1"}
                show={updateModalOpen}
                onClose={() => handleToggleUpdateModal()}
                fields={getFields(selectedItem)}
                onSubmit={(params) => {
                    if (params) {
                        params['DashboardReportID'] = selectedItem['DashboardReportID']
                        updateData({
                            params: params,
                            resource: getResourceName(),
                            piggyResource: getResourceName(),
                            query: getQuery(),
                            notificationMessage: translate('text.ReportUpdatedSuccessfully')
                        })
                        handleToggleUpdateModal()
                    }
                }}
                translate={translate}
                metadata={metadata}
                errorMessage={errorMessage}
                renderFields={parseInt(getProp(selectedItem, 'DashboardReportStatusID', 0)) !== 1 ? (fieldTemplate, translate, handleItemInputChange, metadata) => {
                    return (
                        <div>
                            <DisplayDataGrid
                                addGridClass={"grid-cols-1 p-1 text-tm-gray-700 font-bold"}
                                displayList={Object.values(fieldTemplate)}
                                data={Object.assign({}, selectedItem)}
                                translate={translate}
                            />
                        </div>
                    )
                } : null}
            />

            <ModalConfirm
                title={'Confirm'}
                show={!!confirmModalOpen}
                text={selectedItem?.ArchivedDate ? translate('message.confirm_restore_Report') : translate('message.confirm_archive_Report')}
                onClose={() => handleToggleConfirmModal()}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={() => {
                    if (selectedItem?.ArchivedDate) { // Restore
                        restoreData({
                            params: {
                                ...selectedItem,
                                ArchivedDate: 1
                            },
                            resource: getResourceName(),
                            piggyResource: getResourceName(),
                            query: getQuery(),
                            notificationMessage: translate('text.ReportRestoredSuccessfully')
                        })
                    } else { // Delete
                        deleteData({
                            query: {
                                DashboardReportID: selectedItem['DashboardReportID']
                            },
                            piggyQuery: getQuery(),
                            piggyResource: getResourceName(),
                            resource: getResourceName(),
                            notificationMessage: translate('text.ReportArchivedSuccessfully')
                        })
                    }
                    handleToggleConfirmModal()
                }}
            />
        </Page>
    )
}

export default AdminDashboardView

const getFieldWidthClass = (key) => {
    switch (key) {
        case 'ServiceAreaName':
            return 'col-span-3'
        case 'EntryNotes':
            return 'col-span-3'
        case 'ServiceAreaShift':
        case 'Accessibility':
        case 'Timeliness':
        case 'Accuracy':
        case 'Attitude':
        case 'Operations':
            return 'col-span-1 min-w-[100px] text-center'
    }
}
