import React, {PureComponent} from "react";
import moment from "moment";
import {Provider as Redux} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {rootReducer} from "./data/reducers/index.ts";
import rootSaga from "./data/sagas";
import Translate from "./data/services/translate";
import routes from "./routes.js";
import loggerMiddleware from "./middleware/logger";
import eventsMiddleware from "./middleware/events";
import LocalStorage from "./util/localStorage";
import realtimeMiddleware from './package/realtime/realtimeMiddleware'
import Env from "./util/env.js";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

/**
 * App is using redux-saga for handling side effects (async calls)
 =========================================================================================*/
const sagaMiddleware = createSagaMiddleware();

/**
 * Store is single source of truth for the app, it is hub for all the data and data events
 =========================================================================================*/
const initialStoreState = {
    user: {
        data: LocalStorage.has('user') ? LocalStorage.get('user') : {}
    }
};

let store = createStore(
    rootReducer,
    initialStoreState,
    compose(
        applyMiddleware(
            sagaMiddleware,
            eventsMiddleware,
            loggerMiddleware,
            realtimeMiddleware
        ), f => f
    )
);

realtimeMiddleware.run(
    store,
    () => {
        return Env.getRealtimeServiceUrl();
    },
    () => {
        return LocalStorage.get('user')?.access_token;
    }
);

sagaMiddleware.run(rootSaga);

export const msalConfig = {
    auth: {
        clientId: "96f4e241-66d5-4687-a583-08c8adf6f1a2",
        authority: "https://login.microsoftonline.com/150423c3-4ff3-4a72-9cdb-15808a6a9ca8", // This is a URL (e.g. https://login.microsoftonline.com/150423c3-4ff3-4a72-9cdb-15808a6a9ca8)
        redirectUri: "https://orange-stone-05efcf110.1.azurestaticapps.net/ad",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Root App Component
 =========================================================================================*/
export default class App extends PureComponent {

    constructor(props) {
        super(props);
        moment.locale(props.locale);
    }

    render() {
        const {locale, localeData} = this.props;
        const translator = new Translate(locale, localeData);

        return (
            <MsalProvider instance={msalInstance}>
                <Redux store={store}>
                    {routes(store, translator)}
                </Redux>
            </MsalProvider>
        );
    }
}
