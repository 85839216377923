import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../components/layout";
import {Field, FieldsManager} from "../../data/services/fields.js";
import FieldText from "../../components/field-text";
import {resetPassword, resetUserMessage} from "../../data/actions/user";
import LoaderSmall from "../../components/loader-small";
import {Link} from "react-router-dom";
import InfoBar from "../../components/info-paragraph/info-bar";
import {CheckCircleIcon, ChevronLeftIcon} from "@heroicons/react/outline";

const ResetPasswordView = (props) => {

    /** Constants
     ================================================================= */
    const {history, translate} = props

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user)

    /** Fields/Data definitions
     ================================================================= */
    const getFields = () => ({
        username: new Field('username', '', ['empty', 'email'], false, 'email'),
    })

    const [fields, setFields] = useState(getFields())

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        dispatch(resetUserMessage())
    }, [])

    /** Data events
     ================================================================= */
    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit(event);
        }
    };

    const onSubmit = (event) => {
        event && event.preventDefault();

        const validatedFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            dispatch(resetPassword(FieldsManager.getFieldKeyValues(validatedFields)));
        } else {
            setFields(validatedFields);
        }
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
    }

    /** Render
     ================================================================= */
    return (
        <Layout user={user} history={history}>
            <section className="gradient-form h-[100vh] w-full bg-neutral-200 dark:bg-neutral-700">
                <div className="container h-full m-auto w-full p-10">
                    <div
                        className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200">
                        <div className="w-full xl:w-1/2">
                            <div
                                className="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
                                <div className="">
                                    <div className="px-4 md:px-0">
                                        <div className="md:mx-6 md:p-12">
                                            <div className="text-center mb-12">
                                                <img
                                                    className="mx-auto w-24"
                                                    src="/images/nursing_temp_logo.png"
                                                    alt="logo"/>
                                            </div>

                                            <form onKeyPress={onKeyDown}>
                                                <h2 className="mt-4 text-2xl font-bold leading-9 tracking-tight text-center">
                                                    {translate('text.reset_password')}
                                                </h2>

                                                <h2 className=" text-center">
                                                    Enter your email and we&rsquo;ll send you
                                                    instructions to reset your password
                                                </h2>

                                                <div className="relative mb-8 mt-8" >
                                                    <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                                                        Email
                                                    </label>

                                                    <FieldText
                                                        onChange={handleInputChange}
                                                        {...fields.username}
                                                        placeholder={"email@domain.com"}
                                                        translate={translate}
                                                        addClass="peer block min-h-[auto] w-full rounded border-0 bg-white px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                                    />
                                                </div>

                                                {user.isLoading && (
                                                    <div className="flex justify-center">
                                                        <LoaderSmall/>
                                                    </div>
                                                )}

                                                <div className="flex justify-content-end items-center">
                                                    <button
                                                        disabled={user.isLoading}
                                                        className="btn btn-primary justify-center w-full disabled:bg-transparent disabled:border-primary disabled:text-primary"
                                                        onClick={onSubmit}
                                                    >
                                                        <span className="px-7 relative">
                                                            {!user.reset && (
                                                                translate('btn.reset_password')
                                                            )}

                                                            {user.reset && (
                                                                translate('btn.reset_password_again')
                                                            )}
                                                        </span>
                                                    </button>
                                                </div>
                                            </form>

                                            <div className="min-h-[2.5rem] mt-4">
                                                {user.error && (
                                                    <InfoBar
                                                        type="danger"
                                                        addClass="justify-content-center"
                                                    >
                                                        {translate(user.errorMessage)}
                                                    </InfoBar>
                                                )}

                                                {user.reset && (
                                                    <InfoBar
                                                        type="success"
                                                        Icon={CheckCircleIcon}
                                                        addClass="justify-content-center mb-5">{translate('text.reset_password_sent')}</InfoBar>
                                                )}
                                            </div>

                                            <div className="flex justify-content-end items-center">
                                                <Link className="btn btn-text justify-center w-full font-semibold"
                                                      to={"/login"}
                                                >
                                                    <ChevronLeftIcon className="w-5 h-5 -ml-1 mr-1"/>
                                                    {translate('btn.back_to_login')}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ResetPasswordView;
