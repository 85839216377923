import {useCallback} from "react";
import {createResource, createDocumentResource, deleteResource, getResource, getResourceSilent, updateResource} from "../actions/resource";

export const getDataSelect = ({dispatch, user}) => useCallback(({query, resource}) => dispatch(getResource({
    user: user,
    query: query,
    resource: resource
})), [dispatch])

export const getDataSilentSelect = ({dispatch, user}) => useCallback(({query, resource}) => dispatch(getResourceSilent({
    user: user,
    query: query,
    resource: resource
})), [dispatch])

export const createDataSelect = ({dispatch, user}) => useCallback((object) => {
    dispatch(createResource({
        user: user,
        ...object
    }))
}, [dispatch])

export const createDocumentDataSelect = ({dispatch, user}) => useCallback((object) => {
    dispatch(createDocumentResource({
        user: user,
        ...object
    }))
}, [dispatch])

export const updateDataSelect = ({dispatch, user}) => useCallback((object) => {
    dispatch(updateResource({
        user: user,
        ...object
    }))
}, [dispatch])

export const deleteDataSelect = ({dispatch, user}) => useCallback(({
    query,
    piggyQuery,
    resource,
   infoPiggyResource,
   piggyResource,
    stackedPiggyResource,
    secondPiggyResource,
    notificationMessage
}) => {
    dispatch(deleteResource({
        user: user,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        infoPiggyResource: infoPiggyResource,
        stackedPiggyResource: stackedPiggyResource,
        secondPiggyResource: secondPiggyResource,
        piggyQuery: piggyQuery ?? query,
        notificationMessage: notificationMessage,
    }))
}, [dispatch])
