import React from "react";

const ErrorFieldMessage = ({ errorMessage, translate }) => {

    return errorMessage ?
        <p className="mt-2 text-sm text-red-600">
            {!!translate && (
                errorMessage.reduce((memo, it) => {
                    if (it.key) {
                        return translate(it.key, it.values) + (!!memo ? "," + memo : "")
                    }
                    return translate(it) + (!!memo ? ", " + memo : "")
                }, "")
            )}
            {!translate && (
                <span>Field required*</span>
            )}
        </p>
        :
        null
}

export default ErrorFieldMessage
