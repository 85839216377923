import {call, put} from 'redux-saga/effects';
import Api from './api';
import {logout} from '../actions/user';
import LocalStorage from '../../util/localStorage';
import jwt_decode from 'jwt-decode';
import {getCurrentTimeSeconds} from '../../util/util';

export function* checkUserHelper(user) {
    if (!user || !user.access_token) {
        return {};
    }
    const current = getCurrentTimeSeconds();
    const decoded = jwt_decode(user.access_token);

    const expires = decoded.exp ?? 0;

    if (expires < current) {
        let result = yield call(Api.refresh, user.refresh_token);
        if (result && result.status === 0) {
            if (LocalStorage.get('user') && LocalStorage.get('user').Contact) {
                const newUser = Object.assign(
                    {},
                    LocalStorage.get('user'),
                    result.data,
                );
                LocalStorage.set('user', newUser);

                user = newUser;
            }
        } else {
            yield put(logout());
        }
    }

    return user;
}

export function processResponse(response) {
    return {
        'status': 0,
        'data': response.data.data
    };
}

export function processRegisterResponse(response) {
    return {
        'status': 0,
        'data': response.data
    };
}

export function processError(error) {
    if (error == 'Error: Network Error') {
        return {
            'status': 3,
            'data': 'NO_RESPONSE'
        }
    }

    if (error?.response?.status === 403) {
        logout()
    }

    if (error.response) {
        return {
            'status': 2,
            'data': error.response.data.error ?? error.response.data.message ?? error.response.data.title ?? "API_ERROR",
            'metadata': error?.response?.data?.data ?? null
        }
    } else if (error.request) {
        return {
            'status': 3,
            'data': 'NO_RESPONSE'
        }
    } else {
        return {
            'status': 3,
            'data': error.message
        }
    }
}

export function handleBackendError() {

}
