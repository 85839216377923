import {
    watchLoginCall,
    watchRegisterCall,
    watchResetPasswordCall,
    watchResetPasswordConfirmCall
} from "./userSaga";
import {all} from "redux-saga/effects";
import {
    watchCreateDocumentResource,
    watchCreateResource,
    watchDeleteResource, watchGetHistoryResource,
    watchGetResource,
    watchGetResourceDetails, watchGetResourceSilent,
    watchUpdateResource
} from "./resourceSaga";
import {watchDeleteDocument, watchDownload, watchDownloadStacked, watchUploadDocument} from "./download";
import {watchCloseNotificationDelay, watchPushNotification} from "./ui";
import {
    watchCreateInfoResource,
    watchDeleteInfoResource,
    watchGetInfoResource, watchGetInfoResourceSilent,
    watchUpdateInfoResource
} from "./infoSaga";
import {watchGetTabsResource, watchSaveTabsResource} from "./tabsResourceSaga";
import {
    watchCreateStackedResource,
    watchDeleteStackedResource,
    watchGetStackedResource,
    watchUpdateStackedResource
} from "./stackedResourceSaga";
import {
    watchCreateSecondResource,
    watchDeleteSecondResource,
    watchGetSecondResource,
    watchGetSecondResourceSilent,
    watchUpdateSecondResource
} from "./secoundResourceSaga";
import {watchGetSingleRecordResource} from "./singleRecordResourceSaga";

/*
 * Single entry point to start all Sagas at once
 =========================================================================================*/
export default function *rootSaga() {
    yield all([
        watchLoginCall(),
        watchRegisterCall(),
        watchResetPasswordCall(),
        watchResetPasswordConfirmCall(),

        watchGetResource(),
        watchGetResourceSilent(),
        watchGetSecondResource(),
        watchGetSecondResourceSilent(),
        watchCreateSecondResource(),
        watchUpdateSecondResource(),
        watchDeleteSecondResource(),

        watchGetResourceDetails(),
        watchGetSingleRecordResource(),

        watchCreateResource(),
        watchCreateDocumentResource(),
        watchUpdateResource(),
        watchDeleteResource(),

        watchDownload(),
        watchDownloadStacked(),
        watchUploadDocument(),
        watchDeleteDocument(),

        watchCloseNotificationDelay(),
        watchPushNotification(),

        watchGetInfoResource(),
        watchGetInfoResourceSilent(),
        watchCreateInfoResource(),
        watchUpdateInfoResource(),
        watchDeleteInfoResource(),

        watchGetTabsResource(),
        watchSaveTabsResource(),

        watchGetStackedResource(),
        watchCreateStackedResource(),
        watchUpdateStackedResource(),
        watchDeleteStackedResource(),

        watchGetHistoryResource()
    ]);
}
