import React, { useEffect, useMemo, useRef, useState } from "react";
import NavResponsiveMenu from "./nav-responsive-menu";
import {classNames} from "../../common/util/util-helpers";
import { cloneDeep } from "../../common/util/util-vanilla";
import {ExclamationIcon} from "@heroicons/react/outline";
import {PencilAltIcon} from "@heroicons/react/solid";

const NavResponsive = (props) => {
    const {tabs, onTabChange, translate} = props;

    let allTabs = useMemo(() => cloneDeep(tabs).filter(tab => tab.visible), [tabs]);
    const navRef = useRef(null);
    const navItems = useRef({});
    const [lastVisibleTab, setLastVisibleTab] = useState(allTabs.length - 1)

    useEffect(() => {
        const navWidthObserver = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                handleNavWidthChange(entries);
            });
        });

        navWidthObserver.observe(navRef.current);
    }, [tabs])

    const handleNavWidthChange = (entries) => {
        getLastVisibleTab(allTabs.length - 1, entries)
    }

    const getLastVisibleTab = (currentTab, entries) => {
        if (navItems.current[currentTab]?.offsetLeft + navItems.current[currentTab]?.offsetWidth > entries[0].contentRect.width) {
            getLastVisibleTab(currentTab - 1, entries)
        } else {
            setLastVisibleTab(currentTab)
        }
    }

    return (
        <div className={classNames(props.addClass, props.className ?? "relative pr-10 border-b border-tm-gray-300 z-20")}>
            <div className="overflow-hidden">
                <nav
                    ref={navRef}
                    className="-mb-px flex"
                    aria-label="Tabs"
                >
                    {allTabs.map((tab, i) => {
                        return (
                            <button
                                ref={ref => navItems.current[i] = ref}
                                key={tab.name}
                                onClick={() => onTabChange(tab.resource)}
                                className={classNames(
                                    i > lastVisibleTab ? "invisible" : undefined,
                                    tab.current
                                        ? 'border-link text-link bg-tm-gray-100 border border-tm-gray-200'
                                        : 'border-transparent text-tm-gray-700 hover:text-tm-gray-900',
                                    'whitespace-nowrap py-2 px-4 font-semibold text-xs uppercase rounded-tr-lg rounded-tl-lg flex outline-0',
                                )}
                            >
                                {tab.labelRaw ? tab.name : translate("tab." + tab.name)}
                                {tab.isDirty && (<PencilAltIcon className={"ml-1 text-green-800 w-4 h-4"} />)}
                                {tab.haveError && (<ExclamationIcon className={"ml-1 text-red-500 w-4 h-4"} />)}
                                {tab.count ? (
                                    <span
                                        className={classNames(
                                            tab.current ? 'bg-primary-100 text-primary-600' : 'bg-tm-gray-100 text-secondary-900',
                                            'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                  {tab.count}
                                </span>
                                ) : null}
                            </button>
                        )
                    })}
                </nav>
            </div>

            {lastVisibleTab <  allTabs.length - 1 && (
                <NavResponsiveMenu
                    allTabs={cloneDeep(allTabs)}
                    lastVisibleTab={lastVisibleTab}
                    onTabChange={onTabChange}
                    translate={translate}
                />
            )}
        </div>
    )
}

export default NavResponsive;
