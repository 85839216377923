export function getHistoryResource(data) {
    return {
        type: 'GET_HISTORY_RESOURCE',
        data: data
    };
}

export function doneGetHistoryResource(data) {
    return {
        type: 'DONE_GET_HISTORY_RESOURCE',
        data: data
    };
}

export function errorGetHistoryResource(data) {
    return {
        type: 'ERROR_GET_HISTORY_RESOURCE',
        data: data
    };
}
