import React from "react";
import ChevronDownIcon from "@heroicons/react/solid/ChevronDownIcon";
import Resources from "../../data/services/resources";
import LocalStorage from "../../util/localStorage";
import {classNames, getLookup, getProp} from "../../common/util/util-helpers";
import {fieldsToHtml, includeFields} from "../../util/util-fields";
import PopOver from "../popover";
import FieldSelectSearch from "../field-select-search";
import {queryLimit} from "../../util/util-constants";
import {cloneDeep} from "../../common/util/util-vanilla";

export default function TableFilters(props) {
    const {
        translate,
        selects,
        filterFields,
        handleInputChange,
        customHtml,
        customHeaderHtml,
        hideLimit,
        hideFilterPopover,
        className,
        containerClass,
        addMiscFiltersClass,
        maxHeaderFilters = 3,
        hideSearch
    } = props;

    const defaultLabelType = "float";

    const defaultSelects = getDefaultSelects(filterFields, translate);

    const filterFieldsValues = Object.values(filterFields).map(it => {
        if (!it.metadata) Object.assign(it, {metadata: {}})
        if (!it.metadata.labelType) {
            it.metadata.labelType = defaultLabelType;
        }
        return it;
    });

    const filterSelects = selects ? Object.assign({}, defaultSelects, selects) : defaultSelects;

    const headerFilterLimit = !!customHeaderHtml ? 1 : maxHeaderFilters;

    const largeScreenFields = filterFieldsValues.filter((it, i) => i < headerFilterLimit && it.name !== 'limit').map(it => it.name);

    const largeScreenFilters = fieldsToHtml(Object.values(Object.assign({}, includeFields(filterFields, largeScreenFields))), translate, handleInputChange, filterSelects);

    const miscFilters = fieldsToHtml(
        cloneDeep(filterFieldsValues)
            .filter((it) => it.type !== 'checkbox' && it.name !== 'query' && it.name !== 'limit')
            .map(it => {
                it.metadata.addContainerClass = it.metadata.miscContainerClass ?? it.metadata.addContainerClass
                return it
            })
        , translate, handleInputChange, filterSelects);

    const largerGrid = miscFilters.length >= 4;

    const checkboxFilters = fieldsToHtml(filterFieldsValues.filter((it) => it.type === 'checkbox'), translate, handleInputChange);

    const numberOfFilters = miscFilters.length + checkboxFilters.length;

    return (
        <div className={className ?? "md:px-5 py-5 flex relative z-[31]"}>
            <div className={containerClass ?? "xl:grid grid-cols-3 gap-2 hidden"}>

                {largeScreenFilters}
                {customHeaderHtml}
            </div>

            <div className="xl:hidden">
                {!hideSearch && largeScreenFilters[0]}
            </div>

            <div className="ml-auto flex space-x-2">
                {numberOfFilters > 0 && !hideFilterPopover && (
                    <PopOver
                        btnClass={classNames("form-control border border-tm-gray-600 rounded-full p-0 w-auto text-tm-gray-700 flex items-center flex h-fit", numberOfFilters < 3 ? "xl:hidden" : "")}
                        widthClass={largerGrid ? "max-w-2xl" : "max-w-lg"}
                        positionClass="absolute translate-x-0 right-0"
                        btnCustomHtml={(
                            <React.Fragment>
                                <div className="py-2 px-3 text-xs">
                                    <span className="hidden 2xl:inline">All</span> Filters
                                </div>

                                <span className="h-5 w-px bg-secondary"/>

                                <div className="py-2 px-2">
                                    <ChevronDownIcon className="w-5 h-5"/>
                                </div>
                            </React.Fragment>
                        )}
                    >
                        <div className="bg-popup p-6">
                            <div
                                className={classNames("grid gap-4", largerGrid && !!checkboxFilters.length ? "grid-cols-3" : "grid-cols-2")}>
                                {customHtml}

                                <div className={largerGrid && !!checkboxFilters.length ? "col-span-1" : ""}>
                                    {checkboxFilters}
                                </div>

                                <div
                                    className={classNames(!!largerGrid ? "grid gap-4 col-span-2" : "space-y-4", !checkboxFilters.length ? "col-span-2" : "", (addMiscFiltersClass ?? ""))}>
                                    {miscFilters}
                                </div>
                            </div>
                        </div>
                    </PopOver>
                )}

                {!hideLimit && (
                    <div className="relative w-24">
                        <span
                            className="label text-tm-gray-900 font-semibold absolute -top-2.5 left-2.5 bg-inverse px-1 text-xs z-10">
                            {translate("field.limit")}
                        </span>

                        <FieldSelectSearch
                            addClass="form-control"
                            values={filterSelects["limit"]}
                            onChange={handleInputChange}

                            {...filterFields.limit}
                            placeholder={""}
                            omitSort
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const getDefaultSelects = (filterFields, translate) => ({
    limit: queryLimit,
})
