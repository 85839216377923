const downloadStackedReducer = (state = {}, action) => {
    switch (action.type) {
        case 'DOWNLOAD_STACKED':
            return Object.assign({}, state, {
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.key ?? action.data.resource]: true}),
                error: false,
                errorMessage: null
            });
        case 'DONE_DOWNLOAD_STACKED':
            return Object.assign({}, state, {
                data: Object.assign(state.data ? state.data : {}, {[action.data.key ?? action.data.resource]: action.data.data}),
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.key ?? action.data.resource]: false}),
                error: false,
                errorMessage: null
            });
        case 'ERROR_DOWNLOAD_STACKED':
            return Object.assign({}, state, {
                data: null,
                isLoading: Object.assign(state.isLoading ? state.isLoading : {}, {[action.data.key ?? action.data.resource]: false}),
                error: true,
                errorMessage: action.data?.data
            });
        default:
            return state;
    }
};

export default downloadStackedReducer;
