import {call, put, takeEvery, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util.js";
import {
    doneCreateStackedResource, doneDeleteStackedResource,
    doneGetStackedResource, doneUpdateStackedResource,
    errorCreateStackedResource, errorDeleteStackedResource,
    errorGetStackedResource, errorUpdateStackedResource
} from "../actions/stackedResource";
import {pushNotification} from "../actions/ui";

export function* getStackedResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetStackedResource({resource: action.data.resource, data: result.data}));
    } else {
        yield put(errorGetStackedResource({resource: action.data.resource, data: result.data}));
    }
}

export function* watchGetStackedResource() {
    yield takeEvery('GET_STACKED_RESOURCE', getStackedResourceCall);
}

export function* createResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.onSuccessCallback && result) {
            action.data.onSuccessCallback(result)
        }
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetStackedResource({resource: action.data.piggyResource, data: resPiggy.data}));
                yield put(doneCreateStackedResource({resource: action.data.resource, data: result.data}));
            }
        } else {
            yield put(doneCreateStackedResource({resource: action.data.resource, data: result.data}));
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorCreateStackedResource({resource: action.data.resource, data: result.data}));
    }
}

export function* watchCreateStackedResource() {
    yield takeLatest('CREATE_STACKED_RESOURCE', createResourceCall);
}

export function *updateResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (action.data.onResultCallback && result) {
        action.data.onResultCallback(result)
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetStackedResource({resource: action.data.piggyResource, data: resPiggy.data}));
                yield put(doneUpdateStackedResource({resource: action.data.resource, data: result.data}));
            }
        } else {
            yield put(doneUpdateStackedResource({resource: action.data.resource, data: result.data}));
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorUpdateStackedResource({resource: action.data.resource, data: result.data}));
    }
}

export function* watchUpdateStackedResource() {
    yield takeLatest('UPDATE_STACKED_RESOURCE', updateResourceCall);
}

export function* deleteResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.onSuccessCallback && result) {
            action.data.onSuccessCallback(result)
        }
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetStackedResource({resource: action.data.piggyResource, data: resPiggy.data}));
                yield put(doneDeleteStackedResource({resource: action.data.resource, data: result.data}));
            }
        } else {
            yield put(doneDeleteStackedResource({resource: action.data.resource, data: result.data}));
        }
        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;

        yield put(pushNotification(action));
        yield put(errorDeleteStackedResource({resource: action.data.resource, data: result.data}));
    }
}

export function* watchDeleteStackedResource() {
    yield takeLatest('DELETE_STACKED_RESOURCE', deleteResourceContactCall);
}
