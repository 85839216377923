import React, {Component} from "react";
import Async from "react-select/async";
import {getCustomStyles} from "../../data/themes";
import {scrollIntoView} from "../../common/util/util-vanilla";
import ErrorFieldMessage from "../error-field-message";
import {classNames} from "../../common/util/util-helpers";

export default class FieldDropdownSelect extends Component {

    render() {
        const pairs = this.props.values ? this.props.values : {};

        return (
            <React.Fragment>
                <Async
                    autoFocus={this.props.autoFocus}
                    ref={this.props.innerRef}
                    styles={this.props.customStyles ?? getCustomStyles()}
                    className={classNames("select-css-search p-0 " ,
                        (!!this.props.addClass ? this.props.addClass : '') ,
                        (this.props.errorMessage ? "border-red-300 text-red-900" : ""))}
                    isDisabled={!!this.props.disabled}
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption);
                    }}
                    name={this.props.name}
                    value={!this.props.loadOptions ? (this.props.value ? {
                        value: this.props.value,
                        label: pairs[this.props.value]
                    } : null) : this.props.value}
                    options={this.props.options}
                    loadOptions={this.props.loadOptions}
                    defaultOptions={!!this.props.defaultOptions}
                    isClearable={!!this.props.isClearable}
                    isMulti={this.props.multi}
                    placeholder={this.props.placeholder ? this.props.placeholder : "Select..."}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                    key={this.props.key}
                    menuPlacement={this.props.menuPlacement}
                    menuPortalTarget={this.props.hasPortal ? document.body : null}
                />

                <ErrorFieldMessage
                    errorMessage={this.props.errorMessage}
                    translate={this.props.translate}
                />
            </React.Fragment>
        )
    }
}
