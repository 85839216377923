import LocalStorage from "../../util/localStorage";
import moment from "moment-timezone";
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_DATABASE_TIMEZONE,
    DEFAULT_FRONTEND_DATETIME_FORMAT, DEFAULT_FRONTEND_TIMEZONE
} from "../../util/util-constants";
import {getProp} from "./util-helpers";

export function currentDate() {
    return moment().format(getProp(LocalStorage.get('user'), "Contact.DateTimeFormat", "MM/DD/YYYY"));
}

export function toLocalTimeFromUTC(date) {
    if (!date) return "";

    return moment.utc(date, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(DEFAULT_FRONTEND_DATETIME_FORMAT).format(DEFAULT_DATABASE_DATETIME_FORMAT);
}

export function toFrontDateTimeFromUTC(dateEntered) {
    let timezone = '';
    if (getProp(LocalStorage.get('user'), 'Contact.Timezone', '')) {
        timezone = getProp(LocalStorage.get('user'), 'Contact.Timezone', '')
    } else {
        timezone = "America/Los_Angeles" // PST
    }
    return (dateEntered ? moment.utc(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(timezone).format(LocalStorage.get('user').Contact.DateTimeFormat) : "");
}

export function toFrontTimeFromUTC(dateEntered) {
    let format = LocalStorage.get('user')?.Contact.DateTimeFormat.split(" ")
    format.shift()
    format = format.join(" ")
    return (dateEntered ?
            moment
                .utc(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE)
                .tz(DEFAULT_DATABASE_TIMEZONE)
                .format(format)
            :
            ""
    );
}

export function toFrontDateTime(dateEntered) {
    return (dateEntered ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format(LocalStorage.get('user').Contact.DateTimeFormat) : "");
}

export function toFrontDate(dateEntered) {
    return ((dateEntered && dateEntered > "1900-01-01 00:00:00") ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format(LocalStorage.get('user').Contact.DateTimeFormat.split(" ")[0]) : "");
}

export function toFrontDayMonth(dateEntered) {
    return ((dateEntered && dateEntered > "1900-01-01 00:00:00") ? moment(dateEntered).format("MMMM Do") : "");
}

export function toBackDateTime(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000" || dateEntered === "1900-01-01 00:00:00") {
        return "no data available";
    }
    return (dateEntered ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format(DEFAULT_DATABASE_DATETIME_FORMAT) : "no data available");
}

export function toBackDate(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000") {
        return "no data available";
    }
    return (dateEntered ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format("YYYY-MM-DD") : "no data available");
}


export function enumerateDaysBetweenDates(startDate, endDate) {
    let dates = [];
    startDate = moment.utc(startDate, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE)
        .tz(DEFAULT_DATABASE_TIMEZONE)
    endDate = moment.utc(endDate, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE)
        .tz(DEFAULT_DATABASE_TIMEZONE)
    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().toDate());
    }
    return dates;
}

export function isMilitaryTimeSet() {
    if (LocalStorage.get('user')?.Contact?.DateTimeFormat) {
        return LocalStorage.get('user')?.Contact.DateTimeFormat.slice(-1) !== "A"
    }
}

export function getUserTimeFormat() {
    if (isMilitaryTimeSet()) {
        return "HH:mm"
    } else {
        return "hh:mma"
    }
}

export const timeFromUTCToFront = date => {
    if (date) {
        return moment.utc(date, DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_DATABASE_TIMEZONE).tz(DEFAULT_FRONTEND_TIMEZONE).format(getUserTimeFormat())
    }

    return date
}

export function isTodayDate(date, translate) {
    let value = moment(date).isSame(moment(), 'day')
    if (!!value) {
        let tmp = toFrontDateTimeFromUTC(date).split(" ")
        tmp.shift()
        tmp = tmp.join(" ")
        return `${translate("text.Today_at")} ${tmp}`
    } else {
        return toFrontDateTimeFromUTC(date)
    }
}
