import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import LocalStorage from './util/localStorage'
import NotFoundView from "./views/not-found";
import AdminView from "./views/admin";
import LoginView from "./views/login";
import LoggingInView from "./views/logging-in-view";
import NurseDashboardView from "./views/nurse-dashboard";
import AdminReportsView from "./views/reports";
import ServicemanReportsView from "./views/serviceman-reports";
import ResetPasswordView from "./views/reset-password-view";
import ResetPasswordConfirmView from "./views/reset-password-confirm-view";

const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

const PublicRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/logging-in",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

class RouterListener extends React.Component {

    constructor(props) {
        super(props);

        this.unlisten = null;
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {});
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default (store, translator) => {

    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <RouterListener history={history} dispatch={store.dispatch} {...store.getState()}
                            translate={(key, args) => translator.translate(key, args)}>
                <Switch>
                    <PublicRoute exact path="/" component={(props) => getComponent(LoginView, props)}/>
                    <PublicRoute exact path="/login" component={(props) => getComponent(LoginView, props)}/>
                    <PrivateRoute exact path="/logging-in" component={(props) => getComponent(LoggingInView, props)}/>

                    <PrivateRoute exact path="/dashboard" component={(props) => getComponent(NurseDashboardView, props)}/>
                    <PrivateRoute exact path="/home" component={(props) => getComponent(NurseDashboardView, props)}/>
                    <PrivateRoute exact path="/reports" component={(props) => getComponent(AdminReportsView, props)}/>
                    <PrivateRoute exact path="/serviceman-reports" component={(props) => getComponent(ServicemanReportsView, props)}/>

                    <PrivateRoute exact path="/admin" component={(props) => getComponent(AdminView, props)}/>

                    <PublicRoute exact path="/reset-password"
                                 component={(props) => getComponent(ResetPasswordView, props)}/>
                    <NeutralRoute exact path="/reset-password-confirm/:email/:token/:is_new"
                                  component={(props) => getComponent(ResetPasswordConfirmView, props)}/>

                    <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
                </Switch>
            </RouterListener>
        </Router>
    );
}
