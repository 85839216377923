export function hideModals() {
    return {
        type: 'HIDE_MODALS',
        data: {}
    };
}

export function pushNotification(data) {
    return {
        type: 'PUSH_NOTIFICATION',
        data: data
    }
}

export function hideNotification(data) {
    return {
        type: 'HIDE_NOTIFICATION',
        data
    }
}

export function destroyNotification(data) {
    return {
        type: 'DESTROY_NOTIFICATION',
        data
    }
}

export function closeNotificationDelayed(data) {
    return {
        type: 'CLOSE_NOTIFICATION_DELAY',
        data
    }
}
