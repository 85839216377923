const uiReducer = (state = {}, action) => {
    switch (action.type) {
        case 'PUSH_NOTIFICATION':
            const defaultMessageDuration = 2;
            let newMessageCount = state.messagesCount ? state.messagesCount + 1 : 1;
            let messageDuration = action.data.data.messageDuration === 0 || action.data.data.messageDuration ? action.data.data.messageDuration : defaultMessageDuration;
            let newMessagesArray = state && state.messages ? state.messages : [];
            const color = action.data.data.color ? action.data.data.color : "primary";
            const messageType = action.data.data.type ? action.data.data.type : 0;
            //let storedMessages = Array.from(state.storedMessages ? state.storedMessages : [])

            newMessagesArray.push({
                id: newMessageCount,
                message: action.data.data.notificationMessage ? action.data.data.notificationMessage : action.data.type,
                duration: messageDuration,
                isError: action.data.data.error,
                actionType: action.data.type,
                squared: action.data.data.squared,
                color: color,
                messageType: messageType,
                data: action.data.data.data,
                customErrorMessage: action.data.data?.customErrorMessage
            })

            return Object.assign({}, state, {
                messagesCount: newMessageCount,
                messageDuration: messageDuration,
                messages: newMessagesArray,
                unreadCount: action.data.data.unreadCount
            })
        case 'HIDE_NOTIFICATION':
            if (!state.messages) {
                return state;
            }

            let updatedNotifications = state.messages.map(notification => {
                if (notification.id === action.data) {
                    notification.hidden = true;
                }

                return notification;
            })

            return Object.assign({}, state, {
                messages: updatedNotifications,
            });
        case 'DESTROY_NOTIFICATION':
            if (!state.messages) {
                return state;
            }

            let newNotifications = state.messages.map(notification => {
                if (notification.id === action.data) {
                    notification.destroy = true;
                }

                return notification;
            })

            const hiddenNotifications = newNotifications.filter(notification => notification.destroy)

            if (hiddenNotifications.length === newNotifications.length) {
                newNotifications = []
            }

            return Object.assign({}, state, {
                messages: newNotifications,
            });
        default:
            return state;
    }
};

export default uiReducer;
