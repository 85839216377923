import { delay, takeEvery, select, put } from "redux-saga/effects";
import {destroyNotification, hideNotification} from "../actions/ui";

export const getMessagesCount = (state) => state.ui.messagesCount;
export const getMessageDuration = (state) => state.ui.messageDuration;

export function* pushNotificationCall() {
    let messageID = yield select(getMessagesCount);
    const messageDuration = yield select(getMessageDuration);

    if (!!messageDuration) { // 0 for infinite duration
        yield delay((messageDuration * 1000));
        yield put(hideNotification(messageID));

        yield delay(5000);

        yield put(destroyNotification(messageID));
    }
}

export function* watchPushNotification() {
    yield takeEvery('PUSH_NOTIFICATION', pushNotificationCall);
}

export function* closeNotificationDelayCall(notificationID) {
    yield delay(1000);

    yield put(hideNotification(notificationID.data));
}

export function* watchCloseNotificationDelay() {
    yield takeEvery('CLOSE_NOTIFICATION_DELAY', closeNotificationDelayCall);
}
