import {getProp} from "../../common/util/util-helpers";

export default {
    light: {
        white: "#fff",
        primary: "#3a3a3a",
        light: "#f8f8f8",
        muted: "#6b7280",
        border: "#d7d7d7",
        tableBorder: "#f3f3f3",
        link: "#236591",
        button: "#00365A",
        buttonHover: "#001e31",
        icon: "#8f8f8f",
        input: "#fff",
        inputDarker: "#E6E6E6",
        disabled: "#e9ecef",
        inverseRGB: "rgba(255,255,255,1)",
        inverseRGBTransparent: "rgba(255,255,255,0)"
    },
    dark: {
        white: "#1e2022",
        primary: "#8f8f8f",
        light: "#18191b",
        muted: "#696969",
        border: "#424242",
        tableBorder: "#313131",
        link: "#236591",
        button: "#236591",
        buttonHover: "#1b4e70",
        icon: "#707070",
        input: "#232223",
        inputDarker: "#1E2022",
        disabled: "#e9ecef",
        inverseRGB: "rgba(30,32,34,1)",
        inverseRGBTransparent: "rgba(30,32,34,0)"
    }
}

export function getCustomStyles() {
    return {
        indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: "var(--color-secondary)",
            // transition: ".3s background"
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            color: "var(--color-gray-900)",
            "&:hover": {
                cursor: "pointer"
            }
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isDisabled ? "var(--color-gray-200)" : "var(--color-gray-700)"
        }),
        container: (provided, state) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isFocused ? '#fff' : '',
            fontWeight: state.isSelected ? 700 : '',
            background: state.isFocused ? "#00365a" : getProp(state, 'data.background', "transparent"),
            cursor: "pointer",
            '&:hover': {
                color: '#fff',
                background: "#00365a"
            },
            padding: "3px 10px"
        }),
        menu: (provided, state) => ({
            ...provided,
            background: "var(--color-field)",
            color: "var(--color-gray-900)",
            fontSize: "0.8rem",
            zIndex: 11111,
            borderRadius: 10,
            width: "100%",
            marginTop: "0px"
        }),
        menuPortal: base => ({ ...base, zIndex: 100 }),
        control: (provided, state) => ({
            ...provided,
            transition: "none",
            backgroundColor: state.isDisabled ? "var(--color-gray-400)" : "var(--color-field)",
            color: state.isDisabled ? "var(--color-gray-50)" : "var(--color-gray-900)",
            minHeight: "32px",
            // This line disable the blue border
            boxShadow: state.isFocused ? "0 0 0 1px var(--color-link)" : 0,
            borderRadius: 9999
        }),
        input: (provided, state) => ({
            color: "var(--color-tm-gray-900)"
        }),
        singleValue: (provided, state) => ({
            color: "var(--color-tm-gray-900)",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "calc(100% - 2px)",
        }),
        multiValue: (provided, state) => {
            let backgroundColor = "var(--color-secondary-light)";
            let color = "var(--color-tm-gray-900)";
            if (state.data.manual) {
                backgroundColor = "var(--color-link)";
                color = "var(--color-inverse)";
            }

            if (state.data.invalid) {
                backgroundColor = "rgb(220, 38, 38)";
            }

            return ({
                ...provided,
                color: color,
                background: backgroundColor
            })
        },
        multiValueLabel: (provided, state) => {
            return ({
                ...provided,
                color: "inherit",
                background: "transparent",
                paddingTop: 0,
                paddingBottom: 0
            })
        },
        multiValueRemove: (provided, state) => ({
            ...provided,
            background: "transparent",
            "&:hover": {
                cursor: "pointer"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "0.25rem 0.75rem;"
        }),
    }
}

export function getCondensedCustomStyles() {
    return {
        indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: "var(--color-secondary)",
            // transition: ".3s background"
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            color: "var(--color-gray-900)",
            "&:hover": {
                cursor: "pointer"
            },
            padding: "0 3px"
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isDisabled ? "var(--color-gray-200)" : "var(--color-gray-700)",
            padding: "3px 5px"
        }),
        container: (provided, state) => ({
            ...provided,
            borderRadius: 0,
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isFocused ? '#fff' : '',
            fontWeight: state.isSelected ? 700 : '',
            background: state.isFocused ? "#00365a" : "transparent",
            cursor: "pointer",
            '&:hover': {
                color: '#fff',
                background: "#00365a"
            }
        }),
        menu: (provided, state) => ({
            ...provided,
            background: "var(--color-field)",
            color: "var(--color-gray-900)",
            zIndex: 11,
            width: "100%",
            marginTop: "0px"
        }),
        menuPortal: base => ({ ...base, zIndex: 100 }),
        control: (provided, state) => ({
            ...provided,
            transition: "none",
            borderRadius: 0,
            backgroundColor: state.isDisabled ? "var(--color-gray-400)" : "var(--color-field)",
            minHeight: "28px",
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? "0 0 0 1px var(--color-link)" : 0,
            '&:hover': {
                border: state.isFocused ? "var(--color-secondary)" : 0
            }
        }),
        input: (provided, state) => ({
            color: "var(--color-tm-gray-900)"
        }),
        singleValue: (provided, state) => ({
            color: "var(--color-tm-gray-900)",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "calc(100% - 2px)",
        }),
        multiValue: (provided, state) => {
            let backgroundColor = "var(--color-secondary-light)";
            let color = "var(--color-tm-gray-900)";
            if (state.data.manual) {
                backgroundColor = "var(--color-link)";
                color = "var(--color-inverse)";
            }

            if (state.data.invalid) {
                backgroundColor = "rgb(220, 38, 38)";
            }

            return ({
                ...provided,
                color: color,
                background: backgroundColor
            })
        },
        multiValueLabel: (provided, state) => {
            return ({
                ...provided,
                color: "inherit",
                background: "transparent",
                paddingTop: 0,
                paddingBottom: 0
            })
        },
        multiValueRemove: (provided, state) => ({
            ...provided,
            background: "transparent",
            "&:hover": {
                cursor: "pointer"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "0 0.75rem;"
        }),
    }
}

export function getSelectGroupStyles() {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        color: "var(--color-link)",
        paddingBottom: ".5em",
        marginBottom: ".5em",
        borderBottom: "1px solid var(--color-secondary)"
    };
}


export function getSelectGroupBadgeStyles() {
    return {
        backgroundColor: "var(--color-link)",
        borderRadius: '2em',
        color: "var(--color-inverse)",
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
}
