import React, {useEffect, useState} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import LoaderSmall from "../loader-small";

const PaginationNew = ({count, queryFields, pageLimit, pageOffset, handleQueryChange, isLoading, translate}) => {
    const limit = pageLimit ?? queryFields?.limit?.value;
    const offset = pageOffset ?? queryFields?.offset?.value;

    let pagesNum = Math.ceil(count / limit);
    let currentPage = Math.round(offset / limit + 1);

    const from = Number(offset) + 1;
    const to = currentPage === pagesNum ? count : Number(offset) + Number(limit)

    const handleFocus = (e) => {
        if (!!e) {
            e.setSelectionRange(0, e.value.length)
        }
    }

    const handleKeyDown = (e) => {
        if (isNaN(e.target.value)) {
            return false
        }

        if (e.key === 'Enter') {
            if (e.target.value > pagesNum || e.target.value < 1 || e.target.value == currentPage) {
                setPageInputValue(currentPage);
                return false;
            }

            handleQueryChange("offset", (e.target.value - 1) * limit)
        }
    }

    const handleInputChange = (e) => {
        if (isNaN(e.target.value)) {
            return false;
        }

        setPageInputValue(e.target.value);
    }

    const [pageInputValue, setPageInputValue] = useState(currentPage);

    useEffect(() => {
        setPageInputValue(offset / limit + 1);
    }, [offset, limit]);

    return (
        <div className="flex sm:items-center w-full">
            <p className="text-sm text-tm-gray-700 mr-auto">
                {!isLoading && !!count && (
                    translate("text.pagination_text", [from, to, count])
                )}

                {isLoading && (
                    <LoaderSmall disableContainer/>
                )}
            </p>

            <div className="ml-auto flex items-center gap-x-4">
                <div className="relative z-0 inline-flex -space-x-px">
                    <button
                        disabled={currentPage === 1 || isLoading}
                        onClick={() => (currentPage > 1) ? handleQueryChange("offset", (currentPage - 2) * limit, currentPage) : null}
                        className="btn btn-outline-secondary border border-tm-gray-300 px-2 rounded-r-none z-10"
                    >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="w-5 h-5"/>
                    </button>

                    <input
                        className="form-control rounded-none w-12 text-right px-1.5 relative focus:z-20"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        // onFocus={handleFocus}
                        value={pageInputValue}
                    />

                    <div className="relative">
                        <input
                            disabled={true}
                            className="form-control rounded-none w-20 text-right px-1.5"
                            value={isLoading ? "" : translate("text.pagination_of", [pagesNum])}
                        />

                        {isLoading && (
                            <LoaderSmall/>
                        )}
                    </div>

                    <button
                        disabled={currentPage >= pagesNum || isLoading}
                        onClick={() => currentPage < pagesNum ? handleQueryChange("offset", currentPage * limit, currentPage) : null}
                        className="btn btn-outline-secondary border border-tm-gray-300 px-2 rounded-l-none"
                    >
                        <span className="sr-only">Next</span>

                        <ChevronRightIcon className="w-5 h-5"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PaginationNew
