import React from 'react';
import {classNames} from "../../../common/util/util-helpers";

const PageFooter = ({
                                         actionSubmit,
                                         actionSubmitLabel,
                                         canSubmit,
                                         translate,
                                         actionCancel,
                                         customActionsHtml,
                                         customHtml
}) => {
    return (
        <div className={'h-16 w-full'}>
            <div
                className={classNames("w-full", "z-40 h-16 flex justify-between items-center gap-3 fixed bottom-0 right-0 px-8 bg-inverse border-tm-gray-300 border-t")}>
                <div>
                    {customHtml}
                </div>
                {customActionsHtml}
                <div>
                    {actionSubmit && (
                        <button
                            className="btn btn-primary"
                            disabled={!canSubmit}
                            onClick={canSubmit ? actionSubmit : null}>
                            {actionSubmitLabel ?? translate('btn.save')}
                        </button>
                    )}

                    {actionCancel && (
                        <button
                            className="btn btn-outline ml-3"
                            disabled={!canSubmit}
                            onClick={canSubmit ? actionCancel : null}>{translate('btn.cancel')}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PageFooter
