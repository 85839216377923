import React, {useEffect, useState} from "react";
import {checkPasswordStrength} from "../../common/util/util-vanilla";

const PasswordStrengthMeter = ({password}) => {

    const [background, setBackground] = useState('')
    const [label, setLabel] = useState('')
    const [percentage, setPercentage] = useState(0)

    useEffect(() => {
        setBackground(checkPasswordStrength(password).color)
        setLabel(checkPasswordStrength(password).strength)
        setPercentage(checkPasswordStrength(password).passed * 20)
    }, [password])

    return (
        <>
           <div className="progress bg-tm-gray-300" style={{height: "7px"}}>
              <div className="progress-bar"
                 style={{width: `${percentage}%`, background: background, height: "7px"}}></div>
              </div>
              
              <p style={{color: background}}>{label}</p>
        </>
    )
}

export default PasswordStrengthMeter
