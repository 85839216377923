import { toFrontDate, toFrontDateTime, toFrontDateTimeFromUTC } from "../../common/util/util-dates";
import { CheckCircleIcon } from "@heroicons/react/solid";
import XIcon from "@heroicons/react/solid/XIcon";
import { getLookup } from "../../common/util/util-helpers";
import React from "react";
import { CrudActions } from "./index";
import { numberWithCommas } from "../../util/util-formaters";
import {genericMoneyFormatter} from "../../common/util/util-vanilla";
import Tippy from "@tippyjs/react";

export const fieldToCell = (fields, name, item) => {
    let field = fields[name];
    let value = item[name];

    if (!!fields[name]?.metadata?.render) {
        return fields[name].metadata.render(item);
    }

    switch (field?.type) {
        case 'hidden':
            return null;
        case 'date':
            return toFrontDate(value);
        case 'money':
            return genericMoneyFormatter(value);
        case 'datetime':
            return toFrontDateTime(value);
        case 'datetimezone':
            return toFrontDateTimeFromUTC(value);
        case 'checkbox':
            if (typeof value == "string") {
                value = value === "1"
            } else {
                value = !!value
            }
            // yes, no, empty, customize labels and icons (make default imports)
            return value ?
                <div className="flex justify-center">
                    <CheckCircleIcon className="w-5 h-5 text-green-600" />
                </div> :
                <div className="flex justify-center">
                    <XIcon className="w-4 h-4 text-tm-gray-400"/>
                </div>
        case 'select':
            if (fields[field.name]?.props?.values) {
                return fields[field.name].props.values[value]
            } else {
                return getLookup(field.name.replace("ID", ""))[value]
            }
        case 'select-search':
            if (fields[field.name]?.metadata?.labelField) {
                return item[fields[field.name]?.metadata?.labelField]
            } else {
                return item[name.replace("ID", "")];
            }
        case 'float':
            return <div className="text-right">{numberWithCommas(value)}</div>
        case 'integer':
            return <div className="text-right">{value ? value.toString().replace(/\D/, '') : ''}</div>
        default:
            return value;
    }
}

export const getSkeletonData = (fields, limit) => {
    const columns = Object.keys(fields);
    const rows = [...Array(Number(limit)).keys()];

    return rows.map(() => {
            let min = 0;
            let max = columns.length - 1;

            return columns.reduce((memo, it) => {
                const randomColumnHeader = columns[Math.floor(Math.random() * (max - min + 1) + min)];
                memo[it] = randomColumnHeader + randomColumnHeader;
                return memo
            }, {});
        }
    );
}

export const getLabel = (fieldName, columnsData, translate) => {
    return !!columnsData[fieldName]?.label ? columnsData[fieldName].label : translate("field." + fieldName)
}

export const ActionsWidth = React.forwardRef(({translate, hasDefaultActions, actions, paddingClass}, ref) => {
    return (
        <div
            ref={ref}
            className={"invisible flex absolute whitespace-nowrap space-x-2 right-0 -top-10" + " " + paddingClass}
        >
            {/* Invisible actions, for max width calculation only */}
            {hasDefaultActions && (
                <CrudActions
                    onView={() => null}
                    onEdit={() => null}
                    onDelete={() => null}
                    item={[]}
                    translate={translate}
                />
            )}

            {!!actions && actions.map((Button, btnIndex) => {
                return (
                    <div
                        key={btnIndex}
                        onClick={null}
                        className={!!Button.class ? Button.class : "btn-table-action"}
                    >
                        {!!Button.icon && (
                            <Button.icon
                                className={!!Button.iconClass ? Button.iconClass : "w-4 h-4"}
                            />
                        )}

                        {typeof Button.label === "function" ? () => Button.label() : Button.label}
                    </div>
                )
            })}
        </div>
    )
});

export const TableActions = ({hasDefaultActions, actions, item, rowIndex, onView, onEdit, onDelete, onRestore, translate, isLoading}) => {
    return (
        <React.Fragment>
            {hasDefaultActions && (
                <CrudActions
                    item={item}
                    rowIndex={rowIndex}
                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onRestore={onRestore}
                    translate={translate}
                    isLoading={isLoading}
                />
            )}

            {!!actions && actions.filter(Button => Button.visible === undefined || Button.visible(item, rowIndex)).map((Button, btnIndex) => {
                if (Button.customButton) {
                    return Button.customButton(item, rowIndex)
                }
                return (
                    <Tippy content={typeof Button.title === "function" ? () => Button.title(item, rowIndex) : Button.title}>
                        <button
                            disabled={isLoading}
                            key={btnIndex}
                            onClick={() => !!Button.action && Button.action(item, rowIndex)}
                            className={!!Button.class ? Button.class : "btn-table-action"}
                        >
                            {!!Button.icon && (
                                <Button.icon
                                    className={!!Button.iconClass ? Button.iconClass : "w-4 h-4"}
                                />
                            )}

                            {typeof Button.label === "function" ? () => Button.label(item, rowIndex) : Button.label}
                        </button>
                    </Tippy>
                )
            })}
     </React.Fragment>
    )
}
