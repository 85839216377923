import React from "react";
import { getFieldContainerClass, getFieldLabelClass } from "../../util/util-fields";
import { classNames } from "../../common/util/util-helpers";

export default function FieldContainer({item, children, translate}) {
    let itemLabel = generateLabel(item, translate);

    return (
        <React.Fragment key={item.name}>
            {!!item?.metadata?.htmlBefore && item.metadata.htmlBefore(item)}

            <div
                className={getFieldContainerClass(item?.metadata?.addContainerClass, item.metadata?.labelType)}
            >
                <label
                    htmlFor={item?.props?.id ?? item.name}
                    className={getFieldLabelClass(item.metadata)}
                >
                    {!item?.metadata?.hideLabel && (
                        itemLabel
                    )}

                    {!!item?.metadata?.fieldOptions && (
                        <span className={item?.metadata?.fieldOptionsClassName ?? ""}>
                            {item.metadata.fieldOptions(item)}
                        </span>
                    )}
                </label>

                <div className={classNames("flex", item?.metadata?.addFieldContainerClass)}>
                    {!!item?.metadata?.htmlBeforeField && item.metadata.htmlBeforeField(item)}

                    <div className="flex-1 max-w-full">
                        {children}
                    </div>

                    {!!item?.metadata?.htmlAfterField && item.metadata.htmlAfterField(item)}
                </div>


                {!!item?.metadata?.note && (
                    <div className="mt-1">
                        {typeof item.metadata.note === "function" && item.metadata.note(item)}

                        {typeof item.metadata.note !== "function" && item.metadata.note}
                    </div>
                )}
            </div>

            {!!item?.metadata?.htmlAfter && item.metadata.htmlAfter(item)}
        </React.Fragment>
    )
}

const generateLabel = (item, translate) => {
    const req = (item.validate.includes("empty") || item.validate.find(it => it.includes("length_")) || item.validate.includes("empty_select_search") || item.validate.includes("integer") || item.validate.includes("float"));
    let itemLabel;

    if (!!item?.metadata?.labelRaw) {
        itemLabel = item.metadata.labelRaw + (req ? " *" : "");
    } else {
        itemLabel = (!!item?.metadata?.label) ? (translate("field." + item?.metadata?.label) + " " + (req ? "*" : "")) : (translate("field." + item.name) + " " + (req ? "*" : ""))
    }

    if (item?.metadata?.hideLabel) {
        itemLabel = "";
    }

    return itemLabel;
}
