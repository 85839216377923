import React from "react";
import ExclamationIcon from "@heroicons/react/outline/ExclamationIcon";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import {classNames} from "../../common/util/util-helpers";
import PencilAltIcon from "@heroicons/react/outline/PencilAltIcon";
import QuestionMarkCircleIcon from "@heroicons/react/outline/QuestionMarkCircleIcon";
import {CheckCircleIcon} from "@heroicons/react/solid";

export default function InfoParagraph({type, message, children, onClick, className, style}) {

    const getTypeClasses = () => {
        switch (type) {
            case "success":
                return "border-green-600"
            case "info":
                return "border-blue-600"
            case "danger":
                return "border-red-600"
            case "warning":
                return "border-yellow-600"
            case "note":
                return "border-secondary"
            case "help":
                return "border-link rounded-md"
            default:
                return "border-link"
        }
    }

    const getTypeIconClasses = () => {
        switch (type) {
            case "success":
                return "bg-green-600 text-white"
            case "info":
                return "bg-blue-600 text-white"
            case "danger":
                return "bg-red-600 text-white"
            case "warning":
                return "bg-yellow-600 text-white"
            case "note":
                return "bg-transparent text-tm-gray-700"
            case "help":
                return "bg-transparent text-link"
            default:
                return "bg-link text-tm-gray-700"
        }
    }

    const getTypeIcon = () => {
        switch (type) {
            case "success":
                return <CheckCircleIcon className="h-6 w-6"/>
            case "info":
                return <InformationCircleIcon className="h-6 w-6"/>
            case "danger":
                return <ExclamationIcon className="h-6 w-6"/>
            case "warning":
                return <InformationCircleIcon className="h-6 w-6"/>
            case "note":
                return <PencilAltIcon className="h-6 w-6"/>
            case "help":
                return <QuestionMarkCircleIcon className="h-6 w-6"/>
            default:
                return <InformationCircleIcon className="h-6 w-6"/>
        }
    }

    return (
        <div
            onClick={() => !!onClick ? onClick() : null}
            className={"flex" + (!!onClick ? " cursor-pointer" : "")}
            style={style}
        >

            <div className={classNames(
                "bg-tm-gray-100 text-tm-gray-900 border flex items-center flex-grow rounded-md",
                className ? " " + className : "",
                getTypeClasses()
            )}
            >
                <div
                    className={classNames("px-1 p-2 h-full flex items-start justify-center rounded-l-md", getTypeIconClasses())}>
                    {getTypeIcon()}
                </div>

                <div className="p-2 text-current w-full">
                    {message}

                    {children}
                </div>
            </div>
        </div>
    )
}
