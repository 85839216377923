import React from "react";
import {XIcon} from "@heroicons/react/outline";
import Badge from "../badge";

export default function ActiveFilters(props) {
    const {clearButtonLabel, translate, filterFields, onLabelClick, excludedFields = ['limit']} = props;

    const activeFilters = Object.values(filterFields).filter(it => {
        return !excludedFields.includes(it.name) && (it.value === 0 || !!it.value);
    });

    const filterLabels = activeFilters
        .filter((item) => !item?.metadata?.isRequiredFilter)
        .map((item, i) => {

            return (
                <div
                    key={i}
                    className="sm:mt-0">
                    <div className="flex flex-wrap items-center">
                        <Badge
                            onClick={() => onLabelClick(item.name, "", "active-filters")}
                            type="custom"
                            addClass={`bg-inverse cursor-pointer border border-tm-gray-300 flex items-center space-x-2 hover:bg-tm-gray-100`}
                        >
                            <span>{item.metadata.labelRaw ?? translate(`text.${item?.metadata?.label ? item.metadata.label : item.name}`)}</span>
                            <XIcon className={'h-2 w-2'}/>
                        </Badge>
                    </div>
                </div>
            )
        })

    const clearButtonLabelText = clearButtonLabel ?? translate("text.ClearAll")

    return (
        !!activeFilters.length && (
            <div
                className="px-1 sm:flex sm:items-center space-x-3"
            >
                <h3 className="text-xs font-semibold uppercase tracking-wide text-tm-gray-500">
                    Active filters
                </h3>

                <div className="hidden w-px h-5 bg-tm-gray-300 sm:block sm:ml-4"/>

                {filterLabels}

                {filterLabels.length > 1 && (
                    <React.Fragment>
                        <div className="hidden w-px h-5 bg-tm-gray-300 sm:block sm:ml-4"/>

                        <button
                            className="btn btn-text font-semibold uppercase"
                            onClick={() => props.onClearFiltersClick()}
                        >
                            {clearButtonLabelText}
                        </button>
                    </React.Fragment>
                )}
            </div>
        )
    )
}
