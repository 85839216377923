export function clearTabsResource() {
    return {
        type: 'CLEAR_TABS_RESOURCE'
    }
}
export function getTabsResource(data) {
    return {
        type: 'GET_TABS_RESOURCE',
        data: data
    };
}

export function getTabsResourceSilent(data) {
    return {
        type: 'GET_TABS_SILENT_RESOURCE',
        data: data
    };
}

export function doneGetTabsResource(data) {
    return {
        type: 'DONE_GET_TABS_RESOURCE',
        data: data
    };
}

export function errorGetTabsResource(data) {
    return {
        type: 'ERROR_GET_TABS_RESOURCE',
        data: data
    };
}

export function saveTabsResource(data) {
    return {
        type: 'SAVE_TABS_RESOURCE',
        data: data
    };
}

export function doneSaveTabsResource(data) {
    return {
        type: 'DONE_SAVE_TABS_RESOURCE',
        data: data
    };
}

export function errorSaveTabsResource(data) {
    return {
        type: 'ERROR_SAVE_TABS_RESOURCE',
        data: data
    };
}
