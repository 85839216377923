import React from "react";
import ExclamationIcon from "@heroicons/react/outline/ExclamationIcon";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import {classNames} from "../../common/util/util-helpers";
import ModalFooter from "../modal/modal-footer";
import Modal from "../modal";

export default function ModalConfirm(props) {
    const {
        type,
        show,
        title,
        text,
        textClassName,
        onConfirm,
        buttonLabel,
        buttonDisabled,
        closeButtonLabel,
        onClose,
        secondaryButtonLabel,
        onSecondaryButtonClick,
        secondaryButtonDisabled,
        customButtonsHTML,
        hideModalFooter,
        widthClass,
    } = props;

    const getIcon = () => {
        let icon, iconBg;

        switch (type) {
            case 'success':
                icon = <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                iconBg = "bg-green-100"
                break;
            case 'info':
                icon = <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true"/>
                iconBg = "bg-blue-100"
                break;
            default:
                iconBg = "bg-red-100"
                icon = <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
        }

        return (
            <div
                className={classNames(iconBg, "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10")}>
                {icon}
            </div>
        )
    }

    return (
        <Modal
            close={onClose}
            widthClass={widthClass ?? "max-w-xl"}
            show={show}
            {...props}
        >
            <div className="sm:flex sm:items-start p-6 bg-inverse">
                {getIcon()}

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-[calc(100%-5rem)]">
                    <h3 className="text-lg leading-6 font-medium text-default">
                        {title}
                    </h3>

                    <div className="mt-2 text-soft">
                        {text && (
                            <p className={textClassName ?? "text-sm text-default"}
                               dangerouslySetInnerHTML={{__html: text}}/>
                        )}

                        {!!show && props.children}
                    </div>
                </div>
            </div>

            {!hideModalFooter && (
                <ModalFooter
                    buttonDisabled={buttonDisabled}
                    buttonLabel={buttonLabel} // use nullable value to hide the button
                    onButtonClick={onConfirm}

                    closeButtonLabel={closeButtonLabel}
                    onClose={onClose}

                    secondaryButtonLabel={secondaryButtonLabel}
                    secondaryButtonDisabled={secondaryButtonDisabled}
                    onSecondaryButtonClick={onSecondaryButtonClick}
                >
                    {!!customButtonsHTML && (
                        customButtonsHTML
                    )}
                </ModalFooter>
            )}
        </Modal>
    );
}
