const resourceReducer = (state = {}, action) => {
    switch (action.type) {
        case 'RESET_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                create: null,
                isLoading: false,
                error: false,
                errorMessage: null,
            });
        case 'GET_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'GET_RESOURCE_SILENT':
            return Object.assign({}, state, {
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_RESOURCE':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'GET_RESOURCE_DETAILS':
            return Object.assign({}, state, {
                detailsData: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_RESOURCE_DETAILS':
            return Object.assign({}, state, {
                detailsData: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_RESOURCE_DETAILS':
            return Object.assign({}, state, {
                detailsData: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'CREATE_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'CREATE_DOCUMENT_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_CREATE_RESOURCE':
            return Object.assign({}, state, {
                create: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_CREATE_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'UPDATE_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_UPDATE_RESOURCE':
            return Object.assign({}, state, {
                update: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_UPDATE_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'DELETE_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_DELETE_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: true,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_DELETE_RESOURCE':
            return Object.assign({}, state, {
                deleteResource: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'RESET_RESOURCE_MESSAGE':
            return Object.assign({}, state, {
                deleteResource: null,
                update: null,
                create: null,
                error: false,
                errorMessage: null
            });
        default:
            return state;
    }
};

export default resourceReducer;
