import LocalStorage from "./localStorage";
import moment from 'moment';
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    TABLE_OPTIONS_SUF,
    USER_CLIENT_TYPE_NURSE, USER_CLIENT_TYPE_SERVICEMAN,
    USER_CLIENT_TYPES
} from "./util-constants";
import {currentDate, getUserTimeFormat} from "../common/util/util-dates";
import {SortAscendingIcon, SortDescendingIcon} from "@heroicons/react/outline";
import {cloneDeep} from "../common/util/util-vanilla";
import Tippy from "@tippyjs/react";

export function getUserFullName() {
    return getProp(LocalStorage.get('user'), 'Contact.FirstName', '') + " " + getProp(LocalStorage.get('user'), 'Contact.LastName', '')
}

export function getUserClientType() {
    return getProp(LocalStorage.get('user'), 'Contact.UserType', '')
}

export function getUserClientTypeName() {
    return USER_CLIENT_TYPES[getUserClientType()]
}

export function getUserClientTypeRelation() {
    const role = getUserClientType()
    if (role == USER_CLIENT_TYPE_NURSE) {
        return getProp(LocalStorage.get('user'), 'Contact.DepartmentName', '')
    } else if (role == USER_CLIENT_TYPE_SERVICEMAN) {
        return getProp(LocalStorage.get('user'), 'Contact.ServiceAreaName', '')
    }
    return ''
}

export function getBreadCrumbValue(key = '') {
    return getProp(LocalStorage.get("BreadCrumb"), key, '')
}

export function setBreadCrumbValue(key = '', value = '') {
    let BreadCrumb = LocalStorage.get("BreadCrumb")
    if (!BreadCrumb) {
        BreadCrumb = {}
    }
    BreadCrumb[key] = value
    LocalStorage.set("BreadCrumb", BreadCrumb)
}

export function longTableColumn(value, characters = 50) {
    if (value && value.toString().length > characters) {
        return (
            <Tippy content={<span
                className={'break-all'}>{value}</span>}><span>{value.slice(0, characters)}...</span></Tippy>
        )
    } else {
        return value
    }
}

export function checkPerm(key, check) {
    const perm = parseInt(getProp(LocalStorage.get('user'), 'permissions.' + key, 0));
    return (perm & check) !== 0;
}

export function timePickerValueToServerTime(time) {
    if (!time) return "00:00:00";

    return moment(time, getUserTimeFormat()).format("HH:mm:ss");
}

export function timeZoneToUTC(date) {

    let UserTimezone = LocalStorage.get("user")?.Contact?.Timezone

    if (!date || !UserTimezone || !moment(date, DEFAULT_DATABASE_DATETIME_FORMAT).isValid()) return undefined;

    const m = moment.tz(date, DEFAULT_DATABASE_DATETIME_FORMAT, UserTimezone);

    m.utc();

    return m.format(DEFAULT_DATABASE_DATETIME_FORMAT);
}

export function toBackDateTime(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000" || dateEntered === "1900-01-01 00:00:00") {
        return "no data available";
    }
    return (dateEntered ? moment(dateEntered, DEFAULT_DATABASE_DATETIME_FORMAT).format(DEFAULT_DATABASE_DATETIME_FORMAT) : "no data available");
}

export function userDateFormatToDateTime(date) {
    const userTimeFormat = LocalStorage.get('user')?.Contact?.DateTimeFormat;

    const dateTime = moment(date, userTimeFormat);

    if (dateTime.isValid()) {
        return dateTime.format(DEFAULT_DATABASE_DATETIME_FORMAT);
    }

    return undefined;
}

export function getProp(object, keys, defaultVal) {
    if (object === undefined || object === null) {
        return defaultVal;
    }
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return (object === undefined || object === null) ? defaultVal : object;
}

export function getCurrentTimeSeconds() {
    const d = new Date();
    return Math.round(d.getTime() / 1000);
}

export function numberWithCommasInputChange(n) {
    if (n) {
        let original = n.toString().split(".");
        if (original[1] && original[1].length > 1) {
            return original[0] + (original[1] ? "." + original[1].charAt(0) + original[1].charAt(1) : "");
        } else {
            n = n.replace(/[^0-9.]/g, '');
            n = n.replace(/,/g, "");
            n = n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return n;
        }
    }
}

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function getTimeFromServerDate(date) {

    let dateFormat = "HH:mm:ss";

    if (date?.length > 8) {
        dateFormat = "yyyy-mm-dd HH:mm:ss";
    }

    if (!date || date === 'Invalid date') {
        date = "";
    }

    if (moment(date, dateFormat).format(getUserTimeFormat()) === 'Invalid date') {
        return date;
    }

    return moment(date, dateFormat).format(getUserTimeFormat());
}

export function saveTableColumns(pagePath = "", tableOptions = {}) {
    let tableOptionsUpdate = cloneDeep(tableOptions);

    if (!!tableOptionsUpdate.icons) {
        delete tableOptionsUpdate.icons;
    }

    LocalStorage.set(pagePath + TABLE_OPTIONS_SUF, tableOptionsUpdate)
}

export function getDefaultTableOptions(fields, tableCustomDefaults, pagePath, translate) {
    const localStorageKey = pagePath + TABLE_OPTIONS_SUF;

    let options = LocalStorage.get(localStorageKey);

    if (!options) {
        options = getDefaultTableOptionsJSON(fields, tableCustomDefaults, translate);
    } else {
        Object.assign(options, {
            icons: {
                IconSortAsc: SortAscendingIcon,
                IconSortDesc: SortDescendingIcon,
            }
        });
    }
    return options;
}

export function getDefaultTableOptionsJSON(fields, tableCustomDefaults, translate) {
    let options = {
        behaviour: {
            stickyHeader: true,
            rowSelect: false,
            rowHover: true
        },
        style: {
            stripedRows: false,
            horizontalLines: true,
            verticalLines: true,
            condensed: false,
            floatingActions: true,
            frozenActionColumn: false,
        },
        classes: {
            tdPadding: "h-12 px-3",
            tdWithSubColumnsPadding: "h-16 pl-3 pr-6",
            tdCondensedWithSubColumnsPadding: "h-12 pl-3 pr-6",

            tdPaddingActions: "align-middle pl-3 pr-6",
            verticalLinesClass: "divide-x divide-tm-gray-300",
            horizontalLinesClass: "border-b border-tm-gray-300",
            maxHeightClass: "max-h-[calc(100vh-23rem)]"
        },
        columns: getColumns(fields, translate),
        icons: {
            IconSortAsc: SortAscendingIcon,
            IconSortDesc: SortDescendingIcon,
        }
    };

    options = Object.keys(options).reduce((memo, option) => {
        if (tableCustomDefaults[option]) {
            if (option === "columns") {
                memo["columns"] = mergeColumns(options, tableCustomDefaults);
            } else {
                memo[option] = Object.assign(options[option], tableCustomDefaults[option]);
            }
        } else {
            memo[option] = options[option];
        }

        return memo;
    }, {})

    return options;
}

function mergeColumns(options, tableCustomDefaults) {
    return Object.keys(options["columns"]).reduce((columnsMemo, column) => {
        if (!!tableCustomDefaults["columns"][column]) {
            columnsMemo[column] = Object.assign({}, options["columns"][column], tableCustomDefaults["columns"][column]);
        } else {
            columnsMemo[column] = options["columns"][column];
        }

        return columnsMemo;
    }, {});
}

export function getColumns(fields, translate) {
    return Object.values(fields)
        .filter((it) => it.type !== "hidden" && !it?.metadata?.hideTable)
        .reduce((memo, it, index) => {
        const defaultLabel = !!fields[it.name]?.metadata?.label ? translate("field." + fields[it.name].metadata.label) : translate("field." + it.name);

        memo[it.name] = {
            name: it.name,
            label: defaultLabel,
            frozen: !index,
            type: it.type,
            show: true,
            showMobile: index < 4,
            isMerged: false,
            merged: [],
            minWidth: getProp(fields, it.name + ".metadata.minWidth", 0)
        };
        return memo
    }, {});
}

export function formatFormNameFromDebtor(debtor, formName, format) {
    const ClientName = getBreadCrumbValue('ClientName').replace(/[^a-zA-Z0-9 ]/g, "");
    const DebtorName = debtor?.label?.replace(/[^a-zA-Z0-9 ]/g, "");
    return ClientName
        + "_"
        + DebtorName
        + "_"
        + formName
        + "_"
        + currentDate()
        + '.' + format

    // return debtor.metadata.caseNum + "-" + debtor.label + "-Form_" + formName + ".pdf";
}
