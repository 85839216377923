import React from "react";

export default function TableFooter({show, children}) {
    if (show === false) return null;

    return (
        <div
            className="bg-inverse px-4 py-3 flex items-center justify-between sm:px-6 h-16 rounded-b-lg"
        >
            {children}
        </div>
    )
}