export default class Env {
    static getApiUrl(path, query = null)  {
        return encodeURI(process.env.REACT_APP_API_URL+ path + Env.addQuery(query));
    }

    static getAuthApiUrl(path, query = null)  {
        return encodeURI(process.env.REACT_APP_AUTH_API_URL+ path + Env.addQuery(query));
    }

    static getRealtimeServiceUrl(path = "", query = null) {
        return encodeURI(process.env.REACT_APP_REALTIME_URL + path + Env.addQuery(query));
    }

    static addQuery(path)  {
        if (!!path) {
            let result = ('?' +
                (
                    Object.keys(path).reduce(
                        (memo, key) => {
                            if (Array.isArray(path[key])) {
                                memo += (path[key].map((it) => {
                                    if (typeof it === "object") {
                                        return key + "=" + (JSON.stringify(it))
                                    }
                                    return key + "=" + encodeURI(it)
                                })).join("&") + "&";
                            } else {
                                memo += (key + "=" + path[key] + "&");
                            }
                            return memo;
                        }
                        , "")
                )
            );
            return result.substring(0, result.length - 1);
        }
        return "";
    }

    static getPublicToken()  {
        return 'dGVzdGNsaWVudDp0ZXN0cGFzcw=';
    }
}
