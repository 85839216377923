import {useCallback} from "react";
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource, getSecondResourceSilent,
    updateSecondResource
} from "../actions/secondResource";

export const getSecondDataSelect = ({dispatch, user}) => useCallback(({query, resource}) => dispatch(getSecondResource({
    user: user,
    query: query,
    resource: resource
})), [dispatch])

export const getSecondDataSilentSelect = ({dispatch, user}) => useCallback(({query, resource}) => dispatch(getSecondResourceSilent({
    user: user,
    query: query,
    resource: resource
})), [dispatch])

// TODO
export const createSecondDataSelect = ({dispatch, user}) => useCallback((object) => {
    dispatch(createSecondResource({
        user: user,
        ...object
    }))
}, [dispatch])

export const updateSecondDataSelect = ({dispatch, user}) => useCallback((object) => {
    dispatch(updateSecondResource({
        user: user,
        ...object
    }))
}, [dispatch])

export const deleteSecondDataSelect = ({dispatch, user}) => useCallback((object) => {
    dispatch(deleteSecondResource({
        user: user,
        ...object
    }))
}, [dispatch])
