import LocalStorage from "../../util/localStorage";
import {CREATE_PERM, DELETE_PERM, READ_PERM, UPDATE_PERM} from "../../util/util-constants";
import {isObject} from "./util-vanilla";

export function checkPerm(key, check) {
    const perm = parseInt(getProp(LocalStorage.get('user'), 'permissions.' + key, 0));
    return (perm & check) !== 0;
}

export function getProp(object, keys, defaultVal) {
    if (object === undefined || object === null) {
        return defaultVal;
    }
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return (object === undefined || object === null) ? defaultVal : object;
}

export function getLookup(name, key = null, value = null) {
    const lookup = LocalStorage.get('lookup', {});
    if (key === null) key = name + "ID"
    if (value === null) value = name

    return lookup[name] && lookup[name].reduce(
        (memo, item) => {
            memo[item[key]] = item[value];
            return memo;
        }, {})
}

export function getTranslatedLookup(name, translate) {
    const lookup = LocalStorage.get('lookup', {});
    const key = name + "ID"
    const value = name

    return lookup[name] && lookup[name].reduce(
        (memo, item) => {
            memo[item[key]] = translate('lookup.' + item[value]);
            return memo;
        }, {})
}

export function getLookupsFromArray(arr) {
    return arr.reduce((memo, it) => {
        memo[it] = getLookup(it);
        return memo;
    }, {})
}

export function itemHasPerm(item, perm) {
    const itemPerm = item.perm.toString(2);

    switch (perm) {
        case READ_PERM:
            return parseInt(itemPerm.charAt(itemPerm.length - 1))
        case CREATE_PERM:
            return parseInt(itemPerm.charAt(itemPerm.length - 2))
        case UPDATE_PERM:
            return parseInt(itemPerm.charAt(itemPerm.length - 3))
        case DELETE_PERM:
            return parseInt(itemPerm.charAt(itemPerm.length - 4))
        default:
    }
}

export function permBtnToggle(item, permName) {
    let mod;
    switch (permName) {
        case "read":
            mod = 1;
            break;
        case "create":
            mod = 2;
            break;
        case "update":
            mod = 4;
            break;
        case "delete":
            mod = 8;
            break;
        default:
            mod = 0;
    }
    if (!item.perm || item.perm > 15) {
        item.perm = 0;
    }

    if (itemHasPerm(item, mod)) {
        item.perm -= mod;
        item[permName] = false;
    } else {
        item.perm += mod;
        item[permName] = true;
    }

    return item;
}

export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}});
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {[key]: source[key]});
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
