import {checkUserHelper} from "../services/api-util.js";
import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {doneGetSingleRecordResource, errorGetSingleRecordResource} from "../actions/singleRecordResource";

export function *getSingleRecordResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetSingleRecordResource(Object.assign({}, result?.data?.items[0], { recordCount: result?.data?.count ?? 0 })));
    } else {
        yield put(errorGetSingleRecordResource(result.data));
    }
}

export function* watchGetSingleRecordResource() {
    yield takeLatest('GET_SINGLE_RECORD_RESOURCE', getSingleRecordResourceCall);
}
