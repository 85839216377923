import {combineReducers} from "redux";
import userReducer from "./user";
import resourceReducer from "./resource";
import secondResourceReducer from "./secondResource";
import uiReducer from "./ui";
import infoReducer from "./infoReducer";
import tabsResource from "./tabsResource";
import download from "./download";
import downloadStacked from "./downloadStacked";
import stackedResourceReducer from "./stackedResource";
import historyReducer from "./historyReducer";
import singleRecordReducer from "./singleRecordReducer";
import thirdResourceReducer from "./thirdResource";

export interface UserDto {
    data: {
        access_token: string,
        refresh_token: string,
        ContactID: number
    },
    isLoading: boolean,
    error: boolean,
    errorMessage: string|null,
    logout: boolean
}

export interface ResourceDto {
    data: any,
    isLoading: boolean,
    error: boolean,
    errorMessage: string|null
}

export interface RootReducerDto {
    historyResource: any;
    ui: any,
    user: UserDto,
    resource: any,
    download: any,
    downloadStacked: any,
    addressFixerResource: any,
    redactionTableFixerResource: any,
    redactionMappingResource: any,
    secondResource: ResourceDto,
    tabsResource: ResourceDto,
    info: ResourceDto,
    reportsUpdate: any,
    stackedResource: any,

    claimPresence: any,
    claimUpdate: any,
    schedulePresence: any,
    scheduleUpdate: any,
    ballotPresence: any,
    ballotUpdate: any,
}

const appReducer = combineReducers({
    user: userReducer,
    resource: resourceReducer,
    info: infoReducer,
    ui: uiReducer,
    secondResource: secondResourceReducer,
    tabsResource: tabsResource,
    stackedResource: stackedResourceReducer,
    download: download,
    downloadStacked: downloadStacked,
    historyResource: historyReducer,
    singleRecordResource: singleRecordReducer,
    thirdResource: thirdResourceReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        return {
            ui: {},
            user: {},
            resource: {},
            download: {},
            downloadStacked: {},
            secondResource: {},
            tabsResource: {},
            info: {},
        }
    }
    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
