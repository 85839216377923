import PlusIcon from "@heroicons/react/outline/PlusIcon";
import React from "react";
import {classNames} from "../../common/util/util-helpers";

export default function NoRecords({title, text, btnLabel, onBtnClick, addClass, show, canCreate = true, htmlAfter}) {

    return !show ? null : (
        <div className={classNames("text-center", addClass, !btnLabel ? "mb-6" : "")}>
            <h3 className="text-lg font-medium text-soft">{title}</h3>

            {!!canCreate && (<p className="mt-1 text-sm text-soft">{text}</p>)}

            <div className="flex justify-center gap-4">
                {!!btnLabel && !!canCreate && (
                    <div className="mt-6">
                        <button
                            type="button"
                            className="btn btn-outline"
                            onClick={() => onBtnClick()}
                        >
                            <PlusIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true"/>
                            {btnLabel}
                        </button>
                    </div>
                )}

                {htmlAfter}
            </div>
        </div>
    )
}
