import React from "react";
import {classNames} from "../../common/util/util-helpers";

export default function Badge(props) {
    const {children, type, className, addClass, onClick} = props;

    const getBadgeClass = (type = null) => {
        switch (type) {
            case "outline":
                return "bg-transparent text-tm-gray-900 border border-secondary"
            case "success":
                return "bg-green-100 text-green-800"
            case "danger":
                return "bg-red-100 text-red-800"
            case "warning":
                return "bg-yellow-100 text-yellow-800"
            case "primary":
                return "bg-indigo-100 text-indigo-800"
            case "custom":
                return ""
            default:
                return "bg-secondary-light text-tm-gray-900"
        }
    }

    return (
        <div
            className={classNames(
                className ?? "inline-block text-center px-3 py-0.5 rounded-full text-sm font-medium",
                getBadgeClass(type),
                addClass
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
}
