import React  from 'react';
import {Menu, Transition} from "@headlessui/react";
import {DotsVerticalIcon, PencilAltIcon} from "@heroicons/react/solid";
import {ExclamationIcon} from "@heroicons/react/outline";

export default function NavResponsiveMenu({allTabs, onTabChange, lastVisibleTab, translate}) {

    const menuItems = allTabs.filter((it, i) => i > lastVisibleTab).map(it => {
        return (
            <Menu.Item>
                {({active}) => (
                    <span
                        onClick={() => onTabChange(it.resource)}
                        className={`cursor-pointer block py-2 px-4 flex items-center ${active ? 'bg-tm-gray-100 text-primary' : !!it.current ? " text-primary" : " text-gray-700"} ${!!it.current && " font-bold"}`}
                    >
                        {it.labelRaw ? it.name : translate("tab." + it.name)}
                        {it.isDirty && (<PencilAltIcon className={"ml-1 text-green-800 w-4 h-4"} />)}
                        {it.haveError && (<ExclamationIcon className={"ml-1 text-red-500 w-4 h-4"} />)}
                </span>
                )}
            </Menu.Item>
        )
    })

    return (
        <Menu>
            {({open}) => (
                <React.Fragment>
                    <Menu.Button
                        className="absolute text-inverse w-10 h-10 w-8 h-8 p-0 flex justify-center items-center right-0 top-0.5 hover:bg-tm-gray-100 rounded-full focus:outline-none focus:ring-2 ring-primary-600">
                        <DotsVerticalIcon className="w-5 h-5 text-primary"/>
                    </Menu.Button>

                    <Transition
                        show={open}
                    >
                        <Menu.Items
                            className="z-10 absolute right-0 top-12 w-56 py-2 mt-2 origin-top-right bg-inverse rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border border-tm-gray-200-300"
                        >
                            {menuItems}
                            <div
                                className="absolute w-2 h-2 -top-1 right-3 border-l border-t transform rotate-45 border-solid border-tm-gray-200-300 bg-inverse -z-1"/>
                        </Menu.Items>
                    </Transition>
                </React.Fragment>
            )}
        </Menu>
    )
}
