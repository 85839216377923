import React from 'react'
import {Link} from "react-router-dom";
import {classNames, getLookup, getProp} from "../common/util/util-helpers";
import FieldTextarea from "../components/field-textarea";
import FieldCheckbox from "../components/field-checkbox";
import Tippy from "@tippyjs/react";
import FieldRadio from "../components/field-radio";
import {CheckCircleIcon, DuplicateIcon, ExternalLinkIcon, InformationCircleIcon, XIcon} from "@heroicons/react/outline";
import {cloneDeep, genericMoneyFormatter} from "../common/util/util-vanilla";
import {currentDate, toFrontDate, toLocalTimeFromUTC} from "../common/util/util-dates";
import FieldText from "../components/field-text";
import FieldDropdownSelect from "../components/fields-dropdown-select";
import axios from "axios";
import {processResponse} from "../data/services/api-util.js";
import FieldDate from "../components/field-date";
import FieldSearch from "../components/field-text/search";
import Env from "./env.js";
import LocalStorage from "./localStorage";
import {numberWithCommas} from "./util-formaters";
import {formatMoney, getBreadCrumbValue, getTimeFromServerDate} from "./util";
import FieldSelectSearch from "../components/field-select-search";
import ErrorFieldMessage from "../components/error-field-message";
import FieldMoney from "../components/field-money";
import FieldContainer from "../components/field-container";
import MultiSelect from "../components/field-multi-select";

export function getFieldContainerClass(addClass = "", labelType = "") {
    let fieldContainerClass = "";

    switch (labelType) {
        case 'left':
            fieldContainerClass = "relative text-tm-gray-900 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5";
            break;
        default:
            fieldContainerClass = "relative text-tm-gray-900"
    }

    fieldContainerClass = classNames(fieldContainerClass, addClass);

    return fieldContainerClass;
}

export function getFieldLabelClass(metadata) {
    let formLabelClass;
    if (!!metadata?.formLabelClass) {
        formLabelClass = metadata.formLabelClass;
    } else {
        switch (metadata?.labelType) {
            case 'left':
                formLabelClass = "block text-sm font-semibold text-tm-gray-700 sm:mt-px sm:pt-2 bg-tm-gray-50";
                break;
            case 'float':
                formLabelClass = "label text-tm-gray-900 font-semibold absolute -top-2.5 left-0 label-bg px-1 text-xs z-10 bg-tm-gray-50"
                break;
            case 'stack':
            default:
                formLabelClass = "flex items-center text-sm font-semibold text-tm-gray-700";
        }
    }

    return formLabelClass;
}

export function excludeFields(fields = {}, excludedFields = []) {

    const fieldsUpdate = cloneDeep(fields)

    for (const [key] of Object.entries(fieldsUpdate)) {
        if (!!excludedFields.includes(key)) fieldsUpdate[key].type = "hidden";
    }

    return fieldsUpdate;
}

export function includeFields(fields = {}, includedFields = []) {
    const fieldsUpdate = cloneDeep(fields)

    for (const [key] of Object.entries(fieldsUpdate)) {
        if (!includedFields.includes(key)) fieldsUpdate[key].type = "hidden";
    }

    return fieldsUpdate;
}

export function fillFieldsFromData(fieldTemplates, data) {
    const formats = ['float', 'float_or_empty', 'integer', 'integer_or_empty'];

    if (!data) {
        return fieldTemplates;
    }

    return Object.values(fieldTemplates).reduce((memo, item) => {
        if (item.validate.filter(value => formats.includes(value)).length > 0) {
            const val = data[item.name];
            if (!!val && (val.length > 0) && val.includes(",")) {
                item.value = val;
            } else {
                if (item.validate[0] == 'integer' || item.validate[0] == 'integer_or_empty') {
                    item.value = val ? val.toString().replace(/\D/, '') : ''
                } else {
                    item.value = numberWithCommas(val) ?? fieldTemplates[item.name].value;
                }
            }
        } else if (item.type === 'select-search') {
            if (item?.metadata?.customValue) {
                item.value = item?.metadata?.customValue(data)
            } else if (!data[item.name]) {
                item.value = null // empty select should be null
            } else {
                if (item?.metadata?.labelField) {
                    item.value = {
                        value: data[item.name],
                        label: !!data[item?.metadata?.labelField] ? data[item?.metadata?.labelField] : "",
                        metadata: item?.metadata?.additionalMetadataFields ? item?.metadata?.additionalMetadataFields.reduce((memo, it) => {
                            memo[it] = data[it]
                            return memo
                        }, {}) : {}
                    }
                } else {
                    item.value = {
                        value: data[item.name],
                        label: !!data[item.name.replace("ID", "")] ? data[item.name.replace("ID", "")] : "",
                        metadata: item?.metadata?.additionalMetadataFields ? item?.metadata?.additionalMetadataFields.reduce((memo, it) => {
                            memo[it] = data[it]
                            return memo
                        }, {}) : {}
                    }
                }
            }
        } else if (item.type === 'checkbox') {
            if (typeof data[item.name] == "string") {
                item.value = data[item.name] === "1"
            } else {
                item.value = !!data[item.name]
            }
        } else if (item.type === 'rich-text') {
            item.value = !!data[item.name] ? slateHTMLToValue(data[item.name]) : null;
        } else if (item.type === 'datetimezone') {
            item.value = toLocalTimeFromUTC(data[item.name]);
        } else if (item.type === 'time-custom') {
            item.value = getTimeFromServerDate(toLocalTimeFromUTC(data[item.name.replace('Time', '')]));
        } else if (item.type === 'money') {
            item.value = formatMoney(data[item.name] ?? fieldTemplates[item.name].value ?? 0)
        }  else {
            item.value = data[item.name] ?? fieldTemplates[item.name].value;
        }
        item.isDirty = false;

        memo[item.name] = item;
        return memo;
    }, {});
}

// Simplify this function, remove read only fields
export function fieldsToHtml(fieldsCpy, translate, handleInputChange, selects = {}) {
    return fieldsCpy
        .filter(
            it => !it?.metadata?.hideDialog && (!selects[it.name]?.visibleFilter || !selects[it.name]?.visibleFilter(fieldsCpy))
        )
        .reduce((memo, item, i) => {
            const req = (
                item.validate.includes("empty") ||
                item.validate.find(it => it.includes("length_>")) ||
                item.validate.find(it => it.includes("length_=")) ||
                item.validate.includes("empty_select_search") ||
                item.validate.includes("integer") ||
                item.validate.includes("float"));
            let field;
            const metadata = item.metadata;
            const fieldClass = !!item?.metadata?.fieldClass || item?.metadata?.fieldClass === "" ? item.metadata.fieldClass : "form-control";

            // @TODO: standardize to form inputs with stacked, floating and left label
            let containerClass = item?.metadata?.containerClass ?? "col-md-12";

            let readOnlyFieldGroupClass = item?.metadata?.readOnlyFieldGroupClass ?? "sm:col-span-1 relative";
            let readOnlyLabelClass = item?.metadata?.readOnlyLabelClass ?? "text-sm font-bold text-tm-gray-800";
            let readOnlyFieldClass = item?.metadata?.readOnlyFieldClass ?? "mt-1 text-sm text-tm-gray-900";

            item.type = (item?.metadata?.updateType && item?.metadata?.updateType(item)) ?? item.type;

            let itemLabelText;

            if (!!item?.metadata?.labelRaw) {
                itemLabelText = item.metadata.labelRaw + (req ? " *" : "");
            } else {
                itemLabelText = (!!item?.metadata?.label) ? (translate("field." + item?.metadata?.label) + " " + (req ? "*" : "")) : (translate("field." + item.name) + " " + (req ? "*" : ""))
            }

            if (item.type === 'custom') {
                field = selects[item.name];
            } else if (item.type === 'select-search' || item.type === "multi-select-search") {
                field = (
                    <div
                        key={item?.props?.key ?? item.name}
                        className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}
                    >
                        {!item?.metadata?.hideLabel && (
                            <div className={getFieldLabelClass(metadata)}>
                                {itemLabelText}

                                {!!item?.metadata?.fieldOptions && (
                                    <span className={item?.metadata?.fieldOptionsClassName ?? ""}>
                                        {item.metadata.fieldOptions(item)}
                                    </span>
                                )}
                            </div>
                        )}

                        <FieldDropdownSelect
                            onChange={handleInputChange}
                            {...item}
                            addClass={fieldClass}
                            defaultOptions={true}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl(selects[item.name].api, Object.assign(!!selects[item.name].search ? selects[item.name].query : {}, inputValue ? {search: inputValue} : {}, selects[item.name].query, getProp(item, "props.query", {}))),
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + LocalStorage.get('user')?.access_token
                                            }
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);

                                            if (result && result.status === 0) {
                                                let list;
                                                if (selects[item.name]?.customizeList) {
                                                    list = selects[item.name].customizeList(result.data.list);
                                                } else {

                                                    list = result.data.list.map((it) => {

                                                        return selects[item.name].searchMap(it);
                                                    });
                                                }

                                                callback(list);
                                            }
                                        })
                                        .catch((error) => {
                                        });
                                }
                            }
                            {...item.props}
                            translate={translate}
                        />

                        {!!item?.metadata?.note && (
                            <p className="mt-1 text-tm-gray-700">{item.metadata.note}</p>
                        )}

                        {!!item?.metadata?.errorNote && (
                            <p className="mt-1 text-red-700 text-sm">{item.metadata.errorNote}</p>
                        )}
                    </div>
                );
            } else if (item.type === "select") {
                let values;
                if ((typeof selects[item.name] === "function")) {
                    values = selects[item.name](item, fieldsCpy, handleInputChange);
                } else if (!!selects[item.name]) {
                    values = selects[item.name];
                }  else if (!!item?.props?.values) {
                    values = item?.props?.values;
                } else {
                    values = getLookup(item.name.replace("ID", ""));
                }

                field = (
                    <React.Fragment key={item.name}>
                        {metadata?.beforeContainerHtml}

                        <div style={item?.metadata?.addContainerStyle ?? {}} className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}>
                            {!item?.metadata?.hideLabel && (
                                <span className={getFieldLabelClass(metadata)}>{itemLabelText}</span>
                            )}

                            <FieldSelectSearch
                                addClass="form-control"
                                onChange={handleInputChange}
                                {...item}
                                {...item.props}
                                values={values}
                            />
                        </div>
                    </React.Fragment>
                );
            } else if (item.type === "multi-select") {
                let values;
                if ((typeof selects[item.name] === "function")) {
                    values = selects[item.name](item, fieldsCpy, handleInputChange);
                } else if (!!selects[item.name]) {
                    values = selects[item.name];
                } else {
                    values = getLookup(item.name.replace("ID", ""));
                }

                field = (
                    <React.Fragment key={item.name}>
                        {metadata?.beforeContainerHtml}

                        <div className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}>
                            {!item?.metadata?.hideLabel && (
                                <span className={getFieldLabelClass(metadata)}>{itemLabelText}</span>
                            )}

                            <MultiSelect
                                values={values}
                                addClass="select-css-search form-control p-0"
                                onChange={handleInputChange}
                                {...item}
                                {...item.props}
                            />
                        </div>
                    </React.Fragment>
                );
            } else if (item.type === 'hidden' || item.type === 'meta') {
                field = null;
            } else if (item.type === "textarea") {
                let heightClass = metadata?.heightClass ?? "min-h-[6rem]";
                const fieldLabelCheckbox = fieldsCpy.find(it => it?.name === item?.metadata?.formLabelCheckbox)

                field = (
                    <div key={item.name} className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}>
                        {!item?.metadata?.hideLabel && (
                            <span className={getFieldLabelClass(metadata)}>
                                {itemLabelText}

                                {item?.metadata?.formLabelCheckbox && (
                                    <div
                                        key={fieldLabelCheckbox.name}
                                        className={getFieldContainerClass(null, null)}
                                    >

                                        <div className={classNames(
                                            "flex items-center",
                                        )}
                                        >
                                            <label
                                                className={classNames(
                                                    "mt-2 select-none items-center rounded-md w-full",
                                                    "p-1.5 flex"
                                                )}
                                                htmlFor={fieldLabelCheckbox.name}
                                            >
                                                <FieldCheckbox
                                                    id={fieldLabelCheckbox.name}
                                                    className={classNames("checkbox",
                                                        !!item.value ? "border-primary" : "border-tm-gray-400",
                                                        !!item.disabled ? "cursor-default" : undefined
                                                    )}
                                                    onChange={handleInputChange}
                                                    {...fieldLabelCheckbox}
                                                    {...fieldLabelCheckbox?.props}
                                                />

                                                <span
                                                    className={classNames(
                                                        "flex flex-col text-sm font-semibold text-tm-gray-900",
                                                        "ml-2"
                                                    )}
                                                >
                                                    {!fieldLabelCheckbox?.metadata?.hideLabel && (
                                                        <span className={getFieldLabelClass(metadata)}>{translate("field." + fieldLabelCheckbox.name)}</span>
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </span>
                        )}

                        <FieldTextarea
                            addClass={classNames(heightClass, "form-control")}
                            onChange={handleInputChange}
                            translate={translate}
                            {...item}
                            {...item.props}
                        />

                    </div>
                );
            } else if (item.type === "checkbox") {
                field = (
                    <div
                        key={item?.props?.htmlFor ?? item.name}
                        className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}
                    >

                        <div className={classNames(
                            "flex items-center",
                                !!item.metadata?.checkboxbelow ? "text-center" : undefined
                            )}
                        >
                            <label
                                className={classNames(
                                    "mt-2 select-none items-center rounded-md w-full",
                                    !!item.metadata?.checkboxbelow ? "p-1 flex flex-col-reverse justify-center items-center" : "p-1.5 flex",
                                    !item.disabled ? "hover:bg-tm-gray-100 cursor-pointer" : ""
                                )}
                                htmlFor={item?.props?.htmlFor ?? item.name}
                            >
                                    <FieldCheckbox
                                        id={item?.props?.htmlFor ?? item.name}
                                        className={classNames("checkbox",
                                            !!item.value ? "border-primary" : "border-tm-gray-400",
                                            !!item.disabled ? "cursor-default" : undefined
                                        )}
                                        onChange={handleInputChange}
                                        {...item}
                                        {...item.props}
                                    />

                                    <span
                                        className={classNames(
                                            "flex flex-col text-sm font-semibold text-tm-gray-900",
                                            !!item.metadata?.checkboxbelow ? "items-center mb-2" : "ml-2"
                                            )}
                                    >
                                        {!item?.metadata?.hideLabel && (
                                            <span className={getFieldLabelClass(metadata)}>{itemLabelText}</span>
                                        )}

                                        {!!item?.metadata?.note && (
                                            <p className="m-0 font-normal text-xs text-tm-gray-700 leading-none">{item.metadata.note}</p>
                                        )}
                                    </span>
                            </label>
                        </div>
                    </div>
                );
                // Merge this with button-group
            } else if (item.type === "toggle-buttons") {
                field = (
                    <React.Fragment>
                        <div className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}>
                            <span className={getFieldLabelClass(metadata)}>
                                {!item?.metadata?.hideLabel && (
                                    itemLabelText
                                )}

                                {item?.metadata?.info && (
                                    <Tippy content={item?.metadata?.info}>
                                        <i className="simple-icon-info ml-2 align-top"/>
                                    </Tippy>
                                )}
                            </span>

                            <div className="flex">
                                <button
                                    disabled={item.disabled}
                                    className={classNames("btn pr-3 rounded-r-none focus:ring-offset-2 focus:ring-primary-300", !!item?.metadata?.square ? "rounded-none px-3" : "", !!item.value ? "btn-outline border-gray-700 text-gray-700 relative z-10" : "btn-primary bg-link font-bold")}
                                    onClick={() => item.disabled ? null : handleInputChange(item.name, 0)}
                                >
                                    {item?.metadata?.buttonOff ?? "No"}
                                </button>

                                <button disabled=""
                                        className={classNames("btn rounded-l-none pl-3 focus:ring-offset-2 focus:ring-primary-300", !!item?.metadata?.square ? "rounded-none px-3" : "", !!item.value ? "btn-primary bg-link font-bold" : "btn-outline border-gray-700 text-gray-700 relative z-10")}
                                        onClick={() => item.disabled ? null : handleInputChange(item.name, 1)}
                                >
                                    {item?.metadata?.buttonOn ?? "Yes"}
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                );
            } else if (item.type === "toggle-buttons-group") {
                field = (
                    <React.Fragment>
                        <div className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}>
                            <span className={getFieldLabelClass(metadata)}>
                                {!item?.metadata?.hideLabel && (
                                    itemLabelText
                                )}

                                {item?.metadata?.info && (
                                    <Tippy content={item?.metadata?.info}>
                                        <i className="simple-icon-info ml-2 align-top"/>
                                    </Tippy>
                                )}
                            </span>

                            <div className="flex">
                                {getProp(item, 'metadata.options', []).map((opt, optIndex) => {
                                    let addClassNames = [
                                        optIndex === 0 ? "rounded-r-none" : "",
                                        (optIndex !== 0 && optIndex !== getProp(item, 'metadata.options', []).length - 1) ? "rounded-none" : "",
                                        optIndex === getProp(item, 'metadata.options', []).length - 1 ? "rounded-l-none" : "",
                                        item.value === opt.value ? "btn-primary border-gray-700 relative z-10" : "text-gray-700 btn-outline font-bold"
                                    ]

                                    return (
                                        <button
                                            disabled={opt.disabled}
                                            className={classNames(
                                                "btn  ", addClassNames.join(" ")
                                            )}
                                            onClick={() => opt.disabled ? null : handleInputChange(item.name, opt.value)}
                                        >
                                            {opt?.labelRaw ??opt?.name ?? ""}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                    </React.Fragment>
                );
            } else if (item.type === "radio") {
                if (!!metadata.options) {
                    let options = metadata.options.map(it => (
                        <label
                            key={it.name}
                            className="p-2 hover:bg-secondary-light rounded-md cursor-pointer"
                            htmlFor={it.name}
                        >
                            <FieldRadio
                                id={it.name}
                                className="mr-2"
                                onChange={() => handleInputChange(item.name, it.value)}
                                name={it.name}
                                value={item.value === it.value}
                            />

                            {it.name}
                        </label>
                    ));

                    field = (
                        <div
                            key={item.name}
                            className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}
                        >
                            {!item?.metadata?.hideLabel && (
                                <label
                                    className={classNames(getFieldLabelClass(metadata), "mb-1")}
                                >{itemLabelText}</label>
                            )}

                            <div className="flex flex-col space-y-1">
                                {options}
                            </div>

                            {!!item.errorMessage && (
                                <ErrorFieldMessage
                                    errorMessage={item.errorMessage}
                                    translate={translate}
                                />
                            )}
                        </div>
                    )
                }
            } else if (item.type === "toggle") {
                field = (
                    <React.Fragment>
                        <div className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}>
                            {!item?.metadata?.hideLabel && (
                                <label
                                    className={getFieldLabelClass(metadata)}
                                    htmlFor={item.name}
                                >
                                    {itemLabelText}
                                </label>
                            )}
                            <div className="h-10 flex items-center">
                                <button disabled="" className="btn-group bg-transparent border-0 btn p-0">
                                    <span
                                        onClick={() => item.disabled ? null : handleInputChange(item.name, 0)}
                                        className={"btn btn-xs rounded-r-none " + (item.disabled ? "disabled " : "") + (!item.value ? "btn-primary" : "btn-outline")}>{item?.metadata?.buttonOff ?? "No"}</span>
                                    <span
                                        onClick={() => item.disabled ? null : handleInputChange(item.name, 1)}
                                        className={"btn btn-xs rounded-l-none " + (item.disabled ? "disabled " : "") + (!!item.value ? "btn-primary" : "btn-outline")}>{item?.metadata?.buttonOn ?? "Yes"}</span>
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                );
            } else if (item.type === "date" || item.type === "datetime" || item.type === "datetimezone") {
                const minDate = !!item?.metadata?.minDate && item?.metadata?.minDate(fieldsCpy);
                const maxDate = !!item?.metadata?.maxDate && item?.metadata?.maxDate(fieldsCpy);

                field = (
                    <div key={item.name}
                         className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}>
                        <span className={getFieldLabelClass(metadata)}>
                            {itemLabelText}

                            {item?.props?.currentDateButton && !item?.value && (
                                <button
                                    className={`btn btn-primary px-2 py-0.5 ml-auto text-xs mb-1`}
                                    onClick={() => {
                                        handleInputChange(item.name, currentDate())
                                    }}
                                >
                                    Current Date
                                </button>
                            )}
                        </span>
                        <FieldDate
                            addClass={fieldClass}
                            onChange={handleInputChange}
                            showTimeSelect={false}
                            minDate={minDate}
                            maxDate={maxDate}
                            {...item}
                            {...item.props}
                        />
                    </div>
                );
            } else if (item.type === "emails") {
                field = (
                    <div className={containerClass}>
                        <div className="has-float-label">
                        <span className="input-group mb-4">
                            <FieldText
                                className={"form-control p-0"}
                                addClass={"form-control"}
                                onChange={handleInputChange}
                                translate={translate}
                                {...item}
                                {...item.props}
                            />

                            <div className="input-group-append">
                                <button className="btn btn-outline position-relative z-index-3" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                    </svg>
                                </button>
                            </div>
                        </span>

                            <span className="">{itemLabelText}</span>
                        </div>
                        {!!item?.metadata?.errorNote && (
                            <p className="mt-1 text-red-700 text-sm">{item.metadata.errorNote}</p>
                        )}
                    </div>
                );
            } else if (item.type === "link") {
                field = (
                    <div className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}>
                        <span className={getFieldLabelClass(metadata)}>
                           {itemLabelText}
                        </span>

                        <div>
                            {!!item.value && (
                                <div className="flex">
                                    {item?.metadata?.htmlBeforeInput}
                                    <input disabled={true} className="form-control overflow-ellipsis"
                                           value={window.location.protocol + "//" + window.location.hostname + (!!window.location.port ? ":" + window.location.port : "") + item.value}/>

                                    <button
                                        className={classNames(item?.metadata.copied ? "text-green-600" : "", "border border-tm-gray-300 border-l-0 px-2 flex items-center hover:bg-tm-gray-50 whitespace-nowrap")}
                                        onClick={() => handleInputChange(item.name, item.value)}
                                    >
                                        {item?.metadata?.copied ? <CheckCircleIcon className="h-4 w-4 mr-1"/> :
                                            <DuplicateIcon className="h-4 w-4 mr-1"/>}
                                        Copy
                                    </button>

                                    <Link
                                        className="border border-tm-gray-300 border-l-0 px-2 flex items-center hover:bg-tm-gray-50 hover:text-tm-gray-900 whitespace-nowrap"
                                        target="_blank"
                                        to={item.value}
                                    >
                                        <ExternalLinkIcon className="h-4 w-4 mr-1"/>
                                        Visit
                                    </Link>

                                </div>

                            )}

                            {!item.value && (
                                <span className="text-tm-gray-900 font-weight-normal">N/A</span>
                            )}
                        </div>

                    </div>
                );
            } else if (item.type === "readonly") {
                field = (
                    <div
                        key={item.name}
                        className={readOnlyFieldGroupClass}
                    >
                        {!item?.metadata?.hideLabel && (
                            <dt className={readOnlyLabelClass}>
                                {itemLabelText}
                            </dt>
                        )}
                        <dd className={readOnlyFieldClass}>
                            {item.value ?? "/"}
                        </dd>

                        <span className="absolute top-0 right-0">
                            {item?.metadata?.fieldOptions}
                        </span>
                    </div>
                );
            } else if (item.type === "money") {
                if (!item.metadata) {
                    item.metadata = {}
                }

                if (!item.props) {
                    item.props = {}
                }

                item.metadata.htmlBeforeField = () => (
                    <div className="pointer-events-none absolute left-0 flex items-center h-10 pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                );

                item.metadata.htmlAfterField = () => (
                    <div className="pointer-events-none absolute right-0 flex items-center h-10 pr-3">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">USD</span>
                    </div>
                )

                item.props.className = "form-control pl-7 pr-12"

                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldMoney
                            item={item}
                            placeholder={item.placeholder ?? "$0.00"}
                            onChange={handleInputChange}
                        />
                    </FieldContainer>
                )
            } else if (item.type === "readonly-money") {
                field = (
                    <div className={readOnlyFieldGroupClass}>
                        {!item?.metadata?.hideLabel && (
                            <dt className={readOnlyLabelClass}>
                                {translate("field." + item.name)}
                            </dt>
                        )}

                        <dd className={readOnlyFieldClass}>
                            {genericMoneyFormatter(item.value ?? 0)}
                        </dd>

                        <span className="absolute top-0 right-0">
                            {item?.metadata?.fieldOptions}
                        </span>
                    </div>
                );
            } else if (item.type === "readonly-file") {
                containerClass = item?.metadata?.containerClass ?? "col-sm-6 col-md-4";
                const filePath = item?.metadata?.filePath;
                field = (
                    <div className={containerClass}>
                        {!item?.metadata?.hideLabel && (
                            <dt className="">{translate("field." + item.name)}</dt>
                        )}
                        <dd className={"mt-1 mb-3"}>
                            {!!filePath && (
                                <a
                                    className="flex"
                                    title={translate("text.click_to_download")}
                                    href={filePath}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-1.5em w-1.5em mr-1"
                                         viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd"
                                              d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                              clipRule="evenodd"/>
                                    </svg>

                                    {item.value}
                                </a>
                            )}

                            {!filePath && item.value}
                        </dd>
                    </div>
                );
            } else if (item.type === "readonly-date") {
                field = (
                    <div
                        key={item.name}
                        className={readOnlyFieldGroupClass}
                    >
                        {!item?.metadata?.hideLabel && (
                            <dt className={readOnlyLabelClass}>
                                {translate("field." + item.name)}
                            </dt>
                        )}

                        <dd className={readOnlyFieldClass}>
                            {item.value ? toFrontDate(item.value) : "/"}
                        </dd>
                    </div>
                );
            }  else if (item.type === "readonly-checkbox") {
                field = (
                    <div
                        key={item.name}
                        className={readOnlyFieldGroupClass}
                    >
                        {!item?.metadata?.hideLabel && (
                            <dt className={readOnlyLabelClass}>
                                {translate("field." + item.name)}
                            </dt>
                        )}
                        <dd className={readOnlyFieldClass}>
                            {!!item.value
                                ?
                                <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                                :
                                <XIcon className="w-4 h-4 text-tm-gray-700"/>
                            }
                        </dd>
                    </div>
                );
            } else if (item.type === "search") {
                field = (
                    <div
                        key={item.name}
                        className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}
                    >
                        {!item?.metadata?.hideLabel && (
                            <label htmlFor={item.name} className={classNames(getFieldLabelClass(metadata), "flex items-center")}>
                                {itemLabelText}

                                {item?.metadata?.searchableColumns && item?.metadata?.searchableColumns.length > 0 && (
                                    <Tippy content={(
                                        <div>
                                            Searchable Columns:
                                            {item?.metadata?.searchableColumns.map(it => (
                                                <div>- {translate("field." + it)}</div>
                                            ))}
                                        </div>
                                    )}>
                                        <InformationCircleIcon className={'w-4 h-4 ml-1'}/>
                                    </Tippy>
                                )}
                            </label>
                        )}

                        <FieldSearch
                            id={item.name}

                            className={"form-control p-0"}
                            addClass="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                            onChange={handleInputChange}
                            translate={translate}
                            placeholder={""}
                            {...item}
                            {...item.props}
                            type="text"
                        />

                        {!!item?.metadata?.note && (
                            <p className="mt-1">{item.metadata.note}</p>
                        )}
                    </div>
                );
            } else {
                field = (
                    <div key={item.name}
                         className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}>
                        {!item?.metadata?.hideLabel && (
                            <label htmlFor={item.name} className={getFieldLabelClass(metadata)}>{itemLabelText}</label>
                        )}

                        <FieldText
                            id={item.name}
                            addClass={fieldClass}
                            onChange={handleInputChange}
                            translate={translate}
                            placeholder={""}
                            {...item}
                            {...item.props}
                        />

                        {!!item?.metadata?.note && (
                            <p className={item?.metadata?.noteClass ?? "mt-1"}>{item.metadata.note}</p>
                        )}
                        {!!item?.metadata?.errorNote && (
                            <p className="mt-1 text-red-700 text-sm">{item.metadata.errorNote}</p>
                        )}
                    </div>
                );
            }

            {/*Experimental*/}
            {item?.metadata?.titleBefore && (
                field = (
                    <React.Fragment key={item.name}>
                        <div className="mt-6 col-span-full">

                            <p className="text-lg leading-6 font-medium text-soft border-b border-tm-gray-300 pb-2">{item.metadata.titleBefore}</p>
                            {!!item?.metadata?.straplineBefore && (
                                <p className="text-tm-gray-700 mb-6">{item.metadata.straplineBefore}</p>
                            )}
                        </div>
                        {field}
                    </React.Fragment>
                )
            )}

            memo.push(field);

            return memo;
        }, []);
}
