import React from "react";
import {classNames} from "../../../common/util/util-helpers";

const PageHeader = ({
                                       className = undefined,
                                       title,
                                       buttonLabel,
                                       onButtonClick,
                                       removePageHeaderLabel = false,
                                       buttonDisabled = false,
                                       buttonHidden = false,
                                       buttonClassName = '',
                                       secondaryButtonLabel = '',
                                       onSecondaryButtonClick = () => {},
                                       secondaryButtonDisabled = false,
                                       secondaryButtonHidden = false,
                                       customHtml = "",
                                       titleClass = undefined,
                                       buttonsClassName = undefined,
                                       afterTitle = "",
                                       children = ""
                                   }) => {
    return (
        <header className={classNames(className ?? "flex")}>
            <h1 className={titleClass ?? "text-2xl flex items-center font-bold"}>
                {!removePageHeaderLabel && title}

                {afterTitle}
            </h1>

            <div className={classNames(buttonsClassName ?? "ml-auto space-x-3 flex")}>
                {customHtml}
                {children}

                {!!secondaryButtonLabel && !secondaryButtonHidden && (
                    <button
                        className={`btn btn-outline-secondary`}
                        onClick={() => {
                            !secondaryButtonDisabled && onSecondaryButtonClick();
                        }}
                        disabled={secondaryButtonDisabled}
                    >
                        {secondaryButtonLabel}
                    </button>
                )}

                {!!buttonLabel && !buttonHidden && (
                    <button
                        id="create-btn"
                        className={classNames('btn btn-primary', buttonClassName ?? '')}
                        onClick={() => {
                            if (!buttonDisabled) {
                                onButtonClick();
                            }
                        }}
                        disabled={buttonDisabled}
                    >
                        {buttonLabel}
                    </button>
                )}
            </div>
        </header>
    )
}

export default PageHeader
