import React, {useEffect} from "react";
import {getProp} from "../../../common/util/util-helpers";
import {NOTIFICATION_TYPE_CHAT, NOTIFICATION_TYPE_SYSTEM} from "../../../util/util-constants";
import Notification from "./notification";
import {closeNotificationDelayed} from "../../../data/actions/ui";
import {createPortal} from "react-dom";

const Notifications = ({ ui, translate, dispatch }) => {

    const setNotificationHeight = () => {
        const notificationElements = document.querySelectorAll(".notification-fade-out");
        notificationElements.forEach(item => {
            const message = item.querySelector('p');
            item.style.height = message.offsetHeight + 27 + "px";
        })
    }

    const closeMessage = (id) => {
        const notification = document.getElementById('resource-notification-' + id);
        notification.classList.remove('notification-fade-out')
        notification.classList.add('notification-fade-out')
        notification.style.animationDelay = "0s";
        dispatch(closeNotificationDelayed(id));
    }

    const groupNotifications = (messageType, position) => {
        // TODO: This function is not complete
        if (position === "bottom-left") {
            return true;
        }

        if (position === "top-right") {
            return [NOTIFICATION_TYPE_CHAT, NOTIFICATION_TYPE_SYSTEM].includes(messageType)
        }

        return false;
    }

    const resourceMessages = getProp(ui, "messages", []).filter(notification => groupNotifications(notification.messageType, "bottom-left"));
    const systemMessages = getProp(ui, "messages", []).filter(notification => groupNotifications(notification.messageType, "top-right"));

    const resourceNotifications = resourceMessages.map((notification, i) => {
        if (notification.isError) {
            notification.message = translate("ERROR_" + notification.actionType);
            notification.color = "danger";
        }

        if (notification.customErrorMessage) {
            notification.message = notification.customErrorMessage;
            notification.color = "danger";
        }

        return <Notification key={i} notification={notification} closeMessage={closeMessage} translate={translate} />
    })

    const systemNotifications = systemMessages.map((notification, i) => {
        notification.message = translate(notification.message);

        return <Notification notification={notification} closeMessage={closeMessage} translate={translate} />
    })

    useEffect(() => {
        setNotificationHeight()
    }, [ui])

    return createPortal(
            <React.Fragment>
                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex px-4 py-6 pointer-events-none sm:px-6 sm:pt-20 sm:pb-16 z-[51] items-end"
                >
                    {!!resourceNotifications.length && (
                        <div className="w-full flex flex-col items-start space-y-4">
                            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                            {resourceNotifications}
                        </div>
                    )}
                </div>

                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex px-4 py-6 pointer-events-none sm:px-6 sm:pt-20 sm:pb-16 z-50 justify-start"
                >
                    {!!systemNotifications.length && (
                        <div className="w-full flex flex-col items-end space-y-4">
                            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                            {systemNotifications}
                        </div>
                    )}
                </div>
            </React.Fragment>,
            document.body
    )
}

export default Notifications
