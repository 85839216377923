import React from "react";
import { classNames } from "../../common/util/util-helpers";
import {InformationCircleIcon} from "@heroicons/react/solid";

export default function InfoBar({message, className, addClass, children, onClick, type, Icon = InformationCircleIcon}) {
    const classes = getTypeClasses(type);
    return (
        <div
            className={
                classNames(
                    className ?? "flex gap-2 py-2 px-2 rounded-md",
                    onClick ? classes.hover : undefined,
                    classes.bg,
                    addClass
                )
            }
            onClick={onClick}
        >
            <Icon
                className={classNames("w-5 h-5 shrink-0", classes.icon)}
            />

            {message}

            {children}
        </div>
    )
}

const getTypeClasses = (type) => {
    switch (type) {
        case "success":
            return {
                bg: "bg-green-600/10 text-tm-gray-900",
                hover: "hover:bg-green-600/20 cursor-pointer",
                icon: "text-green-600"
            }
        case "danger":
            return {
                bg: "bg-red-600/10 text-tm-gray-900",
                hover: "hover:bg-red-600/20 cursor-pointer",
                icon: "text-red-600"
            }
        case "warning":
            return {
                bg: "bg-yellow-600/10 text-tm-gray-900",
                hover: "hover:bg-yellow-600/20 cursor-pointer",
                icon: "text-yellow-500"
            }
        default:
            return {
                bg: "bg-sky-600/10 text-tm-gray-900",
                hover: "hover:bg-sky-600/20 cursor-pointer",
                icon: "text-sky-600"
            }
    }
}
