import React, {useEffect, useState} from "react";
import SearchIcon from "@heroicons/react/solid/SearchIcon";
import {classNames} from "../../common/util/util-helpers";
import {scrollIntoView} from "../../common/util/util-vanilla";

const FieldClearIcon = () => (
    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill="currentColor">
        <path
            d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"/>
    </svg>
)

export default function FieldSearch({id, translate, innerRef, type, onChange, readOnly, autoFocus, onBlur, onFocus, onKeyDown, name, value, placeholder, disabled, errorMessage, min, max, step, autocomplete, addClass, icon}) {
    const [inputValue, setInputValue] = useState(undefined);
    const hasIcon = icon !== false;

    const submitChange = () => onChange(name, inputValue);

    const onSearchChange = (event) => {
        setInputValue(event.target.value);
    }

    useEffect(() => {
        if (!value && !!inputValue) {
            setInputValue("");
        }
    }, [value])

    useEffect(() => {
        let debounceTimeout;
        if (inputValue !== undefined && value !== inputValue) { // prevent submitting on initial load
            debounceTimeout = setTimeout(submitChange, 300);
        }

        if (inputValue === undefined && value) {
            setInputValue(value)
        }

        return () => {
            clearTimeout(debounceTimeout);
        }
    }, [inputValue])

    const input = (
        <input
            id={id}
            ref={innerRef}
            type={type}
            readOnly={readOnly}
            autoFocus={autoFocus}
            onChange={onSearchChange}
            onBlur={onBlur}
            onFocus={(event) => {
                if (onFocus) {
                    onFocus(event);
                }

                scrollIntoView(event);
            }}
            onKeyDown={onKeyDown}
            name={name}
            value={inputValue}
            placeholder={placeholder}
            disabled={disabled}
            className={classNames("py-2 form-control", hasIcon ? "pl-9" : undefined , addClass, (errorMessage ? " is-invalid" : ""))}
            min={min}
            maxLength={max}
            step={step}
            autoComplete={autocomplete}
        />);

    return (
        <React.Fragment>
            <div className="relative">
                {hasIcon && (
                    <div
                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon className="w-4 h-4 text-tm-gray-400 z-10"/>
                    </div>
                )}

                {!!value && (
                    <button
                        onClick={() => onChange(name, "")}
                        className="absolute z-10 inset-y-0 right-0 px-3 flex items-center cursor-pointer text-tm-gray-900 hover:text-tm-gray-400">
                        <FieldClearIcon/>
                    </button>
                )}

                {input}
            </div>

            {errorMessage && (
                <div className={"invalid-feedback"}>
                    {!!translate && (
                        errorMessage.reduce((memo, it) => {
                            return translate(it) + (!!memo ? "," + memo : "")
                        }, "")
                    )}
                    {!translate && (
                        <span>Field required*</span>
                    )}
                </div>
            )}
        </React.Fragment>

    )
}
