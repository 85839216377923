import React  from "react";
import {REPORT_LAYOUT_LEGEND_DATA} from "../../util/util-constants";

const ReportLayoutLegend = ({}) => {

    const data = Object.keys(REPORT_LAYOUT_LEGEND_DATA).map((it, index) => (
        <div className={'flex items-center justify-start'}>
            <div className={'w-3 h-3 rounded-full mr-1 ' + (REPORT_LAYOUT_LEGEND_DATA[it].bg)}/>
            {it}. {REPORT_LAYOUT_LEGEND_DATA[it].text}
        </div>
    ))

    return (
        <div className="flex items-center justify-center bg-inverse space-x-5">
            {data}
        </div>
    )
}

export default ReportLayoutLegend
