export default class Resources {

    static User = "user";

    static Resource = "resource"

    static ServiceAreas = "service-areas"
    static Departments = "departments"
    static Nurses = "nurses"
    static Serviceman = "serviceman"
    static NursesImage = "nurses/image"
    static AdminDashboardReports = "admin-dashboard-reports"
    static AdminDashboardReportsSingle = "admin-dashboard-reports/single"
    static NurseDashboardReports = "nurse-dashboard-reports"
    static NurseDashboardReportsSingle = "nurse-dashboard-reports/single"
    static Reports = "reports"
    static ReportsGraph = "reports/graph"
    static ReportsServiceman = "reports/serviceman"
}
