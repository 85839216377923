import React, {Component} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {toBackDateTime} from "../../util/util";
import moment from "moment";
import CalendarIcon from "@heroicons/react/solid/CalendarIcon";
import ErrorFieldMessage from "../error-field-message";

const FieldClearIcon = () => (
    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill="currentColor">
        <path
            d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"/>
    </svg>
)

export default class FieldDate extends Component {

    render() {
        return (
            <span className="relative">
                <DatePicker
                    selected={moment(this.props.value).isValid() ? moment(this.props.value).toDate() : null}
                    onChange={(date) => {
                        this.props.onChange(this.props.name, !!date ? toBackDateTime(date) : "");
                    }}
                    minDate={this.props.minDate ? moment(this.props.minDate).toDate() : null}
                    maxDate={this.props.maxDate ? moment(this.props.maxDate).toDate() : null}
                    disabled={this.props.disabled}
                    showTimeSelect={this.props.type === "datetime"}
                    timeFormat="h:mm aa"
                    dateFormat={this.props.type === "datetime" ? "MM/dd/yy h:mm aa" : "MM/dd/yy"}
                    timeIntervals={15}
                    timeCaption="time"
                    popperPlacement={this.props.placement ? this.props.placement : "bottom-start"}
                    className={"relative z-1 " + this.props.addClass + (this.props.errorMessage ? " is-invalid" : "") + (" block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ")}
                    wrapperClassName={this.props.wrapperClassName ?? "w-full"}
                    calendarClassName={this.props.calendarClassName ?? "flex"}
                    popperProps={this.props.popperProps ?? {}}
                />
                {this.props.isClearable && this.props.value && !this.props.disabled && (
                    <button
                        tabIndex={this.props.isCloseFocusable === false ? "-1" : null}
                        onClick={() => this.props.onChange(this.props.name, "")}
                        className="absolute z-10 inset-y-0 right-0 px-3 flex items-center cursor-pointer hover:text-tm-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-tint">
                        <FieldClearIcon/>
                    </button>
                )}
                <ErrorFieldMessage
                    errorMessage={this.props.errorMessage}
                    translate={this.props.translate}
                />

                {!this.props.value && (
                    <CalendarIcon className="w-5 h-5 text-tm-gray-300 absolute right-2 top-0 z-0"/>
                )}
            </span>
        )
    }
}

